import { toast } from 'react-toastify';
// import { resolve } from 'path';
import { addBannerAPI, deleteBannerImageAPI, uploadImageAPI, getAllBannerAPI, getFreeGiftApi, updateFreeGiftApi } from '../api/banner';

export const ADD_BANNERIMAGE = 'ADD_BANNERIMAGE';
export const ADD_BANNERIMAGE_SUCCESSFULL = 'ADD_BANNERIMAGE_SUCCESSFULL';
export const ADD_BANNERIMAGE_FAILED = 'ADD_BANNERIMAGE_FAILED';

export function addBanner(data) {
    return dispatch => {
        dispatch({
            type: ADD_BANNERIMAGE
        })
        return new Promise((resolve, reject) => {
            addBannerAPI(data)
                .then((res) => {
                    dispatch({
                        type: ADD_BANNERIMAGE_SUCCESSFULL,
                        data: res?.data
                    })
                    const txt = "Added"
                    toast.success(`Banner ${txt} successfully.`)
                    return resolve(res)
                })
                .catch(err => {
                    dispatch({
                        type: ADD_BANNERIMAGE_FAILED
                    })
                    toast.error(err)
                    return reject(err)
                })
        })
    }
}


export function uploadBannerImage(data) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            uploadImageAPI(data).then(res => {
                toast.success(res.message)
                resolve(res)
            }).catch((err) => {
                reject(err)
            })
        })
    }
}

// Delete Image

export const DELETE_BANNER_IMAGE = 'DELETE_BANNER_IMAGE';
export const DELETE_BANNER_IMAGE_SUCCESSFULL = 'DELETE_BANNER_IMAGE_SUCCESSFULL';
export const DELETE_BANNER_IMAGE_FAILED = 'DELETE_BANNER_IMAGE_FAILED';

export function deleteBannerImages(data) {
    return dispatch => {
        dispatch({
            type: DELETE_BANNER_IMAGE
        })
        return new Promise((resolve, reject) => {
            deleteBannerImageAPI(data)
                .then((res) => {
                    dispatch({
                        type: DELETE_BANNER_IMAGE_SUCCESSFULL,
                        data: res
                    })
                    toast.success("Banner Image Deleted successfully.")
                    return resolve(res)
                })
                .catch(err => {
                    dispatch({
                        type: DELETE_BANNER_IMAGE_FAILED
                    })
                    toast.error(err)
                    return reject(err)
                })
        })
    }
}


export const GET_ALL_BANNER = 'GET_ALL_BANNER';
export const GET_ALL_BANNER_SUCCESSFULL = 'GET_ALL_BANNER_SUCCESSFULL';
export const GET_ALL_BANNER_FAILED = 'GET_ALL_BANNER_FAILED';

export function getAllBanner(data) {
    return dispatch => {
        dispatch({
            type: GET_ALL_BANNER
        })
        return new Promise((resolve, reject) => {
            getAllBannerAPI(data)
                .then((res) => {
                    dispatch({
                        type: GET_ALL_BANNER_SUCCESSFULL,
                        data: res?.data
                    })
                    return resolve(res)
                })
                .catch(err => {
                    dispatch({
                        type: GET_ALL_BANNER_FAILED
                    })
                    return reject(err)
                })
        })
    }
}



export const GET_FREE_GIFT = 'GET_FREE_GIFT';
export const GET_FREE_GIFT_SUCCESSFUL = 'GET_FREE_GIFT_SUCCESSFUL';
export const GET_FREE_GIFT_FAILED = 'GET_FREE_GIFT_FAILED';

export function getFreeGift(data){
    return dispatch => {
        dispatch({
            type:GET_FREE_GIFT
        })
        return new Promise((resolve, reject) => {
            getFreeGiftApi(data)
            .then((res) => {
                dispatch({
                    type: GET_FREE_GIFT_SUCCESSFUL,
                    data: res?.data
                })
                return resolve(res)
            })
            .catch(err => {
                dispatch({
                    type: GET_FREE_GIFT_FAILED
                })
                return reject(err)
            })
        })
    }
}

export const UPDATE_FREE_GIFT = 'UPDATE_FREE_GIFT';
export const UPDATE_FREE_GIFT_SUCCESSFUL = 'UPDATE_FREE_GIFT_SUCCESSFUL';
export const UPDATE_FREE_GIFT_FAILED = 'UPDATE_FREE_GIFT_FAILED';

export function updateFreeGift(data){
    return dispatch => {
        dispatch({
            type:UPDATE_FREE_GIFT
        })
        return new Promise((resolve, reject) => {
            updateFreeGiftApi(data)
            .then((res) => {
                dispatch({
                    type: UPDATE_FREE_GIFT_SUCCESSFUL,
                    data: res?.data
                })
                return resolve(res)
            })
            .catch(err => {
                dispatch({
                    type: UPDATE_FREE_GIFT_FAILED
                })
                return reject(err)
            })
        })
    }
}