import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography, Grid, Box, Paper } from '@material-ui/core';
import TableLoader from '../../../components/common/TableLoader';
import * as commonActions from "../../../redux/actions/common"
import * as productsActions from "../../../redux/actions/products"
import DraggableList from './DraggableList';
import { APP_NAME } from '../../../constants';
import { getData } from '../../../utils';

function ManageCategoriesPage(props) {
    const classes = useStyles();
    const dispatch = useDispatch()

    const [category, setCategory] = useState([])

    const { allCategoryData, isLoading, allBlazeCategoryData, categoryImages = [] } = useSelector(state => state.products)


    const { values: categoryValues } = allCategoryData
    const { values = [] } = allBlazeCategoryData
    const appName = JSON.parse(getData(APP_NAME))

    useEffect(() => {
        dispatch(productsActions.getAllCategory())
        dispatch(productsActions.getAllBlazeCategory())
        if (Array.isArray(categoryImages) && !categoryImages.length) {
            dispatch(productsActions.getAllCategoryImages())
        }
    }, [])

    useEffect(() => {
        setCategory(categoryValues)
    }, [categoryValues])




    const handleChangeCategory = (e, index) => {
        const { name, value } = e.target

        const updatedCategory = [...category]
        let catObj = { ...(updatedCategory[index]) }

        catObj = {
            ...catObj,
            [name]: value
        }

        updatedCategory[index] = catObj

        setCategory(updatedCategory)
    }

    const handleChangeSubCategory = (e, val, index) => {       

        if(appName === 'cannamobile' || appName === "multishop"){

            const updatedCategory = [...category];
            const catObj = { ...updatedCategory[index] };
              const newdata = e.target.value.split(',')
              const newVal = newdata && newdata.length && newdata.map(v => { return {   _id: e.target.id, name: v } }) || []
              catObj.blazeCategories = newVal;  
            updatedCategory[index] = catObj
            setCategory(updatedCategory)
        }else{
            const newVal = val && val.length && val.map(v => { return { id: v.id, name: v.name } }) || []
            const updatedCategory = [...category]
            let catObj = { ...(updatedCategory[index]) }
            catObj = {
                ...catObj,
                blazeCategories: [...newVal]
            }
            updatedCategory[index] = catObj
            setCategory(updatedCategory)
        }

    }

    const handleDeleteConfirmation = (id) => {
        dispatch(commonActions.openCustomModalDialog({
            title: "Are you sure you want to delete?",
            confirm: () => { handleDeleteCategory(id) },
            cancel: () => { }
        }, "ConfirmationModal"))
    }

    const handleDeleteCategory = (id) => {
        dispatch(productsActions.deleteCategory(id)).then(() => {
            dispatch(productsActions.getAllCategory())
        }).catch(() => { })
    }

    const handleSubmit = () => {
        const newOrderedCat = [...category].map((v, ind) => { return { ...v, order: ind + 1 } })
        dispatch(productsActions.updateCategories(newOrderedCat))
    }

    const onUpdateCategoryOrder = (updatedCategory) => {
        setCategory(updatedCategory)
    }

    const handleEditCategory = (item) => {
        dispatch(commonActions.openCustomModalDialog({
            title: "Edit",
            data: item
        }, "AddCategory"))
    }


    const updatedOptions = [...(values || [])];
    let selectedBlazeCategory = [];
    if (category && category.length) {
        category.map((v) => selectedBlazeCategory.push(v.blazeCategories));
    }
    
    selectedBlazeCategory = selectedBlazeCategory.flat(1);
    const array3 = updatedOptions.filter((obj) => {
        return selectedBlazeCategory.findIndex((fnd) => fnd && obj && fnd.id === obj.id) === -1;
    });
    
    const updatedOptionsValues = [...array3];
    


    return (
        <>
        <Helmet>
      <title>Categories | Ankar Admin </title>
    </Helmet>
        <Grid component="main" className={`${classes.root} manage-category-page-container`}>
            <Grid container className=''>
                <Grid item xs={12} sm={4} md={4} xl={4} className="page-heading">
                    <h2 className='m-0'>Categories</h2>
                </Grid>
                <div className='fixed-top'>
                    <Button onClick={() => { dispatch(commonActions.openCustomModalDialog({}, "AddCategory")) }} variant="contained" color="primary" >
                        Add Category
                    </Button>
                </div>
            </Grid>
            {  isLoading ? <TableLoader  /> :  Array.isArray(category) && category.length ?
                <Grid item xs={12} sm={12} md={12} lg={12} className="m-a">
                    <Paper>
                        <Box p={4}>
                            <DraggableList
                                category={category}
                                values={updatedOptionsValues}
                                onUpdateCategoryOrder={onUpdateCategoryOrder}
                                handleEditCategory={handleEditCategory}
                                handleDeleteCategory={handleDeleteConfirmation}
                                handleChangeSubCategory={handleChangeSubCategory}
                            />
                            <div className="save-button-div d-flex jc-content-end">
                                <Button
                                    onClick={() => { handleSubmit() }}
                                    variant="contained"
                                    color="primary"
                                >Save</Button>
                            </div>
                        </Box>
                    </Paper>
                </Grid>
                :
                <Grid item xs={12}>
                    <Box mt={2}>
                        <div className="no-data txt-center">
                            <img src="/assets/images/noDataFound.svg" alt="" />
                            <Typography variant="h5">No Categories Yet</Typography>
                        </div>
                    </Box>
                </Grid>
                }
        </Grid>
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        // height: '100vh',
    },

}));


export default ManageCategoriesPage
