import {
    GET_ALL_NOTIFICATIONS_REQUEST,
    GET_ALL_NOTIFICATIONS_SUCCESS,
    GET_ALL_NOTIFICATIONS_FAILURE,
    CREATE_NOTIFICATIONS,
    CREATE_NOTIFICATIONS_SUCCESS,
    CREATE_NOTIFICATIONS_FAILURE,
    UPDATE_NOTIFICATIONS,
    UPDATE_NOTIFICATIONS_SUCCESS,
    UPDATE_NOTIFICATIONS_FAILURE,
    UPDATE_NOTIFICATIONS_IMAGE,
    UPDATE_NOTIFICATIONS_IMAGE_SUCCESS,
    UPDATE_NOTIFICATIONS_IMAGE_FAILURE,
    GET_ALL_NOTIFICATIONS_CRITERIA_REQUEST,
    GET_ALL_NOTIFICATIONS_CRITERIA_SUCCESS,
    GET_ALL_NOTIFICATIONS_CRITERIA_FAILURE,
    DELETE_NOTIFICATION,
    DELETE_NOTIFICATION_SUCCESS,
    DELETE_NOTIFICATION_FAILURE,
} from '../actions/notifications'

const initialState={
    isLoading: false,
    allNotifications:{}
    
}

export default function (state={...initialState},actions={}) {
    switch(actions.type) {
        case GET_ALL_NOTIFICATIONS_REQUEST:{
        return {...state, isLoading: true};
    }

        case GET_ALL_NOTIFICATIONS_SUCCESS:{
            return {...state, isLoading:false,allNotifications:actions.payload};

    }
        case GET_ALL_NOTIFICATIONS_FAILURE:{
            return {...state, isLoading:false};
        }

        case CREATE_NOTIFICATIONS:{
            return {...state, isLoading:true};
        }

        case CREATE_NOTIFICATIONS_SUCCESS:{
            return {...state, isLoading:false};
        }

        case CREATE_NOTIFICATIONS_FAILURE:{
            return {...state, isLoading:false};
        }

        case UPDATE_NOTIFICATIONS:{
            return {...state, isLoading:true};
        }

        case UPDATE_NOTIFICATIONS_SUCCESS:{
            return {...state, isLoading:false};
        }

        case UPDATE_NOTIFICATIONS_FAILURE:{
            return {...state, isLoading:false};
        }

        case UPDATE_NOTIFICATIONS_IMAGE:{
            return {...state, isLoading:true};
        }

        case UPDATE_NOTIFICATIONS_IMAGE_SUCCESS:{
            return {...state, isLoading:false};
        }

        case UPDATE_NOTIFICATIONS_IMAGE_FAILURE:{
            return {...state, isLoading:false};
        } 
        
        case GET_ALL_NOTIFICATIONS_CRITERIA_REQUEST:{
            return {...state, isLoading: true};
        }

        case GET_ALL_NOTIFICATIONS_CRITERIA_SUCCESS:{
            return {...state, isLoading:false,allNotifications:actions.payload};
        }

        case GET_ALL_NOTIFICATIONS_CRITERIA_FAILURE:{
            return {...state, isLoading:false};
        }
        case DELETE_NOTIFICATION: {
            return {...state, isLoading:true}
        }
        case DELETE_NOTIFICATION_SUCCESS: {
            return {...state, isLoading:false}
        }
        case DELETE_NOTIFICATION_FAILURE: {
            return {...state, isLoading:false}
        }
        default :
        return state;
        

    }
}