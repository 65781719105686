import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
import { CircularProgress, Hidden } from '@material-ui/core';
import * as forgotPasswordActions from '../../../redux/actions/forgotPassword'


const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    image: {
        height: "100%",
        width: '100%',
    },
    innerImage: {
        width: '100%',
        height: "100%",
        objectFit: "cover",
        objectPosition: "70%"
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const ForgotPassword = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [email, setEmail] = useState('');
    const [errors, setErrors] = useState({});
    const [imageLoader, setImageLoader] = useState(true);
  
    const { isLoading } = useSelector(state => state.auth);
  
    const handleEmailChange = (event) => {
        setErrors({});
        setEmail(event.target.value);
    };
  
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
    const handleSubmit = (event) => {
        event.preventDefault();
      
        if (!email.trim()) {
          setErrors({ email: 'Please enter an email address' });
            
        } else if(!emailRegex.test(email)) {
            setErrors({ email: 'Please enter a valid email address' });
            // return;

        }else {
            const payload = { email };
      
        dispatch(forgotPasswordActions.forgotPassword(payload))
          .then(() => {
            toast.success('Email sent successfully!'); 
          })
          .catch((error) => {
          });
        }
      
        
      };

  
    return (
        <div className="login-background-contianer">
            <Grid container component="main" className={`${classes.root} login-container`}>
  
                <Grid item xs={12} sm={12} md={12} lg={12} className="login-container-item">
                    <Paper style={{ height: "100vh" }}>
                        <Grid container className="" style={{ height: "100%" }}>
                        <Grid item xs={12} sm={12} md={6} style={{ display: "flex" }}>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12} className="form-container-item">
                                        <div className={classes.paper}>
                                            {/* <div className="login-logo-div">
                                                <img src="../assets/images/logo.svg" alt="Forgot Password" />
                                            </div> */}
                                            <div className='mb-2'>
                                                <h1 >Forgot Password?</h1>
                                            </div>
                                            <form className={`${classes.form} login-form forgot-passwd-page`}>
                                                <TextField
                                                    type="text"
                                                    variant="outlined"
                                                    fullWidth
                                                    id="email"
                                                    label="Email Address"
                                                    name="email"
                                                    error={!!errors.email}
                                                    helperText={errors.email}
                                                    value={email}
                                                    onChange={handleEmailChange}
                                                    autoComplete="email"
                                                    autoFocus
                                                />
                                              <a className="forget-password" onClick={() => { window.location.href = "/login" }}> Back to Login </a>
                                                <Button
                                                    type="Send Confirmation"
                                                    fullWidth
                                                    variant="contained"
                                                    color="primary"
                                                    className={classes.submit}
                                                    onClick={handleSubmit}
                                                >
                                                    {isLoading ? <CircularProgress size={25} style={{ color: "white" }} /> : 'Send'}
                                                </Button>
                                                
                                          

    
                                            </form>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Hidden only={['xs', 'sm']}>
                                <Grid item md={6} className={classes.image}>
                                    <img src="../assets/images/first-page.jpg" alt='' onLoad={() => setImageLoader(false)} className={classes.innerImage} />
                                </Grid>
                            </Hidden>
                           
                        </Grid>
                    </Paper>
                </Grid>
            </Grid >
        </div>
    );
};
  
export default ForgotPassword;
