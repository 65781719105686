import { toast } from 'react-toastify';
import { getAllNotificationsAPI, getNotificationAPI, createNotificationsAPI,updateNotificationsAPI,updateNotificationsImageAPI,criteriaNotificationAPI, deleteNotificationAPI } from '../api/notifications';


export const GET_ALL_NOTIFICATIONS_REQUEST='GET_ALL_NOTIFICATIONS_REQUEST';
export const GET_ALL_NOTIFICATIONS_SUCCESS='GET_ALL_NOTIFICATIONS_SUCCESS';
export const GET_ALL_NOTIFICATIONS_FAILURE='GET_ALL_NOTIFICATIONS_FAILURE';


export function getAllNotifications(data) {
    return dispatch =>{
        dispatch({
            type: GET_ALL_NOTIFICATIONS_REQUEST,
        })
        return new Promise((resolve, reject) =>{
            getAllNotificationsAPI(data)
            .then((res)=>{
                dispatch({
                    type: GET_ALL_NOTIFICATIONS_SUCCESS,
                    payload:res?.data
                })
                return resolve(res);

            })
            .catch((err)=>{
                dispatch({
                    type: GET_ALL_NOTIFICATIONS_FAILURE
                });
                toast.error(err);
                return reject(err);
            });
            
        })
    }
}

export const GET_NOTIFICATION_REQUEST = 'GET_NOTIFICATION_REQUEST';
export const GET_NOTIFICATION_SUCCESS = 'GET_NOTIFICATION_SUCCESS';
export const GET_NOTIFICATION_FAILURE = 'GET_NOTIFICATION_FAILURE';

export function getNotificationData(id,data) {
    return dispatch => {
        dispatch({
            type: GET_NOTIFICATION_REQUEST,
        })
        return new Promise((resolve, reject) => {
            getNotificationAPI(id,data)
            .then((res)=>{
                dispatch({
                type: GET_NOTIFICATION_SUCCESS,
                payload:res?.data
                })
                return resolve(res);
            })
            .catch((err) => {
                dispatch({
                    type: GET_NOTIFICATION_FAILURE
                });
                toast.error(err);
                return reject(err);
            })
        })
    }
}



export const CREATE_NOTIFICATIONS='CREATE_NOTIFICATIONS'
export const CREATE_NOTIFICATIONS_SUCCESS='CREATE_NOTIFICATIONS_SUCCESS'
export const CREATE_NOTIFICATIONS_FAILURE='CREATE_NOTIFICATIONS_FAILURE'


export function createNotifications(data,id){
    return dispatch =>{
        dispatch({
            type: CREATE_NOTIFICATIONS,
        })
        return new Promise((resolve, reject) =>{
            createNotificationsAPI(data,id)
            .then((res)=>{
                dispatch({
                    type: CREATE_NOTIFICATIONS_SUCCESS,
                    payload:res
                })
                let txt = "Added"
                if (id) {
                    txt = "Updated"
                }
                toast.success(`Notification ${txt} successfully.`)
                return resolve(res)

            })
            .catch((err)=>{
                dispatch({
                    type: CREATE_NOTIFICATIONS_FAILURE
                });
                toast.error(err);
                return reject(err);
            });
            
        })
    }
}

export const UPDATE_NOTIFICATIONS='UPDATE_NOTIFICATIONS';
export const UPDATE_NOTIFICATIONS_SUCCESS='UPDATE_NOTIFICATIONS_SUCCESS';
export const UPDATE_NOTIFICATIONS_FAILURE='UPDATE_NOTIFICATIONS';

export function updateNotifications(id,data){
    return dispatch =>{
        dispatch({
            type: UPDATE_NOTIFICATIONS,
        })
        return new Promise((resolve, reject) =>{
            updateNotificationsAPI(id,data)
            .then((res)=>{
                dispatch({
                    type: UPDATE_NOTIFICATIONS_SUCCESS,
                    payload:res
                })
                return resolve(res);

            })
            .catch((err)=>{
                dispatch({
                    type: UPDATE_NOTIFICATIONS_FAILURE
                });
                toast.error(err);
                return reject(err);
            });
            
        })
    }
}

export const UPDATE_NOTIFICATIONS_IMAGE ='UPDATE_NOTIFICATIONS_IMAGE';
export const UPDATE_NOTIFICATIONS_IMAGE_SUCCESS ='UPDATE_NOTIFICATIONS_SUCCESS';
export const UPDATE_NOTIFICATIONS_IMAGE_FAILURE ='UPDATE_NOTIFICATIONS_IMAGE_FAILURE';

export function updateNotificationsImage(data){
    return dispatch =>{
        dispatch({
            type: UPDATE_NOTIFICATIONS_IMAGE,
        })
        return new Promise((resolve, reject) =>{
            updateNotificationsImageAPI(data)
            .then((res)=>{
                dispatch({
                    type: UPDATE_NOTIFICATIONS_IMAGE_SUCCESS,
                    payload:res
                })
                return resolve(res);

            })
            .catch((err)=>{
                dispatch({
                    type: UPDATE_NOTIFICATIONS_IMAGE_FAILURE
                });
                toast.error(err);
                return reject(err);
            });
            
        })
    }
}


export const GET_ALL_NOTIFICATIONS_CRITERIA_REQUEST='GET_ALL_NOTIFICATIONS_REQUEST';
export const GET_ALL_NOTIFICATIONS_CRITERIA_SUCCESS='GET_ALL_NOTIFICATIONS_CRITERIA_SUCCESS';
export const GET_ALL_NOTIFICATIONS_CRITERIA_FAILURE='GET_ALL_NOTIFICATIONS_CRITERIA_FAILURE';


export function getAllNotificationsCriteria(data) {
    return dispatch =>{
        dispatch({
            type: GET_ALL_NOTIFICATIONS_CRITERIA_REQUEST,
        })
        return new Promise((resolve, reject) =>{
            criteriaNotificationAPI(data)
            .then((res)=>{
                dispatch({
                    type: GET_ALL_NOTIFICATIONS_CRITERIA_SUCCESS,
                    payload:res?.data
                })
                return resolve(res);

            })
            .catch((err)=>{
                dispatch({
                    type: GET_ALL_NOTIFICATIONS_CRITERIA_FAILURE
                });
                toast.error(err);
                return reject(err);
            });
            
        })
    }
}

export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';
export const DELETE_NOTIFICATION_SUCCESS = 'DELETE_NOTIFICATION_SUCCESS';
export const DELETE_NOTIFICATION_FAILURE = 'DELETE_NOTIFICATION_FAILURE';

// added delete notification
export function deleteNotification(data){
    return dispatch => {
        dispatch({
            type: DELETE_NOTIFICATION
        });

        return new Promise((resolve,reject) =>  {   
                deleteNotificationAPI(data)
                .then((res)=>{
                    dispatch({
                        type: DELETE_NOTIFICATION_SUCCESS,
                        payload: res?.data
                    })
                    return resolve(res)
                })
                .catch((err)=> {
                    dispatch({
                        type:DELETE_NOTIFICATION_FAILURE
                    });
                    toast.error(err);
                    return reject(err);
                })
        })
    }
}
