import React from 'react';
import { Box, Typography, Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';

export default function CustomerViewModal(props) {
  const items = useSelector((state) => state.common.modalData);

  return (
    <Grid container component="main" className="view-customer-modal-container mt-60">
      <Grid item xs={12} className="header-modal">
        <h3>Customer Details</h3>
      </Grid>
      <Grid item xs={12}>
      <Box className="list-view d-flex" style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography>Email: {items?.email || '-'}</Typography>
          <Typography>First Name: {items?.firstName || '-'}</Typography>
          <Typography>Last Name: {items?.lastName || '-'}</Typography>
        </Box>
      </Grid>
    </Grid>
  );
}
