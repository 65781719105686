import { isEmpty } from "lodash";

export function validateNotificationData(data = {}) {

    let isValid = true;
    const errors = {};
  
    if (isEmpty(data?.name)) {
      errors.name = "Name is required";
      isValid = false;
    }
  
    if (isEmpty(data?.title)) {
      errors.title = "Title is required";
      isValid = false;
    }
  
    if (isEmpty(data?.body)) {
      errors.body = "Notification Description is required";
      isValid = false;
    }
  
    if (data?.isSchedule === "later" && isEmpty(data?.scheduleTime)) {
      errors.isSchedule = "Scheduled Date and Time is required";
      isValid = false;
    }
  
    // if (isEmpty(data?.platforms)) {
    //   errors.platforms = "Please select a platform";
    //   isValid = false;
    // }
  
    if (!data.platforms || data.platforms.length === 0) {
      errors.platforms = "Please select at least one platform.";
    }
    data?.rules?.forEach((rule, index) => {
      if (isEmpty(rule?.entity_type)) {
        errors.rules = errors.rules || [];
        errors.rules[index] = { ...errors.rules[index], entity_type: "Entity Type is required" };
        isValid = false;
      }
  
      if (isEmpty(rule?.key)) {
        errors.rules = errors.rules || [];
        errors.rules[index] = { ...errors.rules[index], key: "Key is required" };
        isValid = false;
      }
  
      if (isEmpty(rule?.criteria)) {
        errors.rules = errors.rules || [];
        errors.rules[index] = { ...errors.rules[index], criteria: "Criteria is required" };
        isValid = false;
      }
  
      if (isEmpty(rule?.value)) {
        errors.rules = errors?.rules || [];
        errors.rules[index] = { ...errors.rules[index], value: "Value is required" };
        isValid = false;
      }
    });
  
    return { isValid, errors };
  };
  