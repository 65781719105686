import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Typography, Grid, TextField, Box } from '@material-ui/core';
import * as actions from "../../../redux/actions/products"
import * as commonActions from "../../../redux/actions/common"
// import * as productsActions from "../../../redux/actions/products"

export default function ThresholdModal(props) {
    const dispatch = useDispatch();
    const { modalData } = props;
    console.log("modalData",modalData);
    const [threshold,setThreshold] = useState("");
    const [msg,setMsg]=useState('')
    const productThreshold = modalData?.thresholdValue;
  
    
    const onChangeHandler = (e)=>{
        const{value=""} =e.target;
        setThreshold(+value);  
       
    };
    
    const onSetThresholdHandler = async(e)=>{
        if (threshold === ""){
            setMsg(`Please set a value`)
        }
        else if (threshold < 0){
              setMsg(`Value can't be less then zero`)
            }
        else {
            await dispatch(actions.updateThreshold({threshold}));
            dispatch(commonActions.closeCustomModalDialog());
            dispatch(actions.getThreshold())
        }
        }
   

    return (
        <Grid container component="main" className="add-zipcode-modal-container mt-60">
            <Grid item xs={12} className="header-modal">
                <h3>Threshold</h3>
            </Grid>

            <Grid item xs={12} className="add-category-input">
            
                <Grid item xs={12} className='mb-2'>
                     <strong>   Current Threshold: {productThreshold}</strong>
                   
                </Grid>
          
                <Grid item xs={12}>
                    <TextField
                        className="blaze-zipcode-textarea"
                        type="number"
                        label="Set New Threshold"
                        value={threshold}
                        name="thresholdValue"
                        fullWidth
                        variant="outlined"
                        onChange={onChangeHandler}
                    />
                    <p style={{color:"red"}}>{msg}</p>
                </Grid>

                <Grid item xs={12} className="d-flex jc-ctr add-button">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onSetThresholdHandler}
                    >
                        Set Threshold
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )
    
}


