import { isEmpty } from "lodash";

export function validateBrandData(data = {}) {
    const errors = {};

    if (!data.brandId) {
        errors.brandId = "Brand is required";
    }

    if (!data.description) {
        errors.description = "Brand Description is required";
    }

    return {
        isValid: isEmpty(errors),
        errors
    }
}