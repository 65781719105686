export const OPEN_MODAL_DIALOG = "OPEN_MODAL_DIALOG";

export function openCustomModalDialog(data, modal) {
    return dispatch => {
        dispatch({
            type: OPEN_MODAL_DIALOG,
           modal,
           data,
        })
    }
}

export const CLOSE_MODAL_DIALOG = "CLOSE_MODAL_DIALOG";

export function closeCustomModalDialog(data = {}) {
    return dispatch => {
        dispatch({
            type: CLOSE_MODAL_DIALOG,
            data,
        })
    }
}

// export const SET_SHOP_NAME = "SET_SHOP_NAME"

// export function selectedShopName(data = {}){
//     return dispatch => {
//         dispatch({
//             type : SET_SHOP_NAME ,
//             data
//         })
//     }
// }