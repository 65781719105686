import React, { useCallback, useEffect, useState, } from 'react';
import queryString from 'query-string'
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  InputAdornment,
  Tooltip,
  Grid,
  Button
} from "@material-ui/core";
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import { Helmet } from 'react-helmet-async';
import _ from 'lodash';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import { useLocation, useNavigate } from "react-router-dom"
import {  colorStyles, filterOptions } from '../../../utils';
import * as commonActions from '../../../redux/actions/common'
import * as promotionsActions from "../../../redux/actions/promotions";
import CustomInput from '../../../components/common/CustomInput';
import AllPromotions from './AllPromotions';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
}));

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

export default function ManagePromotions(props) {

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(0);
  const [search, setSearch] = useState("")
  const [filters, setFilters] = useState({
    limit: 10,
    skip: 0,
    page: 0,
  })
  const [isActiveTab, setIsActiveTab] = useState(true);

  useEffect(() => {
    let newFilters = {}
    let searchT = ''
    let newActive = true
    if (location.search) {
      const { limit = 10, skip = 0, page = 0, searchTerm = '', isActive = true } = queryString.parse(location.search);
      searchT = searchTerm
      newActive = isActive === "true"
      newFilters = {
        limit: Number(limit),
        skip: Number(skip),
        page: Number(page)
      }
      setIsActiveTab(newActive)
      setFilters(newFilters)
      setSearch(searchT)
      setCurrentPage(skip / limit)
    }
    hitRequest({ ...newFilters, searchTerm: searchT, isActive: newActive })

  }, [])
  
useEffect(() => {
  location.search = queryString.stringify({ ...filters, searchTerm: search, isActive: isActiveTab });
  window.onpageshow = function(event) {
    if (event.persisted) {
        window.location.reload();
    }
};
}, [filters, search, isActiveTab]);


  const hitRequest = (filters = {}) => {

    dispatch(promotionsActions.getAllPromotion({ ...filters, sortBy: 'isActive' }))

  }

  const handleChange = (e) => {
    const { value } = e.target
    if (value.length === 1 && value[0] === ' ') {

      setSearch('');
      return;
    }
    const newFilters = { limit: 10, skip: 0, page: 0, searchTerm: value,isActive: isActiveTab }
    setSearch(value);
    setFilters({ limit: 10, skip: 0, page: 0 })
    setCurrentPage(0)
    debouncedSave(newFilters);
  }


  const debouncedSave = useCallback(_.debounce(filters => hitRequest(filters), 1000), []);

  const onClickClearInputField = () => {
    if (!search) return
    const newFilters = { limit: 10, skip: 0, page: 0, }
    setFilters(newFilters)
    hitRequest({ ...newFilters, isActive: isActiveTab })
    setSearch('')
  }

  const onChangePagination = (e, page, activeStatus) => {
    setCurrentPage(page);
    const skip = page * filters.limit
    setFilters({ ...filters, skip, page })
    hitRequest({ ...filters, skip, page, searchTerm: search, isActive: activeStatus })
};



const onChangeRowsPerPage = (e) => {
  const newFilters = { limit: parseInt(e, 10), page: 0, skip: 0 }
  setFilters(newFilters)
  setCurrentPage(0)
  hitRequest({ ...newFilters, searchTerm: search,isActive: isActiveTab })
}


const onChangeSelectedTab = (e) => {
  const status = e?.value;
  console.log(status)
  const newFilters = { limit: 10, skip: 0, page: 0, }
  setIsActiveTab(status)
  setFilters(newFilters)
  setCurrentPage(0)
  hitRequest({ ...newFilters, searchTerm: search, isActive: status })
}

const handlePrevPage = (prevPage) => {
  setCurrentPage(prevPage)
  const skip = prevPage * filters.limit
  setFilters({ ...filters, skip, page: prevPage })
  hitRequest({ ...filters, skip, page: prevPage, searchTerm: search,isActive: isActiveTab })
};

const handleNextPage = (nextPage) => {
  setCurrentPage(nextPage)
  const skip = nextPage * filters.limit
  setFilters({ ...filters, skip, page: nextPage })
  hitRequest({ ...filters, skip, page: nextPage, searchTerm: search,isActive: isActiveTab })
};


  return (
    <>
    <Helmet>
      <title>Promotions | Ankar Admin </title>
    </Helmet>
    <Grid item xs={12}  >
      <Grid container className=' pb-3'>
        <Grid item xs={12} sm={6} md={4} xl={4} className="page-heading">
          <h2 className='m-0'>Promotions </h2>
        </Grid>
        <Grid item xs={12} sm={12} md={12} xl={12} className="d-flex search-section jc-content-end">
          <div className="search-input">
            <CustomInput
              label="Search"
              name="search"
              placeholder="Search"
              onChange={handleChange}

              value={search}
              fullWidth
              InputProps={{
                startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                endAdornment: search ? <InputAdornment position="end" className="cur-pointer"><ClearIcon onClick={onClickClearInputField} /></InputAdornment> : null
              }}
            />
          </div>
          <div className='search-btn-drop-down d-flex'>
            <div className="manage-top-tabs ml-3">
              <Select
                isSearchable={false}
                value={filterOptions?.find((option => option.value === isActiveTab?.toString()))}
                onChange={onChangeSelectedTab}
                options={filterOptions}
                styles={colorStyles}
              />
            </div>
            <div className='add-category-button ml-3'>
              <Button
                variant="contained"
                color="primary"
                onClick={() => dispatch(commonActions.openCustomModalDialog({}, 'AddPromotions'))}
                className=""
              >
                Add Promotions
              </Button>
            </div>
          </div>

        </Grid>
      </Grid>


        <AllPromotions
    onChangePagination={(event, page) => {
      const activeStatus = isActiveTab;
      setCurrentPage(page);
      onChangePagination(event, page, activeStatus);
    }}
    onChangeRowsPerPage={onChangeRowsPerPage}
    filters={filters}
    searchTerm={search}
    isActiveTab={isActiveTab}
    currentPage={currentPage}
    handleNextPage={(nextPage) => {
        const activeStatus = isActiveTab;
        handleNextPage(nextPage, activeStatus);
    }}
    handlePrevPage={(prevPage) => {
        const activeStatus = isActiveTab;
        handlePrevPage(prevPage, activeStatus);
    }}
  />
    </Grid>
    </>
  );
}