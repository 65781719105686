import { isEmpty } from "lodash";

export function validateDeliveryHoursData(data = {}) {
    const errors = {};
    console.log('error data : ', errors);
 
    if (!data.locationName) {
        errors.locationName = "Location Name is required";
    }

    if (!data.locationCity) {
        errors.locationCity = "Location City is required";
    }

   if (data.timeInterval !== 0 && !data.timeInterval) {
    errors.timeInterval = "Time Interval is required";
   
    }
  
    if(data.zipcode.length===0){
        errors.zipcode = "Zipcode is required";
    }

    return {    
        isValid: isEmpty(errors),
        errors
    }
}