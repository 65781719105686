import { FORGOT_PASSWORD, FORGOT_PASSWORD_SUCCESS, FORGOT_PASSWORD_FAILURE,RESET_PASSWORD,RESET_PASSWORD_SUCCESS,RESET_PASSWORD_FAILURE } from "../actions/forgotPassword";

const initialState = {
  isLoading: false,
  forgotPasswordData: {},
  resetPasswordData: {},
};


export default function (state = { ...initialState }, action = {}) {
    switch (action.type) {
        case FORGOT_PASSWORD: {
            return { ...state, isLoading: true }
        }

        case FORGOT_PASSWORD_SUCCESS: {
            return { ...state, isLoading: false, forgotPasswordData: action.payload }
        }

        case FORGOT_PASSWORD_FAILURE: {
            return { ...state, isLoading: false, forgotPasswordData: action.payload }
        }

        case RESET_PASSWORD: {
            return { ...state, isLoading: true }
        }

        case RESET_PASSWORD_SUCCESS: {
            return { ...state, isLoading: false, resetPasswordData: action.payload }
        }

        case RESET_PASSWORD_FAILURE: {
            return { ...state, isLoading: false, resetPasswordData: action.payload }
        }

        default:
            return state
    }
}


