import { isEmpty } from "lodash";

export function validatePromotionData(data = {}) {
    const errors = {};

    
    if (!data.name) {
        errors.name = "Title/Name is required!";
    }
    
    // if(!data.promocode) {
    //     errors.promocode = "Promo Code is required!";
    // }

    if(!data.active) {
        errors.active = "Status is required!";
    }

    if(!data.discountAmt){
        errors.discountAmt = "Amount is required!";
    }

    if(!data.promoDesc){
        errors.promoDesc = "Promo Text is required!";
    }

    if(!data.maxAvailable){
        errors.maxAvailable = "Max Available is required!";
    }

    if(!data.promoCodes || !data.promoCodes.length){
        errors.promoCodes = "Promo Code is required!";
    }
    

    if(!data.limitPerCustomer){
        errors.limitPerCustomer = "Max Per Customer is required!";
    }

    if(!data.discountType){
        errors.discountType = "Discount Type is required!";
    }

    return {
        isValid: isEmpty(errors),
        errors
    }
}