import React, { useEffect, useState } from 'react';
import { Card, CardActionArea, CardActions, CardContent, CardMedia, Grid, IconButton, Typography, Switch,Menu,MenuItem,Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import { Delete } from '@material-ui/icons';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Helmet } from 'react-helmet-async';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Edit } from '@mui/icons-material';
import TableLoader from '../components/common/TableLoader';
import * as integrationActions from '../redux/actions/integrations';
import * as commonActions from '../redux/actions/common';
import EditIcon from '../components/common/Icons/EditIcon';
import DeleteIcon from '../components/common/Icons/DeleteIcon';

export default function Settings() {
  const [integrations, setIntegrations] = useState({});
  const [staticData, setStaticData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isToggleOn, setIsToggleOn] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIntegration, setSelectedIntegration] = useState(null);
  const [activeItemsToShow, setActiveItemsToShow] = useState(5);
  const [inactiveItemsToShow, setInactiveItemsToShow] = useState(5);
  const increment = 5;
  const dispatch = useDispatch();


  const handleMenuOpen = (event, integration) => {
    setAnchorEl(event.currentTarget);
    setSelectedIntegration(integration);
  };

  const handleMenuClose = () => {
    if (selectedIntegration !== null) {
      setAnchorEl(null);
      setSelectedIntegration(null);
    }
  };
  const handleActiveViewMore = () => {
    setActiveItemsToShow((prev) => prev + increment);
  };

  const handleInactiveViewMore = () => {
    setInactiveItemsToShow((prev) => prev + increment);
  };


  useEffect(() => {
    const fetchIntegrations = async () => {
      setIsLoading(true);
      const staticIntegrations = await dispatch(integrationActions.getStaticIntegrations());
      setStaticData(staticIntegrations);
      dispatch(integrationActions.getAllIntegrations()).then((data) => {
        const initialToggles = {};
        data?.data.forEach((integration) => {
          initialToggles[integration?._id] = integration?.isActive || false;
        });
        setIntegrations(data);
        setIsToggleOn(initialToggles);
        setIsLoading(false);
      });
    };

    fetchIntegrations();  
  }, [dispatch]);




  const filterAndSortIntegrations = (integrationsData) => {
    const activeIntegrations = [];
    const inactiveIntegrations = [];


    integrationsData.forEach((integration) => {
      if (typeof integration.isActive === 'boolean') {
        if (integration.isActive) {
          activeIntegrations.push(integration);
        } else {
          inactiveIntegrations.push(integration);
        }
      }
    });

    const upcomingIntegrations = filterIncomingIntegrations(
      staticData?.data,
      activeIntegrations,
      inactiveIntegrations
  );

  // const filteredStaticIntegrations = staticData?.data.filter((staticIntegration) => {
  //     return !activeIntegrations.some((activeIntegration) => activeIntegration._id === staticIntegration._id) &&
  //         !upcomingIntegrations.some((upcomingIntegration) => upcomingIntegration._id === staticIntegration._id);
  // });


  
  const filteredStaticIntegrations = (staticData?.data || []).filter(integration => {
    const notActiveIntegration =  !activeIntegrations.some(activeIntegration => activeIntegration.integrationId === integration._id);
    const notInactiveIntegration = !inactiveIntegrations.some(inactiveIntegration => inactiveIntegration.integrationId === integration._id)
    const upcomingIntegration = !integration.isUpcoming

    return notActiveIntegration && notInactiveIntegration && upcomingIntegration;
  });
  


  inactiveIntegrations.push(...filteredStaticIntegrations);


    return { activeIntegrations, inactiveIntegrations};
  };

  const value = localStorage.getItem('ankar-admin');
  const parsedValue = JSON.parse(value);
  const projectId = parsedValue.projectId;

  const toggleSwitch = (integrationId) => {
    const existingIntegration = integrations?.data?.find((integration) => integration?._id === integrationId);

    if (existingIntegration) {
      const updatedIntegrations = integrations?.data?.map((integration) => {
        if (integration?._id === integrationId) {
          return {
            ...integration,
            isActive: !isToggleOn[integrationId],
          };
        }
        return integration;
      });

      const updatedIntegration = updatedIntegrations?.find((integration) => integration?._id === integrationId);
      const logoUrl = updatedIntegration?.logo;
      const isHttps = logoUrl.startsWith('https://');
      const { _id, ...updatedIntegrationWithoutId } = updatedIntegration;
      const newIntegration = {
        ...updatedIntegrationWithoutId,
        logo:logoUrl,
        projectId,
      };
      setIntegrations((prevState) => ({
        ...prevState,
        data: updatedIntegrations,
      }));
      dispatch(integrationActions.updateIntegration(newIntegration));
      toast.success(`Status changed for ${existingIntegration.name}`);
      setIsToggleOn((prevState) => ({
        ...prevState,
        [integrationId]: !isToggleOn[integrationId],
      }));
    } 
    
    else {

      const staticIntegration = staticData?.data?.find((integration) => integration?._id === integrationId);
      if (staticIntegration) {
        const newIntegration = { ...staticIntegration,
           integrationId, isActive: true, projectId,
           logo: staticIntegration?.logo?.split('/').pop(),};
      
     
           
        const addNewIntegration = async () => {
          try {
            await dispatch(integrationActions.addIntegration(newIntegration));

          
            setIntegrations((prevState) => ({
              ...prevState,
              data: [...prevState.data, newIntegration],
            }));

          
            setStaticData((prevState) => ({
              ...prevState,
              data: prevState.data.filter((integration) => integration._id !== integrationId),
            }));
            
            
            setIsToggleOn((prevState) => ({
              ...prevState,
              [integrationId]: true,
            }));
            // window.location.reload();
          } catch (error) {
            console.error('Error adding integration:', error);
          }
        };
        addNewIntegration();
      }
    }
  };

  const handleEdit = (integration) => {
    setAnchorEl(null);
    if (typeof commonActions.openCustomModalDialog === 'function') {
      dispatch(commonActions.openCustomModalDialog({ integration }, 'AddIntegrationModal'));
    }
  };

  const filterIncomingIntegrations = (incomingIntegrations, activeIntegrations, inactiveIntegrations) => {
    const upcomingIntegrations = incomingIntegrations.filter(integration => integration.isUpcoming);
    return upcomingIntegrations;
    // .filter(
    //   (incomingIntegration) =>
    //     !activeIntegrations.some((activeIntegration) => activeIntegration.integrationId === incomingIntegration._id) &&
    //     !inactiveIntegrations.some((inactiveIntegration) => inactiveIntegration.integrationId === incomingIntegration._id)
    // );
  };


 const handleConfirmation = (integrationId) => {
  setAnchorEl(null);
  dispatch(commonActions.openCustomModalDialog({
    title: "Are you sure you want to delete?",
    confirm: () => {
      handleDelete(integrationId);
      handleMenuClose();},
    cancel: () => { }
  }, "ConfirmationModal"));
}

const handleDelete = (integration) => {
  dispatch(integrationActions.deleteIntegration(integration?.integrationId))
    .then(() => {
      dispatch(integrationActions.getAllIntegrations());
      window.location.reload();
    })
    .catch(() => {});
};


useEffect(() => {
  if (!selectedIntegration) {
    handleMenuClose();
  }
}, [selectedIntegration]);
  return (
    <>
      <Helmet>
        <title>Integrations | Ankar Admin </title>
      </Helmet>
      <Grid item xs={12} sm={6} md={4} xl={4} className="page-heading">
        <h2 className="m-0">Integrations</h2>
      </Grid>
      {isLoading ? (
        <TableLoader />
      ) : (
        <>
      <div className="setting-box">
        <h2 className="mb-2 mt-0 setting-heading"><u>Active</u></h2>
        {filterAndSortIntegrations(integrations?.data)?.activeIntegrations.length === 0 ? (
                <p className='no-integrated-data'>
                  <img src={`/assets/No-Integrated.png`} alt="No Integration"/> No Active Integrations
                </p>
              ) : <>
              <ul className="list-grid-style">
               {
                filterAndSortIntegrations(integrations?.data)?.activeIntegrations
                  .slice(0, activeItemsToShow)
                  .map((integration) => (
                    <li key={integration?._id}>
                      <Card className="list-style-ui">
                        <div className="d-flex">
                          <Switch
                            checked={isToggleOn[integration?._id]}
                            onChange={() => toggleSwitch(integration?._id)}
                            variant="contained"
                            color="primary"
                            inputProps={{ 'aria-label': `toggle feature - ${integration?.name}` }}
                          />
                          <div className="card-actions text-center">
                            <div className="spacer" />
                            <IconButton onClick={(event) => handleMenuOpen(event, integration)}>
                              <MoreVertIcon />
                            </IconButton>
                          </div>
                        </div>
                        <CardMedia
                          component="img"
                          image={integration?.logo || '/assets/dummy-post.jpg'}
                          alt="NO image yet"
                          onError={(e) => {
                            e.target.src = '/assets/dummy-post.jpg';
                          }}
                        />
                      </Card>
                    </li>
                  ))
                        }
           </ul>
              </>}

        <Grid item xs={12}  className="d-flex jc-ctr my-4 add-button">
        {activeItemsToShow < filterAndSortIntegrations(integrations?.data)?.activeIntegrations.length && (
          <Button variant='contained' color='primary'  onClick={handleActiveViewMore}>View More <ExpandMoreIcon/></Button>
        )}
        </Grid>
      </div>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem  className='setting-popover' onClick={() => handleEdit(selectedIntegration)}><EditIcon/>Edit</MenuItem>
        <MenuItem  className='setting-popover' onClick={() => handleConfirmation(selectedIntegration)}><DeleteIcon/>Delete</MenuItem>
      </Menu>

          <div className="setting-box">
            <h2 className="mb-2 setting-heading"><u>Inactive</u></h2>
            {filterAndSortIntegrations(integrations?.data)?.inactiveIntegrations.length === 0 ? (
                <p className='no-integrated-data'>
                <img src={`/assets/No-Integrated.png`} alt="No Integration"/>
                No Inactive Integrations
                </p>

              ) : <>
            <ul className="list-grid-style">
             {
              filterAndSortIntegrations(integrations?.data)?.inactiveIntegrations
                  .slice(0, inactiveItemsToShow)
                  .map((integration) => (
                    <li key={integration?._id}>
                      <Card className="list-style-ui">

                        <Typography>
                          <Switch
                          checked={isToggleOn[integration?._id]}
                            onChange={() => toggleSwitch(integration?._id)}
                            variant="contained"
                            color="primary"
                            inputProps={{ 'aria-label': `toggle feature - ${integration?.name}` }}
                          />
                        </Typography>
                        <CardMedia
                          component="img"
                          image={integration?.logo || '/assets/dummy-post.jpg'}
                          alt="NO image yet"
                          onError={(e) => {
                            e.target.src = '/assets/dummy-post.jpg';
                          }}
                        />
                      </Card>
                    </li>
                  ))
                        }
                     {/* {staticData?.data
                      .filter((integration) => integration.isUpcoming === false && !integration.hasOwnProperty('isActive'))
                     .map((integration) => (
    <li key={integration?._id}>
      <Card className="list-style-ui">
        <Typography>
        <Switch
                          checked={isToggleOn[integration?._id]}
                            onChange={() => toggleSwitch(integration?._id)}
                            variant="contained"
                            color="primary"
                            inputProps={{ 'aria-label': `toggle feature - ${integration?.name}` }}
                          />
        </Typography>
        <CardMedia
                          component="img"
                          image={integration?.logo || '/assets/dummy-post.jpg'}
                          alt="NO image yet"
                          onError={(e) => {
                            e.target.src = '/assets/dummy-post.jpg';
                          }}
                        />
      </Card>
    </li>
  ))} */}
          </ul>
              </>}
        <Grid item xs={12}  className="d-flex jc-ctr my-4 add-button">
            {inactiveItemsToShow < filterAndSortIntegrations(integrations?.data)?.inactiveIntegrations.length && (
          <Button variant="contained" color="primary" size='small' onClick={handleInactiveViewMore}>View More <ExpandMoreIcon/></Button>
          )}
        </Grid>
          </div>


          <div className="setting-box">
            <h2 className="mb-2 setting-heading"><u>Upcoming</u> </h2>
            <ul className="list-style-upcoming">
            {filterIncomingIntegrations(staticData?.data, filterAndSortIntegrations(integrations?.data)?.activeIntegrations, filterAndSortIntegrations(integrations?.data)?.inactiveIntegrations).map(
            (integration) => (
              <li key={integration?._id}>
                  <Card className="d-flex list-style-ui ">
                        <CardMedia
                          component="img"
                          image={integration?.logo || '/assets/dummy-post.jpg'}
                          alt="NO image yet"
                          onError={(e) => {
                            e.target.src = '/assets/dummy-post.jpg';
                          }}
                        />
                        <div>
                          <div className="card-actions">
                            <div className="spacer" />
                            {/* <IconButton onClick={() => handleEdit(integration)}>
                              <Edit />
                            </IconButton> */}
                            {/* <Switch
                               checked={isToggleOn[integration?._id]}
                              onChange={() => toggleSwitch(integration?._id)}
                              variant="contained"
                              color="primary"
                              inputProps={{ 'aria-label': `toggle feature - ${integration?.name}` }}
                            /> */}
                          </div>
                        </div>
                      </Card>
                </li>
              ))}
            </ul>
          </div>
        </>
      )}
    </>
  );
  }
