import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@mui/material';
// import { Button, Switch } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { toast } from 'react-toastify';
import CustomInput from '../components/common/CustomInput'
import * as bannerAction from "../redux/actions/banner";
import { getData } from '../utils';
import { SelectedShop } from '../constants';


const BannerImage = () => {
    const dispatch = useDispatch()

    const [category, setCategory] = useState({
        bannerImages: [],
    })

    const [isToggleOn, setIsToggleOn] = useState(false)

    useEffect(() => {
        getBannerData()
    }, [])

    function getBannerData (){
        dispatch(bannerAction.getAllBanner()).then((res) => {
            setCategory({ bannerImages: [...res?.data?.bannerImages], })
        }).catch();
    }

    useEffect(() => {
        const fetchFreeGiftStatus = async () => {
            try {
                const getFreeGiftResponse = await dispatch(bannerAction.getFreeGift())
                const isActive = getFreeGiftResponse?.data?.active
                const isStatus200 = getFreeGiftResponse?.status === 200;
                console.log('is active and status', isActive, isStatus200)
                setIsToggleOn(isActive && isStatus200)
            } catch (error) {
                console.error('Error fetching free gift status:', error);
            }
        };
        fetchFreeGiftStatus();
    }, []);


    const handleChangeCategory = (e, index) => {
        const { name, value } = e.target;

        const updatedBanners = category?.bannerImages?.map((banner, i) =>
            i === index ? { ...banner, [name]: value } : banner
        );
        setCategory({ ...category, [name]: value, bannerImages: updatedBanners })
    };



    const imageChangeHandler = (e) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('image', file);

        dispatch(bannerAction.uploadBannerImage(formData)).then((res) => {
            const newBanner = {
                fileName: file.name,
                src: res?.data?.url,
                link: '',
            };
            setCategory({ ...category, bannerImages: [...category.bannerImages, newBanner] })
        });
    };

    const addBanners = () => {
        const updatedData = {
            bannerImages: category.bannerImages.map((banner) => ({
                fileName: banner.src ? banner.src.substring(banner.src.lastIndexOf('/') + 1) : "",
                src: banner.src,
                link: banner.link,
            })),

        };
        dispatch(bannerAction.addBanner(updatedData)).then(() => {
            dispatch(bannerAction.getAllBanner());
        }).catch(() => {
        });
    };

    const updateBanner = (updatedData) => {
        dispatch(bannerAction.addBanner(updatedData)).then(() => {
            dispatch(bannerAction.getAllBanner());
        }).catch(() => {
        });
    };


    const deleteBannerImage = (index) => {
        const deletedBanner = category?.bannerImages[index];
        const data = {
            image: deletedBanner.src,
        };
        
        dispatch(bannerAction.deleteBannerImages(data))
            .then((response) => {
                const updatedBanners = category?.bannerImages.filter((item, idx) => idx !== index);
                const deletedData = {bannerImages : updatedBanners}
                updateBanner(deletedData)
                setCategory((prevCategory) => ({
                    ...prevCategory,
                    bannerImages: updatedBanners,
                }));
            })
            .catch((error) => {
                // console.error("Error deleting banner image:", error);
            });
    };



    const handleToggleChange = async () => {
        try {
            const isActive = !isToggleOn;

            setIsToggleOn(isActive);

            const dataToUpdate = {
                freeGift: isActive,
            };

            const updateResponse = await dispatch(bannerAction.updateFreeGift(dataToUpdate));
            toast.success('Update Successful')
        } catch (error) {
            console.error(error)
        }
    };

    return (
        <div className='shop-banner'>
            <Grid container>
                <Grid item xs={12}>
                    <h3>Add Shop Banner </h3>
                    <div className="mb-2 mt-2">
                        <input type='file' name='filePath'
                            onChange={imageChangeHandler}
                        />
                        <p className='banner-info' > <b>Note: </b>Shop banner image should be 1600x150</p>
                        {
                            category?.bannerImages?.length ?
                                <ul className="mb-2 mt-2 img-uploaded">
                                    {
                                        category?.bannerImages?.map((item, index) => {
                                            return (
                                                <li>
                                                    <div className="slider-shop-img">
                                                        <img key={index} src={item.src} alt="" />
                                                        <span onClick={() => deleteBannerImage(index)} >
                                                            <CloseIcon />
                                                        </span>
                                                    </div>
                                                    <div className="slider-link">
                                                        <CustomInput
                                                            label="Banner Link"
                                                            placeholder="Banner Link"
                                                            name="link"
                                                            value={(item.link) || ""}
                                                            fullWidth
                                                            onChange={(e) => { handleChangeCategory(e, index) }}
                                                        />
                                                    </div>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                                : 'No Banner found!'
                        }
                    </div>
                </Grid>
                <Grid item xs={12} className="jc-ctr add-button">
                    <Button onClick={() => { addBanners() }} variant="contained" color="primary" fullWidth>Submit</Button>
                </Grid>

            </Grid>
        </div>

    )
}

export default BannerImage



