import axios from 'axios';
import { AccessTokenId, AccessTokenName, isSuperAdmin, BASE_URL, APP_NAME, SelectedShop } from './constants';
import { LOGOUT_USER } from './redux/actions/auth';
import configureStore from "./redux/store";


export function generateUrl(path) {

    return BASE_URL + path;
}

export function apiReq(endPoint, data, method, header, type) {
    return new Promise((resolve, reject) => {
        let headers = {
            ...header,
        }

        const accessToken = JSON.parse(getData(AccessTokenId));
        const appName = JSON.parse(getData(APP_NAME))
        const selectedShopId = JSON.parse(getData(SelectedShop))
        if (accessToken) {
            headers = {
                ...headers,
                app: appName,
                shopId : selectedShopId,
                Authorization: `Bearer ${accessToken}`
            }
        }

        if (type === "blaze") {
            headers = {
                ...header,
                Authorization: "72f4973d80b24bf699feb29e6e35d3df",
                "partner_key": "31584710637c4cffb283146d2c4dd93d"
            }
        }
        if (type === "google") {
            headers = {}
        }

        if (method === 'get' || method === 'delete') {
            data = {
                params: data,
                headers
            }
        }
        axios[method](endPoint, data, { headers }).then(({ data, status: httpStatus }) => {
            if ((httpStatus === 200) || (httpStatus === 201)) {
                return resolve(data);
            }
            return reject(data)
        }).catch((err) => {
            const status = err && err.response && err.response.status
            if (status === 401 || status === 403) {
                const store = configureStore()
                store.dispatch({ type: LOGOUT_USER })
                window.location.replace("/login")
            }
            const message = err && err.response && err.response.data && err.response.data.message
            return reject(message);
        });
    })
}

export function apiPost(endPoint, data, headers = {}) {
    return apiReq(generateUrl(endPoint), data, 'post', headers);
}

export function apiDelete(endPoint, data, headers = {}) {
    return apiReq(generateUrl(endPoint), data, 'delete', headers);

}

export function apiGet(endPoint, data, headers = {}, type) {
    return apiReq(generateUrl(endPoint), data, 'get', headers, type);
}

export function apiPut(endPoint, data, headers = {}) {
    return apiReq(generateUrl(endPoint), data, 'put', headers);
}


export function apiGetCustom(endPoint, data, headers = {}, type) {
    return apiReq(`https://maps.googleapis.com/maps/api/`, data, 'get', headers, type);
}

export function getData(label) {
    const data = window && window.localStorage.getItem(label);
    return data
}

export function setData(label, data) {
    if (window && window.localStorage)
        localStorage.setItem(label, JSON.stringify(data));
}

export function removeData(label) {
    if (window && window.localStorage)
        localStorage.removeItem(label);
}

export function getUserData() {
    const user = JSON.parse(getData(AccessTokenName));

    if (user && user.access_token) {
        return user
    }
    return null
}

export function isLoggedIn() {
    const user = JSON.parse(getData(AccessTokenName));

    if (user && user.access_token) {
        return user.access_token
    }
    return null
}

export function getPercentage(offeredPrice, salePrice) {
    const discountPercentage = ((offeredPrice - salePrice) / offeredPrice) * 100
    return parseFloat(discountPercentage).toFixed(0)
}

export function formatDate(date) {
    const d = new Date(date)
    let month = `''${(d.getMonth() + 1)}`
    let day = `''${d.getDate()}`
    const year = d.getFullYear();

    if (month.length < 2)
        month = `'0'${month}`;
    if (day.length < 2)
        day = `'0'${day}`;

    return [year, month, day].join('-');
}

export function isSuperAdminAccess() {
    const superAdmin = JSON.parse(getData(isSuperAdmin))
    if (!!superAdmin) {
        return true
    }
    return false
}

export function trimText(text, len = 20) {
    if (text.length <= len) return text;
    return `text.substring(0, len - 1)${"..."}`;
}



export function getProductData(type, allProducts = {}, allCategoryData = {}) {

    switch (type) {
        case "PRODUCT":
            return allProducts?.values || []
        case "CATEGORY":
            return allCategoryData?.values || []
        case "GROUP":
            return [
                { name: "Hybrid", value: "Hybrid" },
                { name: "Indica", value: "Indica" },
                { name: "Sativa", value: "Sativa" },
                { name: "CBD", value: "CBD" },]
        default:
            return []
    }
}

// Dynamic label show in Autocomplete list according to the type selected
export function getOptionLabel(option, type) {
    switch (type) {
        case "PRODUCT":
            return option?.adminProductName || ''
        case "CATEGORY":
            return option?.categoryName || ''
        case "GROUP":
            return option?.name || ''
        default:
            return ''
    }
}

// Dynamic label show in Autocomplete according to the type selected
export function getSelectedLabel(type) {
    switch (type) {
        case "PRODUCT":
            return 'Select Product' || ''
        case "CATEGORY":
            return 'Select Category' || ''
        case "GROUP":
            return 'Select Group' || ''
        case "GENERAL":
            return 'Select General' || ''
        default:
            return 'Id'
    }
}


export function getSelectedId(type, selectedId) {
    switch (type) {
        case "PRODUCT":
            return selectedId.id || ''
        case "CATEGORY":
            return selectedId._id || ''
        case "GROUP":
            return selectedId.value || ''
        case "CUSTOMER":
            return selectedId.id_ || ''
        default:
            return ''
    }
}

export const typeOptions = [
    { title: 'Product', value: 'PRODUCT' },
    { title: 'Category', value: 'CATEGORY' },
    { title: 'Group', value: 'GROUP' },
    { title: 'General', value: 'GENERAL' }
];

export const RadioFormOption = [
    { name: 'All', label: 'All' },
    { name: 'IOS', label: 'IOS' },
    { name: 'ANDROID', label: 'ANDROID' },
    { name: 'USER', label: 'USER' },
]


export const getProductUnitByCategoryId = (categoryName, concValues, potencyValues) => {
    if (categoryName === 'Edibles' || categoryName === 'Topicals' || categoryName === 'Tincture' || categoryName === 'Tinctures') {
        return {
            thc: (potencyValues && potencyValues.thc && (`${parseFloat(potencyValues.thc).toFixed(2)} mg`)) || "0mg",
            cbd: (potencyValues && potencyValues.cbd && (`${parseFloat(potencyValues.cbd).toFixed(2)} mg`)) || "0mg",
            cbn: (potencyValues && potencyValues.cbn && (`${parseFloat(potencyValues.cbn).toFixed(2)} mg`)) || "0mg",
        }
    }

    return {
        thc: (concValues && concValues.thc && (`${parseFloat(concValues.thc).toFixed(2)} mg`)) || "0%",
        cbd: (concValues && concValues.cbd && (`${parseFloat(concValues.cbd).toFixed(2)} mg`)) || "0%",
        cbn: (concValues && concValues.cbn && (`${parseFloat(concValues.cbn).toFixed(2)} mg`)) || "0%",
    }

}


export const convertPascalCaseToUserFriendly = (a = '') => {
    let b = ''
    for (let i = 0; i < a.length; i += 1) {
        if (i === 0) {
            b = a.charAt(i).toUpperCase();
        } else {
            if (a.charAt(i).toUpperCase() === a.charAt(i)) {
                b += `b${" "}`
            }
            b += b + a.charAt(i);
        }
    }
    return b
}


export const capitalizeString = (str) => {
    return (str?.slice(0, 1)?.toUpperCase() + str?.slice(1)?.toLowerCase());
};


export const numRegex = /^[0-9]*$/;


export const colorStyles = {
    control: (styles) => ({ ...styles, width: '200px', height: '48px', backgroundColor: "#E8E8E8", border: '1px solid gray', boxShadow: 'none', outline: 'none', cursor: 'pointer', borderRadius: 'none', '&:hover': { boxShadow: 'none', border: "1px solid black" }, borderColor: 'none', }),
    dropdownIndicator: base => ({
        ...base,
        icon: {
            right: 0,

        },
        color: "grey",
        ":hover": {
            color: '#C2ABFAe'
        }
    }),


    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            color: data?.color || "",
            outline: data?.outline || "",
            border: data?.border || "",
            backgroundColor: data?.backgroundColor || "",
            ":hover": {
                backgroundColor: "var(--secondary-light)",
                color: '',
                cursor: 'pointer',
            },

        };
    },

    singleValue: (styles, { data }) => ({ ...styles, color: 'white', fontWeight: '500', textTransform: "upperCase" }),

};


export function flowerTypeImg(key, props) {
    switch (key) {
        case "Hybrid":
            return <img src="/assets/flowerType/hybrid.svg" alt="hybrid" />
        case "Indica":
            return <img src="/assets/flowerType/indica.svg" alt="indica" />
        case "Sativa":
            return <img src="/assets/flowerType/sativa.svg" alt="sativa" />
        case "CBD":
            return <img src="/assets/flowerType/cbd.svg" alt="cbd" />
        case "CBN":
            return <img src="/assets/flowerType/cbn.svg" alt="cbn" />
        case "Indica-Dominant":
            return <img src="/assets/flowerType/Indica-Dominant.svg" alt="Indica-Dominant" />
        case "Sativa-Dominant":
            return <img src="/assets/flowerType/Sativa-Dominant.svg" alt="Sativa-Dominant" />
        default:
            return <img src="/assets/flowerType/placeholder-flowerType.svg" alt="placeholder" />
    }
}

export const filterOptions = [
    { value: "", label: 'All', },
    { value: "true", label: 'Active', },
    { value: "false", label: 'In-Active' },
];

export const defaultColor = {
    secondary: "#5827D0",
    'secondary-light': '#C2ABFA',
    'secondary-color-light': "#F3EEFF",
    primary: " #2AAC7F",
    "primary-light": "#85BBA1",
    "graph-primary": "#212121",
    "graph-secondary": "#00C627"
}

export const shopNameOptionId = [{
    id: "031e99f07f2446a580b994d42a3710f9",
    name: "Calgary",
},{
    id: "b7d10fc48a3e4fb8a15d1f5d3d984db9",
    name: "Vancouver",
}]