import { apiGet } from '../../utils';

export function getAllCustomersAPI(data){
    return apiGet(`api/v1/admin/customer`,data);
}

export function getCustomerOrdersAPI(id, data) {
    const { limit = 9, skip = 0 , start=0 } = data || {};
    const queryString = `?limit=${limit}&skip=${skip}&start=${start}`;
    return apiGet(`api/v1/admin/customer/${id}${queryString}`);
}
