import { useMemo, useState, useCallback, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string'
import { Box } from '@material-ui/core';
import { Helmet } from 'react-helmet-async';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import { Container, Stack, Typography, MenuItem, Button, TextField, Grid, InputAdornment, } from '@mui/material';
import _, { filter } from 'lodash';
import Select from 'react-select';
import * as customerActions from "../redux/actions/customer";
import * as commonActions from '../redux/actions/common';
import CommonLoading from "../components/common/CommonLoading";
import { filterOptions } from '../utilities/helper';
import { colorStyles } from '../utils';
import CustomInput from '../components/common/CustomInput';
import CustomTable from '../components/common/CustomTable';
import ViewIcon from '../components/common/Icons/ViewIcon'
import TableLoader from '../components/common/TableLoader';




const columns = [
  {
    name: 'S.no',
    key: 's_no',
  },
  {
    name: 'Customer Email',
    key: 'email',
  },
  {
    name: "First Name",
    key: 'firstName'
  },
  {
    name: 'Last Name',
    key: 'lastName',
  },
  {
    name: 'Phone Number',
    key: 'primaryPhone',
  },

  // {
  //   name: 'Status',
  //   key: 'active',
  // },
  {
    name: 'Actions',
    key: 'actions'
  },

]

export default function CustomerPage() {
  const [currentPage, setCurrentPage] = useState(0);
  const { allCustomers: { values = [], total = 0 }, isLoading } = useSelector(state => state.customers, shallowEqual)
  const [isActiveTab, setIsActiveTab] = useState('All')

  const debouncedSave = useCallback(_.debounce(filters => hitRequest(filters), 1000), []);

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [search, setSearch] = useState("")
  const [filters, setFilters] = useState({
    limit: 10,
    skip: 0,
    page: 0,
  })

  const onChangePagination = (e, page) => {
    const skip = page * filters.limit
    setFilters({ ...filters, skip, page })
    hitRequest({ ...filters, skip, page, searchTerm: search })
  }

  const onChangeRowsPerPage = (e) => {
    const newFilters = { limit: parseInt(e, 10), page: 0, skip: 0 }
    setFilters(newFilters)
    setCurrentPage(0)
    hitRequest({ ...newFilters, searchTerm: search })
  }



  const totalPages = useMemo(() => {
    return Math.ceil(total / filters.limit) > 1 ? Math.ceil(total / filters.limit) : 1

  }, [total, filters.limit]);


  const handlePrevPage = (prevPage) => {

    setCurrentPage(prevPage)
    const skip = prevPage * filters.limit
    setFilters({ ...filters, skip, page: prevPage })
    hitRequest({ ...filters, skip, page: prevPage, searchTerm: search, })
  };
  const handleNextPage = (nextPage) => {
    setCurrentPage(nextPage)
    const skip = nextPage * filters.limit 
    setFilters({ ...filters, skip, page: nextPage })
    hitRequest({ ...filters, skip, page: nextPage, searchTerm: search, })
  };


  const handleChange = (e) => {
    const { value } = e.target
    if (value.length === 1 && value[0] === ' ') {
      setSearch('');
      return;
    }
    // if(value.includes('(') || value.includes(')') ||  value.includes('+')){
    //   return;
    // }
    const newFilters = { limit: 10, skip: 0, page: 0, searchTerm: value , cartType: isActiveTab}
    setSearch(value);
    setFilters({ limit: 10, skip: 0, page: 0 })
    setCurrentPage(0)
    debouncedSave(newFilters);
  }


  const onChangeSelectedTab = (e) => {
    const status = e?.value;
    const newFilters = { limit: 10, skip: 0, page: 0, }
    setIsActiveTab(status)
    setFilters(newFilters)
    setCurrentPage(0)
    hitRequest({ ...newFilters, searchTerm: search, cartType: status })
  }


  const hitRequest = (filters = {}) => {
    dispatch(customerActions.getAllCustomers({ ...filters, }))
  }
  const parseRowData = useMemo(() => {
    return values?.map(((customer, idx) => {

      return {
        ...customer,
        s_no: filters.skip + (idx + 1),
        email: customer?.email,
        firstName: customer?.firstName,
        lastName: customer?.lastName,
        actions: <Box>
        <ViewIcon onClick={() => navigate(`/dashboard/customers/viewCustomer/${customer.id}`)} />
      </Box>
      }

    })) || []

  }, [values])

  useEffect(() => {
    let newFilters = {}
    let searchT = ''
    let newActive = true
    if (location.search) {
      const { limit = 10, skip = 0, page = 0, searchTerm = '', active = true, cartType = "" } = queryString.parse(location.search);

      searchT = searchTerm
      newActive = active === "true"
      newFilters = {
        limit: Number(limit),
        skip: Number(skip),
        page: Number(page),
      }
      setIsActiveTab(cartType)
      setFilters(newFilters)
      setSearch(searchT)
      setCurrentPage(skip / limit)
      hitRequest({ ...newFilters, searchTerm: searchT, cartType, sortBy: 'active' })
      return;
    }
    hitRequest({ ...newFilters, searchTerm: searchT, cartType: "all" })

  }, [])

  useEffect(() => {

    location.search = queryString.stringify({ ...filters, searchTerm: search, cartType: isActiveTab });
    navigate({
      pathname: '/dashboard/customers',
      search: location.search
    })
    //   window.onpageshow = function(event) {
  //     if (event.persisted) {
  //         window.location.reload();
  //     }
  // };
  }, [filters, search, isActiveTab])

  const onClickClearInputField = () => {
    if (!search) return
    const newFilters = { limit: 10, skip: 0, page: 0, }
    setFilters(newFilters)
    hitRequest({  ...newFilters,  cartType: isActiveTab })
    setSearch('')
  }


  return (
    <>
      <Helmet>
        <title>Customers | Ankar Admin </title>
      </Helmet>
      <Grid item xs={12} className="manage-product-section">
        <Grid container className=''>
          <Grid item xs={12} sm={6} md={4} xl={4} className="page-heading">
            <h2 className='m-0'> Customers</h2>
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={12} className="d-flex p-0 fixed-top search-section jc-content-end">
            <div className="search-input">
              <CustomInput
                label="Search"
                name="search"
                placeholder="Search"
                onChange={handleChange}
                value={search}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: search ? (
                    <InputAdornment position="end" className="cur-pointer">
                      <ClearIcon onClick={onClickClearInputField} />
                    </InputAdornment>
                  ) : null,
                }}
              />
            </div>
          </Grid>

        </Grid>
        {
          isLoading ? <TableLoader /> : <CustomTable
            tableHeading={columns}
            rowsData={parseRowData}
            currentPage={currentPage}
            totalPages={totalPages}
            skip={filters.skip}
            handlePrevPage={handlePrevPage}
            handleNextPage={handleNextPage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            limit={filters.limit}
            onChangePagination={onChangePagination}
          />
        }
      </Grid>

    </>
  );
}