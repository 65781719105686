import { useState, useEffect } from 'react';
import { alpha, styled } from '@mui/material/styles';
import ContentLoader from 'react-content-loader';
import { Card, Typography, Skeleton } from '@mui/material';
import Iconify from '../../../components/iconify';

const StyledIcon = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
}));

export default function AppWidgetSummary({ title, dashboardStore, icon, color = 'primary', sx, ...other }) {
  const [loading, setLoading] = useState(true);
  const [displayTitle, setDisplayTitle] = useState('');
  const [displayIcon, setDisplayIcon] = useState('');

  useEffect(() => {
    const timer = setTimeout(() => {
      setDisplayTitle(title);
      setDisplayIcon(icon !== "" ? (icon) : (<Skeleton />));
      setLoading(false);
    }, 300);
    return () => clearTimeout(timer);
  }, [title, icon]);

  return (


    <Card
      sx={{
        py: 5,
        boxShadow: 0,
        textAlign: 'center',
        // color: (theme) => theme.palette[color].darker,
        // bgcolor: (theme) => theme.palette[color].lighter,
        ...sx,
      }}
      {...other}
    >
      <StyledIcon
        sx={{
          // color: (theme) => theme.palette[color].dark,
          // backgroundImage: (theme) => `linear-gradient(135deg, ${alpha(theme.palette[color].dark, 0)} 0%, ${alpha(theme.palette[color].dark, 0.24)} 100%)`,
        }}
      >
        {loading ? <Skeleton style={{height:'80px',width:'50px',borderRadius:'50%',margin:'auto'}} /> : displayIcon}
      </StyledIcon>
      <Typography variant="h3">
        {loading ? <Skeleton style={{width:'40%', margin:'auto'}} /> : dashboardStore}
      </Typography>
      <Typography variant="subtitle2">
        {loading ? <Skeleton style={{width:'40%' , margin:'auto'}}/> : displayTitle}
      </Typography> 
     
    </Card>
  
  );
}
