import React, { memo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ReactApexChart from 'react-apexcharts';
import { Box, Card, CardHeader, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { fNumber } from '../../../utilities/formatNumber';
import { useChart } from '../../../components/chart';
// import * as dashboardActions from '../../../redux/actions/dashboard';

function AppProductSold({ title,color, subheader, yearParam, dashboardStore = {}, ...other }) {
  const [dashboardData, setDashboardData] = useState(null);
  const [selectedYear, setSelectedYear] = useState(yearParam || '2022');
  const dispatch = useDispatch();

  useEffect(() => {
    if (dashboardStore?.dashboardData?.productSold && selectedYear === '2022') {
      setDashboardData(Object.values(dashboardStore.dashboardData.productSold));
    } else if (dashboardStore?.dashboardData?.productSold) {
      const data = dashboardStore?.dashboardData?.productSold[selectedYear];
      setDashboardData(data ? [data] : []);
    }
  }, [dashboardStore.dashboardData.productSold, selectedYear]);

  const chartOptions = useChart({
    tooltip: {
      marker: { show: false },
      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: () => '',
        },
      },
    },
    plotOptions: {
      line: { horizontal: false },
    },
    xaxis: {
      categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      title: {
        text: 'Product Sold',
      },
    },
    colors: ['var(--graph-secondary)'],
  });

  // const handleYearChange = (event) => {
  //   setSelectedYear(event.target.value);
  //   dispatch(dashboardActions.getDashBoardData());
  // };
    
  return (
    <Card {...other}>
      <CardHeader className="dash-cart-tittle" title={title} subheader={subheader} />
      <Box sx={{ mx: 3 }}>
    {/* <FormControl className='select-style-list' variant="standard" sx={{ mb: '16px', minWidth: '120px'}}>
     <InputLabel id="year-label">Year</InputLabel>
       <Select labelId="year-label" id="year-select" className='select-style-list' value={selectedYear} onChange={handleYearChange}>
        <MenuItem value="2021">2021</MenuItem>
        <MenuItem value="2022">2022</MenuItem>
        <MenuItem value="2023">2023</MenuItem>
  </Select>
    </FormControl>   */}

        <ReactApexChart type="line" series={[{ data: dashboardData || [] }]} options={chartOptions} height={364} />
      </Box>
    </Card>
  );
}

export default memo(AppProductSold);

