import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, Hidden } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { validateLogin } from '../../../utilities/auth';
import * as authActions from "../../../redux/actions/auth"
import { SelectedShop } from '../../../constants';


const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    height: "100%",
    width: '100%',
  },
  innerImage: {
    width: '100%',
    height: "100%",
    objectFit: "cover",
    objectPosition: "70%"
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Login() {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [showPassword, setShowPassword] = useState(false);
  const [user, setUser] = useState({
    email: '',
    password: ''
  })

  const [email, setEmail] = useState('');

  
  const [errors, setErrors] = useState({})
  const [imageLoader, setImageLoader] = useState(true);

  const { isLoading } = useSelector(state => state.auth)

  const handleForgotPassword = () => {
    window.location.href = "/ForgotPassword";
 
  };

  const onChangeHandler = ({ target: { value = '', name = '' } }) => {
    setUser({ ...user, [name]: value })
    setErrors({ ...errors, [name]: '' })
  }

  const onSubmit = (e) => {
    e.preventDefault()
    if (isLoading) return

    const { errors, isValid } = validateLogin(user)
    setErrors(errors);


    if (isValid) {
      dispatch(authActions.login(user)).then((res) => {

        console.log(res,)
        if (res) {
          // const parsedResponse = JSON.parse(res);
        

        console.log("condition is validate")
          if(res?.data?.shops?.length){
            window.localStorage.setItem(SelectedShop, JSON.stringify(res?.data?.shops[0]?.shopId));
          }
        navigate("/dashboard")
      }}).catch((error) => {
        console.error("Error during login API call", error)
       })
    } 
    else {
    console.log("out side condition")
    }
  }

  const LoaderStyle = {
    height: "100%",
    overflow: "hidden"
  }

  return (
    <div className="login-background-contianer">
      <Grid container component="main" className={`${classes.root} login-container`}>

        <Grid item xs={12} sm={12} md={12} lg={12} className="login-container-item">
          <Paper style={{ height: "100vh" }}>
            <Grid container className="" style={{ height: "100%" }}>

                  <Grid item xs={12} sm={12} md={6} style={{ display: "flex" }}>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12}  className="form-container-item">
                          <div className={classes.paper}>
                            <div className="login-logo-div">
                              <img src="../assets/images/logo.svg" alt="ANKAR" />
                            </div>
                              <form className={`${classes.form} login-form`} onSubmit={onSubmit} noValidate>
                                <TextField
                                  type="text"
                                  variant="outlined"
                                  fullWidth
                                  id="email"
                                  label="Email Address"
                                  name="email"
                                  value={user.email}
                                  onChange={onChangeHandler}
                                  autoComplete="email"
                                  error={errors && errors.email}
                                  helperText={errors && errors.email || ''} 
                                  autoFocus
                                />
                               {/* {errors?.email && <p className="error">{errors?.email}</p>} */}
                               <TextField
                                    variant="outlined"
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type={showPassword ? 'text' : 'password'}
                                    id="password"
                                    value={user.password}
                                    onChange={onChangeHandler}
                                    autoComplete="current-password"
                                    error={errors && errors.password}
                                    helperText={errors && errors.password || ''}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end" className="cur-pointer smaller-adornment" variant='outlined'>
                                          <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowPassword(!showPassword)}
                                            onMouseDown={(e) => e.preventDefault()}
                                            size="small"
                                          >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                          </IconButton>
                                        </InputAdornment>
                                      ),
                                    }}
                                  />

                                
                                    <a className="forget-password" onClick={handleForgotPassword}> Forgot password? </a>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={onSubmit}
                                >  {isLoading ? <CircularProgress size={25} style={{ color: "white" }} /> : 'Login'}
                                </Button>
                                      
                            
                              </form>
                          </div>
                        </Grid>
                    </Grid>
                  </Grid>
                  <Hidden only={['xs', 'sm']}>
                    <Grid item md={6} className={classes.image}>
                      <img src="../assets/images/first-page.jpg" alt='' onLoad={() => setImageLoader(false)} className={classes.innerImage} />
                    </Grid>
                  </Hidden>
            </Grid>
          </Paper>
        </Grid>
      </Grid >
    </div>
    );
}