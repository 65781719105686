
import {
    GET_ALL_PROMOTIONS,
    GET_ALL_PROMOTIONS_FAILED,
    GET_ALL_PROMOTIONS_SUCCESSFULL,
    GET_SPECIALS_PRODUCTS,
    GET_SPECIALS_PRODUCTS_FAILED,
    GET_SPECIALS_PRODUCTS_SUCCESSFULL,
    SYNC_PROMOTIONS,
    SYNC_PROMOTIONS_FAILED,
    SYNC_PROMOTIONS_SUCCESSFULL,
    UPDATE_PROMOTIONS,
    UPDATE_PROMOTIONS_FAILED,
    UPDATE_PROMOTIONS_SUCCESSFULL,
    UPDATE_SPECIALS_PRODUCTS,
    UPDATE_SPECIALS_PRODUCTS_FAILED,
    UPDATE_SPECIALS_PRODUCTS_SUCCESSFULL,
    DELETE_PROMOTION,
    DELETE_PROMOTION_SUCCESSFULL,
    DELETE_PROMOTION_FAILED,
    ADD_PROMOTION_PRODUCTS,
    ADD_PROMOTION_PRODUCTS_SUCCESSFULL,
    ADD_PROMOTION_PRODUCTS_FAILED
} from "../actions/promotions"


const initialState = {
    isLoading: false,
    specialProducts: {},
    allPromotions: {}
}

export default function promotionReducer(state = { ...initialState }, action = {}) {
    switch (action.type) {

      
        case GET_SPECIALS_PRODUCTS:
            return state

        case GET_SPECIALS_PRODUCTS_SUCCESSFULL:
            return { ...state, specialProducts: action.data || "" }

        case GET_SPECIALS_PRODUCTS_FAILED:
            return state

       
        case UPDATE_SPECIALS_PRODUCTS:
            return state

        case UPDATE_SPECIALS_PRODUCTS_SUCCESSFULL:
            return { ...state, specialProducts: action.data || "" }

        case UPDATE_SPECIALS_PRODUCTS_FAILED:
            return state

       
        case SYNC_PROMOTIONS:
            return state

        case SYNC_PROMOTIONS_SUCCESSFULL:
            return state

        case SYNC_PROMOTIONS_FAILED:
            return state

      
        case GET_ALL_PROMOTIONS:
            return { ...state, isLoading: true }

        case GET_ALL_PROMOTIONS_SUCCESSFULL:
            return { ...state, allPromotions: action.data, isLoading: false }

        case GET_ALL_PROMOTIONS_FAILED:
            return { ...state, isLoading: false }

        
        case UPDATE_PROMOTIONS:
            return state

        case UPDATE_PROMOTIONS_SUCCESSFULL:
            return state

        case UPDATE_PROMOTIONS_FAILED:
            return state

       
        case DELETE_PROMOTION:
            return state

        case DELETE_PROMOTION_SUCCESSFULL:
            return state

        case DELETE_PROMOTION_FAILED:
            return state

        case ADD_PROMOTION_PRODUCTS:
            return state

        case ADD_PROMOTION_PRODUCTS_SUCCESSFULL:
            return state

        case ADD_PROMOTION_PRODUCTS_FAILED:
            return state

        default:
            return state
    }
}