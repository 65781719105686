import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import ContentLoader from 'react-content-loader';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Button, Typography, TextField, FormHelperText } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
// import { Skeleton } from '@material-ui/lab'
import CustomInput from '../../../components/common/CustomInput';
import * as productsAction from "../../../redux/actions/products";
import * as commonAction from "../../../redux/actions/common";
import { BASE_URL } from '../../../constants';
import { validateCategoryData } from '../../../utilities/validations/category';

function AddCategory(props) {
    const classes = useStyles();
    const dispatch = useDispatch()

    const { modalData } = useSelector(state => state.common)
    const { categoryImages } = useSelector(state => state.products)
    const { title = "Add", data: editData = {} } = modalData

    const [bannerImage, setBannerImage] = useState([]);
    const [category, setCategory] = useState({
        categoryName: "", metaTitle: "", metaDescription: "", bannerImages: [], banners: [{
            fileName: "",
            imageSrc: "",
            link: ""
        }]
    })
    const [selectedImage, setSelectedImage] = useState('')
    const [errors, setErrors] = useState({ ...modalData })


    useEffect(() => {
        if (editData) {
            setCategory({
                categoryName: editData.categoryName || '',
                metaTitle: editData.metaTitle || '',
                metaDescription: editData.metaDescription || '',
                banners: editData.banners || []
            })
            setSelectedImage(editData.image || '')
        }
    }, [])

    // const handleChangeCategory = (e) => {
    //     const { name, value } = e.target

    //     setCategory({ ...category, [name]: value })
    // }

    const handleChangeCategory = (e, index) => {
        const { name, value } = e.target;

        const updatedBanners = category.banners.map((banner, i) =>
            i === index ? { ...banner, [name]: value } : banner
        );

        setCategory((prevCategory) => ({
            ...prevCategory,
            [name]: value,
            banners: updatedBanners,
        }));
    };

    const addCategory = () => {
        const { isValid = false, errors = {} } = validateCategoryData(category);
        if (isValid) {
            let updatedData = { ...category };
            if (selectedImage) {
                updatedData = {
                    ...updatedData,
                    image: selectedImage,
                };
            }
            dispatch(productsAction.addCategory(updatedData, ((editData && editData._id) || ""))).then(() => {
                dispatch(productsAction.getAllCategory());
                dispatch(commonAction.closeCustomModalDialog());
            }).catch(() => { });
        } else {
            setErrors(errors);
            console.log("err", errors);
        }
    };




    const onClickSelectImage = (url) => {
        setSelectedImage(prevState => prevState === url ? '' : url)
    }

    const imageChangeHandler = (e) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('image', file);

        dispatch(productsAction.uploadCategoryImage(formData)).then((res) => {
            const newBanner = {
                fileName: file.name,
                imageSrc: res?.data?.url,
                link: '',
            };

            setCategory((prevCategory) => ({
                ...prevCategory,
                banners: [...prevCategory.banners, newBanner],
            }));
            setBannerImage([...bannerImage, res?.data?.url])
        });
    };


    // const imageChangeHandler = (e) => {
    //     const file = e.target.files[0]
    //     const formData = new FormData();
    //     formData.append('image', file)
    //     dispatch(productsAction.uploadCategoryImage(formData)).then((res) => {
    //         // console.log("res")
    //         setCategory({ ...category, banners: [...category.banners, res?.data?.url] })
    //         setBannerImage([...bannerImage, res?.data?.url])
    //     });
    // }

    // const deleteBannerImage = (index) => {
    //     const deletedImage = category?.banners.filter((item, idx) => idx === index);
    //     const bannerImage = category?.banners.filter((item, idx) => idx !== index);
    //     // console.log("dATa image",  deletedImage)
    //     const data = {
    //         image: deletedImage[0]
    //     }

    //     dispatch(productsAction.deleteBannerImages(data))
    //         .then(() => {
    //             setCategory({
    //                 ...category,
    //                 banners: bannerImage,
    //             });
    //         })
    // };

    const updatedCategoryBanner = (updatedData) => {
        
        dispatch(productsAction.addCategory(updatedData, ((editData && editData._id) || ""))).then(() => {
            dispatch(productsAction.getAllCategory());
        }).catch(() => { });
   
};

    const deleteBannerImage = (index) => {
        const deletedBanner = category?.banners[index];

        const data = {
            image: deletedBanner.imageSrc,
        };

        console.log("Deleting Image: ", data);
        dispatch(productsAction.deleteBannerImages(data))
            .then((response) => {
                // console.log("Delete Image Response: ", response);

                const updatedBanners = category?.banners.filter((item, idx) => idx !== index);
                const deletedData = {banners : updatedBanners}
                updatedCategoryBanner(deletedData)
                setCategory((prevCategory) => ({
                    ...prevCategory,
                    banners: updatedBanners,
                }));
            })
            .catch((error) => {
                console.error("Error deleting banner image:", error);
            });
    };



    return (
        <Grid container component="main" className={`${classes.root} add-category-modal-container`}>

            <Grid item xs={12} className="header-modal">
                <h3>{title} Category</h3>
            </Grid>
            <Grid item xs={12}>
                <h4 className="cus-label">Please Select Image</h4>
            </Grid>
            <Grid item xs={4} className="add-category-input">
                <Grid container>
                    <Grid item xs={12} className="category-image-item-2">
                        {
                            selectedImage ?
                                <div className="select-category-image selected" onClick={() => setSelectedImage('')}>
                                    <img crossOrigin="anonymous" src={(selectedImage.startsWith("http")) ? selectedImage : BASE_URL?.concat(selectedImage)} alt="" />
                                </div>
                                :
                                <div className="select-category-image selected">
                                    <img crossOrigin="anonymous" className="w-100" src="/assets/images/image.svg" alt="" />
                                </div>
                        }


                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={8} className="add-category-input">
                <Grid container>
                    <Grid item xs={12} className="category-images-container">
                        <Grid container>
                            <Grid item xs={12} className="scrollable-sec">
                                {categoryImages?.length > 0 ? (
                                    <Grid container>
                                        {categoryImages?.map(image => {
                                            const isSelected = image.url === selectedImage
                                            return (
                                                <Grid item xs={3} className="inner-select-image-item">
                                                    {isSelected ? <div className="tick-placement"><CheckCircleIcon /></div> : null}
                                                    <div className={`select-category-image ${isSelected ? 'disable-select' : ''}`} onClick={() => onClickSelectImage(image.url)}>
                                                        <img crossOrigin="anonymous" src={(image.url)} alt="https://new-ankar-admin-backend.js.thcs.in//categories/edibles.svg" />
                                                    </div>
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                ) : (
                                    <ContentLoader
                                        height={160}
                                        width={360}
                                        speed={2}
                                        primaryColor="#f3f3f3"
                                        secondaryColor="#d9d9d9"
                                    >
                                        <rect x="0" y="0" width="100%" height="32" />
                                        <rect x="0" y="50" width="100%" height="32" />
                                        <rect x="0" y="100" width="100%" height="32" />

                                    </ContentLoader>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={12}>
                        <CustomInput
                            name="categoryName"
                            value={category?.categoryName || ""}
                            onChange={(e) => { handleChangeCategory(e) }}
                            label="Category Name"
                            className="w-100"
                            margin="normal"
                        />
                        {errors?.categoryName && <p className="error">{errors?.categoryName}</p>}
                    </Grid>
                    <Grid item xs={12}>
                        <CustomInput
                            name="metaTitle"
                            value={category?.metaTitle || ""}
                            onChange={(e) => { handleChangeCategory(e) }}
                            label="Meta Title"
                            className="w-100"
                            margin="normal"
                        />
                        {errors?.metaTitle && <p className="error">{errors?.metaTitle}</p>}
                    </Grid>
                    <Grid item xs={12} className="pt-2 add-category-input">
                        <TextField
                            className="blaze-zipcode-textarea"
                            name="metaDescription"
                            variant="outlined"
                            value={category?.metaDescription || ""}
                            onChange={(e) => { handleChangeCategory(e) }}
                            label="Meta Description"
                            multiline
                            fullWidth
                            rows={6}
                        />
                        {errors?.metaDescription && <p className="error">{errors?.metaDescription}</p>}
                    </Grid>
                    <Grid item xs={12}>

                        <div className="mb-2 mt-2">
                            <h3>Add Category Banner </h3>

                            <input type='file' name='filePath'
                                onChange={imageChangeHandler}
                            />
                            <p className='banner-info' > <b>Note: </b> Banner image should be 1600x150</p>
                            {
                                category?.banners.length ?
                                    <ul className="mb-2 mt-2 img-uploaded">
                                        {
                                            category?.banners?.map((item, index) => {
                                                return (
                                                    <>
                                                        <li>
                                                            <img key={index} src={item.imageSrc} alt="" />
                                                            <span onClick={() => deleteBannerImage(index)} >
                                                                <CloseIcon />
                                                            </span>
                                                        </li>
                                                        <Grid item xs={12} md={12} className="pt-2 pb-2">
                                                            <CustomInput
                                                                label="Banner Link"
                                                                placeholder="Banner Link"
                                                                name="link"
                                                                value={(item.link) || ""}
                                                                fullWidth
                                                                onChange={(e) => { handleChangeCategory(e, index) }}
                                                            />
                                                        </Grid>
                                                    </>
                                                )
                                            })
                                        }
                                    </ul>
                                    : ''
                            }
                        </div>


                    </Grid>
                    <Grid item xs={12} className="jc-ctr add-button">
                        <Button onClick={() => { addCategory() }} variant="contained" color="primary" fullWidth>Submit</Button>
                    </Grid>
                </Grid>
            </Grid>

        </Grid >
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
    },

}));


export default AddCategory