import { toast } from 'react-toastify';
import { createNewDeliveryHourAPI, deleteDeliverHoursAPI, getAllDeliveryHoursAPI, updateDeliverHoursAPI } from '../api/deliveryHours';


export const GET_DELIVERY_HOURS_REQUEST = 'GET_DELIVERY_HOURS_REQUEST';
export const GET_DELIVERY_HOURS_SUCCESS = 'GET_DELIVERY_HOURS_SUCCESS';
export const GET_DELIVERY_HOURS_FAILED = 'GET_DELIVERY_HOURS_FAILED';

export function  getAllDeliveryHours(data) {
    return dispatch => {
        dispatch({
            type: GET_DELIVERY_HOURS_REQUEST
        })
        return new Promise((resolve, reject) => {
            getAllDeliveryHoursAPI(data)
                .then((res) => {
                    dispatch({
                        type: GET_DELIVERY_HOURS_SUCCESS,
                        payload: res?.data
                    })
                    return resolve(res)
                })
                .catch(err => {
                    dispatch({
                        type: GET_DELIVERY_HOURS_FAILED
                    })
                    toast.error(err)
                    return reject(err)
                })
        })
    }
}



export function postDeliveryHours(data) {
    return new Promise((resolve, reject) => {
        createNewDeliveryHourAPI(data)
            .then((res) => {
                return resolve(res)
            })
            .catch((err => {
                toast.error(err)
                return reject(err)
            }))

    })
}


export function updateDeliveryHours(id, data) {
    return new Promise((resolve, reject) => {
        updateDeliverHoursAPI(id, data)
            .then(res => {
                toast.success(res.message)
                return resolve(res)
            })
            .catch((err => {
                toast.error(err)
                return reject(err)
            }))
    })
}


export function deleteDeliverHours(id) {
    return new Promise((resolve, reject) => {
        deleteDeliverHoursAPI(id)
            .then(res => {
                toast.success(res.message)
                return resolve(res)
            })
            .catch((err => {
                toast.error(err.message)
                return reject(err)
            }))
    })
}