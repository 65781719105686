import React from 'react';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import {  useSelector } from 'react-redux';

export default function OrderViewModal(props) {
    const { items } = useSelector((state) => state.common.modalData);
    console.log("items",items)

    return (
        <Grid container component="main" className="view-order-modal-container mt-60">
            <Grid item xs={12} className="header-modal">
                <h3>Order Details</h3>
            </Grid>
            {items?.map((row) => (
                <Grid item xs={12} key={row?._id}>
                    <Box className="list-view d-flex" style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography> <strong>Name: </strong> {row?.product?.name || '-'}</Typography>
                        <Typography><strong>Quantity: </strong>  {row?.quantity || '-'}</Typography>
                      <Typography><strong>Price: </strong>  {row?.unitPrice ? `$${row.unitPrice}` : '-'}</Typography>

                    </Box>
                </Grid>
            ))}
        </Grid>
    );
}
