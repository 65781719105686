import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom"
import {
    Grid,
    FormGroup,
    Button,
    Typography,
    TextField,
    Switch,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    TextareaAutosize,
    Box,
    Divider,
    Card,
} from '@material-ui/core';
import ContentLoader from 'react-content-loader';   // eslint
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@mui/material';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import CustomInput from '../common/CustomInput';
import CustomCheckbox from '../common/CustomCheckBox'
import TableLoader from '../common/TableLoader';
import { getProductUnitByCategoryId, capitalizeString, getPercentage, flowerTypeImg } from '../../utils';
import { imagesdata } from '../../constants'

function EditProductDetails(props) {

    const {
        infoAndEffectsData,
        singleProductDetailData,
        data,
        onChangeCheckbox,
        handleSubmit,
        onChangeBrandDescription,
        productStatus,
        onChangeProductStatus,
    } = props;

    const {
        name,
        category,
        brand,
        flowerType,
        // customWeight,
        thc,
        cbd,
        cbn,
        unitPrice = 0,
        potencyAmount,
        description,
        priceBreaks,
        adminProductName = '',
        bmwCategoryName = '',
        isCopyText = false,
        assets = [],
        overrideDescription,
        isBestSelling = false,
    } = singleProductDetailData;


    const navigate = useNavigate();
    const location = useLocation();
    const { isLoading, setisLoading } = useState(true);
    const [isSwitchDisabled, setSwitchDisabled] = useState(false);
    const [showNoImage, setShowNoImage] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowNoImage(true);
        }, 10000);

        return () => clearTimeout(timer);
    }, []);

    const handleStatusChange = (event) => {
        setSwitchDisabled(true);
        onChangeProductStatus(event);
        setTimeout(() => {
            setSwitchDisabled(false);
        }, 5000);
    };


    const infoEffectObj = infoAndEffectsData?.length && infoAndEffectsData?.filter((v) => v?.strainType === (flowerType?.toLowerCase()))[0]
    const infoEffectArr = (infoEffectObj?.infoEffects) || [];
    // const salesPrice = (priceBreaks?.length && priceBreaks[0]?.salePrice) || null;
    const unitTypeByCategoryName = getProductUnitByCategoryId(bmwCategoryName, { thc, cbd, cbn }, potencyAmount) || {};

    let salesPrice = (priceBreaks && priceBreaks.length && priceBreaks[0] && priceBreaks[0].salePrice) || null;

    salesPrice = (salesPrice === 0) || (unitPrice === salesPrice) ? null : salesPrice

    const focusKeywordStr = (data?.focusKeyword?.length) ? data?.focusKeyword?.toString() : "";

    useEffect(() => {
        // location.search = queryString.stringify({ ...filters, searchTerm: search, isActive: isActiveTab });
        window.onpageshow = function (event) {
            if (event.persisted) {
                window.location.reload();
            }
        };
    }, []);

    const handleGoBack = () => {
        window.history.back();
    };

    return (
        <>
            <Grid container className=' py-0'>
                <Grid item xs={12} className="d-flex">
                    <div onClick={handleGoBack} className="cur-pointer d-flex align-center mb-1 back-btn">
                        <KeyboardBackspaceIcon />
                        <h2 className='m-0'>Back to Products</h2>
                    </div>

                    <Grid item xs={12} sm={4} md={4} xl={4} className="page-heading">
                        <h2 className='m-0'>Edit Menu Item</h2>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={3} className="">
                <Grid item xs={12} className="productDetailsleft">
                    <Card className=''>
                        <Grid container className='p-3'>
                            <Grid item xs={12}>
                                <h4 className=' mt-0 mb-0'>Website Product Name:</h4>
                            </Grid>
                            <Grid item xs={12} md={12} className="pt-2 pb-2">
                                <CustomInput
                                    label="Website Product Name"
                                    placeholder="Enter Website Product Name"
                                    name="websiteProductName"
                                    value={(data?.websiteProductName) || ""}
                                    fullWidth
                                    onChange={(e) => { onChangeBrandDescription(e) }}
                                />
                            </Grid>

                            <Grid item xs={6} lg={4} md={4} className="pb-0 list-label list-label">
                                <Typography ><strong> Category:</strong> <span> {bmwCategoryName || "-"}</span></Typography>
                            </Grid>
                            <Grid item xs={6} lg={4} md={4} className="pb-0 list-label">
                                <Typography ><strong> Sub-category:</strong> <span> {(category?.name) || "-"}</span></Typography>
                            </Grid>
                            <Grid item xs={6} lg={4} md={4} className="pb-0 list-label">
                                <Typography ><strong> Admin Product Name:</strong> <span> {adminProductName || "-"}</span></Typography>
                            </Grid>
                            <Grid item xs={6} lg={4} md={4} className="pb-0 list-label">
                                <Typography ><strong> Brand:</strong> <span> {(brand && brand.name) || "-"}</span></Typography>
                            </Grid>
                            <Grid item xs={6} lg={4} md={4} className="pb-0 list-label">
                                <Typography ><strong> Type:</strong> <span> {flowerType || "-"}</span></Typography>
                            </Grid>
                            <Grid item xs={6} lg={4} md={4} className="pb-0 list-label">
                                <Typography ><strong> THC Content:</strong> <span> {unitTypeByCategoryName.thc || "-"}</span></Typography>
                            </Grid>
                            <Grid item xs={6} lg={4} md={4} className="pb-0 list-label">
                                <Typography ><strong> CBD Content:</strong> <span> {unitTypeByCategoryName.cbd || "-"}</span></Typography>
                            </Grid>
                            <Grid item xs={6} lg={4} md={4} className="pb-0 list-label">
                                <Typography ><strong> CBN Content:</strong> <span> {unitTypeByCategoryName.cbn || "-"}</span></Typography>
                            </Grid>
                            <Grid item xs={6} lg={4} md={4} className="pb-0 list-label">
                                {/* <Typography ><strong> Price :</strong> <span> ${parseFloat(unitPrice).toFixed(2) || "-"}</span></Typography> */}
                            </Grid>
                            {
                                salesPrice ?
                                    <Grid item xs={6} lg={4} md={4} className="pb-0 list-label">
                                        {/* <Typography ><strong> Sale Price :</strong> <span> ${parseFloat(salesPrice).toFixed(2) || "-"}</span></Typography> */}
                                    </Grid>
                                    : null
                            }
                            <Grid item xs={6} lg={4} md={4} className="pb-0 list-label">
                                <Grid container  >
                                    <Grid xs={8}>
                                        <Typography ><strong> Best Selling Product:</strong>  </Typography>
                                    </Grid>
                                    <Grid xs={4} className='mt-1'>
                                        <CustomCheckbox
                                            checked={data?.isBestSelling}
                                            name='isBestSelling'
                                            onChange={onChangeBrandDescription}
                                        />
                                    </Grid>
                                </Grid>

                            </Grid>
                            <Grid item xs={12} className="pb-0 list-label">
                                <Typography ><strong> Description:</strong> <span> {description || "-"}</span></Typography>
                            </Grid>
                            {/* <Grid container spacing={3} className="pb-0 list-label" > */}
                            <Grid item xs={12} md={12} className="pb-0 list-label mt-2  product-align-single-meta">
                                <TextareaAutosize
                                    fullWidth
                                    placeholder="Override Description"
                                    name="overrideDescription"
                                    value={data?.overrideDescription}
                                    onChange={onChangeBrandDescription}
                                    maxLength
                                    className="w-100"
                                    multiline
                                    rowsMin={5}
                                    variant="outlined"
                                />
                            </Grid>
                            {/* </Grid> */}
                        </Grid>
                        <Box>
                            <Grid container>
                                <Grid item xs={12} className='pb-0 list-label no-shadow'>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                            className='accordion-style'
                                        >
                                            <h4 className=' mt-0 mb-0'>Product SEO Data</h4>

                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid container spacing={3}>
                                                <Grid item xs={6} md={12} className="product-align-single-meta">
                                                    <CustomInput
                                                        fullWidth
                                                        label="Meta Titile"
                                                        value={data?.metaTitle}
                                                        name="metaTitle"
                                                        onChange={onChangeBrandDescription}
                                                    />
                                                </Grid>
                                                <Grid item xs={6} md={12} className="product-align-single-meta">
                                                    <CustomInput
                                                        fullWidth
                                                        label="Focus Keywords"
                                                        value={data?.focusKeyword}
                                                        name="focusKeyword"
                                                        onChange={onChangeBrandDescription}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={12} className="product-align-single-meta">
                                                    <TextField
                                                        fullWidth
                                                        label="Meta Description"
                                                        variant="outlined"
                                                        value={data?.metaDescription}
                                                        name="metaDescription"
                                                        onChange={onChangeBrandDescription}
                                                    />
                                                </Grid>
                                                
                                                <Grid item xs={12} md={12} className="product-align-single-meta">
                                                    <TextareaAutosize
                                                        placeholder="SEO Content"
                                                        fullWidth
                                                        maxLength
                                                        value={data?.seoContent}
                                                        className="w-100"
                                                        variant="outlined"
                                                        multiline
                                                        rowsMin={5}
                                                        name="seoContent"
                                                        onChange={onChangeBrandDescription}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            </Grid>
                        </Box>

                        {/* <Divider /> */}

                        {/* <Box>
                            <Grid container>
                                <Grid item xs={12} className='pb-0 list-label'>
                                    <Accordion className='no-shadow'>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                            name="metaTitle"
                                            className='accordion-style'

                                        >
                                            <h4 className=' mt-0 mb-0 '>Product Effects</h4>

                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid item xs={12} md={12} className="effect-align-checkbox" >
                                                {
                                                    infoEffectArr?.length > 0 ?
                                                        <FormGroup className="effects-listing  ">
                                                            {infoEffectArr?.map((item, index) => {
                                                                const { title } = item;
                                                                const capatlizedTitle = capitalizeString(title);
                                                                const value = data?.infoEffects?.includes(title);
                                                                const selectedImage = imagesdata?.filter(data => data.title === title);

                                                                return (
                                                                    <div className='innerGridstyle cur-pointer'>
                                                                        <Box className={value ? "select" : "unselect"}
                                                                            onClick={(e) => onChangeCheckbox(e, title)}>
                                                                            <CustomCheckbox
                                                                                checked={value || false}
                                                                            />
                                                                            <img src={selectedImage[0]?.src || "/images/ProductEffectImage/default.svg"}
                                                                                alt={selectedImage[0]?.alt || "default_image"} />
                                                                            <p>{capatlizedTitle}</p>
                                                                        </Box>
                                                                    </div>
                                                                );
                                                            })}
                                                        </FormGroup>
                                                        :
                                                        <div className="no-data txt-center">
                                                            <img src="/assets/images/noDataFound.svg" alt="" />
                                                            <Typography variant="h5">No product effects yet </Typography>
                                                        </div>
                                                }
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            </Grid>
                        </Box> */}

                        {/* <Divider /> */}

                        <Box>
                            <Grid container className='px-2'>
                                <Grid item xs={12} className='pb-0 list-label'>
                                    <Accordion className='no-shadow'>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                            className='accordion-style'
                                        >
                                            <h4 className=' mt-0 mb-0'>Product Meta Data</h4>

                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12}>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={6} className="product-align-single-meta">
                                                            <CustomInput
                                                                fullWidth
                                                                label="Product Name"
                                                                name="websiteProductName"
                                                                value={data?.websiteProductName}
                                                                defaultValue="Product Name"
                                                                onChange={onChangeBrandDescription}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <CustomInput
                                                                fullWidth
                                                                label="Product Description"
                                                                name="productDescription"
                                                                value={data?.productDescription}
                                                                onChange={onChangeBrandDescription}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextareaAutosize
                                                        fullWidth
                                                        placeholder="Brand Description"
                                                        name="brandDescription"
                                                        value={data?.brandDescription}
                                                        onChange={onChangeBrandDescription}
                                                        maxLength
                                                        className="w-100"
                                                        multiline
                                                        rowsMin={5}
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <div className='disclamimer-border'>
                                                        <Grid container className='copy-to-all-products'>
                                                            <Grid item xs={12} >
                                                                <CustomInput
                                                                    className="input"
                                                                    fullWidth
                                                                    label="Product Disclaimer"
                                                                    name="disclaimerText"
                                                                    value={(data?.disclaimerText) || ""}
                                                                    onChange={(e) => { onChangeBrandDescription(e) }}
                                                                />
                                                                <CustomCheckbox
                                                                    label="Copy to all products"
                                                                    name="isCopyText"
                                                                    value={data?.isCopyText}
                                                                    onChange={onChangeBrandDescription}
                                                                />
                                                            </Grid>

                                                        </Grid>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            </Grid>
                        </Box>
                        {/* <Divider /> */}
                        <Box>
                            <Grid container className='p-3'>
                                <Grid item xs={12} className=''>
                                    <div className="product-save-btn d-flex jc-content-end">
                                        <Button
                                            onClick={(e) => { handleSubmit(e) }}
                                            variant="contained"
                                            color="primary">
                                            Save
                                        </Button>
                                    </div>
                                </Grid>
                            </Grid>
                        </Box>
                    </Card>
                </Grid>
                <Grid item xs={12} className="productDetailsrigh">
                    <Card className='p-3 box-style'>
                        <div className="cus-pro-list">
                            {salesPrice ? <span className="flower-type">{getPercentage(unitPrice, salesPrice) || 0}% Off</span> : null}
                            <div className="pro-img" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                {assets && assets[0] && assets[0].thumbURL ? (
                                    <img src={assets[0].thumbURL} alt="Product" />
                                ) : showNoImage ? (
                                    <img src={'/assets/dummy-post.jpg'} alt="" />
                                ) : (
                                    <div style={{ width: '100px', height: '100px', position: 'relative' }}>
                                        <CircularProgress color="inherit" style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, margin: 'auto' }} />
                                    </div>
                                )}
                            </div>
                            <div className="pro-card-header">
                                <div className="pro-mood">
                                    <span>
                                        {flowerTypeImg(flowerType)}
                                    </span>
                                    <p>THC: {unitTypeByCategoryName.thc}</p>
                                </div>
                                <div className="pro-title">
                                    <h5>{name}</h5>
                                </div>
                                <div className="pro-price">
                                    <span className={salesPrice ? "pro-price-amount-del" : "pro-price-amount"}>
                                        <span className="price-currency-symbol">$</span>{Number(unitPrice).toFixed(2)}
                                    </span>
                                    {salesPrice ?
                                        <span className="pro-price-amount">
                                            <span className="price-currency-symbol">$</span>{Number(salesPrice).toFixed(2) || ""}
                                        </span>
                                        : null
                                    }
                                </div>
                            </div>
                        </div>
                        <Typography className="text-left mt-3">
                            <strong>Status :</strong>
                            <Switch
                                checked={productStatus}
                                onChange={handleStatusChange}
                                color="primary"
                                name="active"
                                disabled={isSwitchDisabled}
                            />
                            <p style={{ margin: 0 }}>
                                {productStatus
                                    ? <span>This item is <span style={{ color: "green", fontWeight: 600 }}>ACTIVE</span> and will be shown on your menu.</span>
                                    : <span>This item is <span style={{ color: "red", fontWeight: 600 }}>INACTIVE</span> and will not be shown on your menu.</span>
                                }
                            </p>
                        </Typography>
                    </Card>

                </Grid>
            </Grid>
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },

}));


export default EditProductDetails