import { Navigate, useRoutes } from 'react-router-dom';
import { Component, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';

//

import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import ManageProductsPage from './sections/@dashboard/products';
import DashboardAppPage from './pages/DashboardAppPage';
import PromotionsPage from './sections/@dashboard/promotions/index';
import OrderPage from './pages/OrderPage';
import BrandPage from './pages/BrandsPage';
import CustomerPage from './pages/CustomerPage';
import ManageDeliveryPage from './pages/ManageDeliveryHoursPage';
import PrivateRoutes from './components/privateRoutes';
import ManageCategoriesPage from './sections/@dashboard/categories';
import { getData } from './utils';
import ProductDetails from './pages/ProductDetails';
import NotificationsPage from './pages/NotificationsPage';
import AddNotificationPage from './pages/AddNotificationsPage';
import EditNotificationPage from './pages/EditNotificationPage';
import ViewCustomerPage from './pages/ViewCustomerPage';
import * as authActions from './redux/actions/auth'
import { ForgotPassword } from './sections/auth/login';
import ResetPassword from './sections/auth/login/resetPassword';
import SettingsPage from './pages/SettingsPage'
import ViewOrderPage from './pages/OrderDetailsPage'
import BannerImage from './pages/BannerImage';
import CannaSettings from './pages/CannaSettings';

// ----------------------------------------------------------------------
let id = null

export default function Router() {
  const dispatch = useDispatch()

  const { lastSyncDate } = useSelector(state => state.auth)

  useEffect(() => {
    id = setInterval(timer, 900000);

    return () => {
      clearInterval(id)
    }
  }, [])

  function timer() {
    dispatch(authActions.getLastSyncDate())
  }
  const loggedInToken = getData('accessToken')
  const routes = useRoutes([
    {
      path: '/dashboard',
      element:<PrivateRoutes>
         <DashboardLayout />
      </PrivateRoutes>,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path:'app', element: <DashboardAppPage /> },
        { path:'manage_products', element: <ManageProductsPage /> },
        { path:'promotion', element: <PromotionsPage /> },
        { path:'order', element: <OrderPage /> },
        { path:'brands', element: <BrandPage /> },
        { path:'manage_delivery', element: <ManageDeliveryPage/>},
        { path:'manage_categories', element:<ManageCategoriesPage/>},
        { path:'manage_products/productDetails/:id', element:<ProductDetails/>},
        { path:'notifications', element:<NotificationsPage/>},
        { path:'customers',element:<CustomerPage/>},
        { path:'notifications/addNotification', element:<AddNotificationPage/>},
        { path:'notifications/editNotification/:_id', element:<EditNotificationPage/>},
        { path:'customers/viewCustomer/:id', element:<ViewCustomerPage/>},
        { path:'settings', element:<SettingsPage/>},
        { path:'order/viewOrder/:id', element:<ViewOrderPage/>},
        { path:'banners', element:<BannerImage/>},
        { path :'shopsettings', element: <CannaSettings/>}
      ],
    },
    {
      path: 'login',
      element: !!loggedInToken ? <Navigate to='/dashboard/app' /> :<LoginPage />,
    },
    {
      path: 'forgotpassword',
      element: <ForgotPassword />,
    },
    {
      path: 'ResetPassword',
      element: <ResetPassword />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}