import { apiGet, apiPost, apiPut, apiDelete } from '../../utils';

export function getAllNotificationsAPI(data) {
    return apiGet(`api/v1/admin/notification`,data)
}

export function getNotificationAPI(id, data) {
    return apiGet(`api/v1/admin/notification/${id}`, data)
}

export function createNotificationsAPI(data) {
    return apiPost(`api/v1/admin/notification/create-notification`,data)
}

export function updateNotificationsAPI(id,data) {
    return apiPut(`api/v1/admin/notification/${id}`,data)
}

export function updateNotificationsImageAPI(id,data) {
    return apiPut(`api/v1/admin/notification/upload-image/${id}`,data)
}

export function criteriaNotificationAPI(data) {
    return apiGet(`api/v1/admin/notification/criteria`,data)
}

export function deleteNotificationAPI(id) {
    return apiDelete(`api/v1/admin/notification/delete/${id}`)
}

