import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton, Grid, Container, Typography, Select, MenuItem, FormControl, InputLabel, Button } from '@mui/material';
// utils
import { bgBlur } from '../../../utilities/cssStyles';
// components
import Iconify from '../../../components/iconify';
//
// import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
// import NotificationsPopover from './NotificationsPopover';
import * as commonActions from '../../../redux/actions/common'
import { APP_NAME, AccessTokenName, SelectedShop } from '../../../constants';
import { getData, setData, } from '../../../utils';
import * as dashBoardActions from '../../../redux/actions/dashboard';



// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 69;

const StyledRoot = styled(AppBar)(({ theme, isOpen }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  boxShadow: 'none',
  // [theme.breakpoints.up('lg')]: {
  //   width:  `calc(100% - ${NAV_WIDTH + 1}px)`,
  // },
  ...(isOpen && {
    width: `calc(100% - ${NAV_WIDTH}px)`,
    marginLeft: `${NAV_WIDTH}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 3),
  },
}));

// ----------------------------------------------------------------------

Header.propTypes = {
  onOpenNav: PropTypes.func,
  isOpen: PropTypes.bool
};

export default function Header({ onOpenNav, isOpen = false }) {
  const shopType = JSON.parse(getData('ankar-admin'))?.shops
  const getShopData = JSON.parse(window.localStorage.getItem(SelectedShop))
  const [selectShop, setSelectShop] = useState(getShopData)
  const [isOpena, setIsOpen] = useState(false);

  const buttonRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (buttonRef.current && !buttonRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleLocation = (e) => {
    const shopValue = e.target.value
    setData(SelectedShop, shopValue)
    setSelectShop(selectShop)
    window.localStorage.setItem(SelectedShop, JSON.stringify(shopValue));
    // dispatch(commonActions.selectedShopName(shopValue))
    window.location.reload()
  }


  return (
    <StyledRoot isOpen={isOpen}>
      <StyledToolbar>
        <IconButton
          onClick={onOpenNav}
          sx={{
            mr: 1,
            color: 'text.primary',
            // display: { lg: 'none' },
          }}
        >
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>

          {/* <div className="d-flex algn-itm-ctr ">
            <FormControl className='drop-down-filter' variant="standard">
            <InputLabel id="year-label" className='revenue-year'>Select Shop Location:</InputLabel>

              <Select labelId="shop-label" id="shop-select" className='search-btn-drop-down-filter' value={selectShop} onChange={handleLocation}>
                {
                 shopType.map((item) => 
                    <MenuItem value={item.shopId} key={item.shopId}>{item.name}- {item.originalName}</MenuItem>
                  )
                }
              </Select>
            </FormControl>
          </div> */}
        <div className="shop-change" ref={buttonRef}>
          {
            shopType.length > 0 &&
            <>
              {shopType?.map((item) => {
                if (item.shopId === selectShop) {
                  return (
                    <Button key={item.shopId} className="btn-change-shop" onClick={() => setIsOpen(!isOpena)}>{item.name} - {item.originalName}</Button>
                  );
                }
                return null;
              })}
              {isOpena && (
                <div className="change-inner">
                  {shopType.map((item) => (
                    <Button
                      className={`btn-shop-locations ${selectShop === item.shopId ? 'selected-location' : ''}`}
                      id="shop-select"
                      onClick={handleLocation}
                      value={item.shopId}
                      key={item.shopId}
                    >
                      {item.name}- {item.originalName}
                    </Button>
                  ))}
                </div>
              )}
            </>
          }
        </div>

        {/* <Searchbar /> */}
        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction="row"
          alignItems="center"
          spacing={{
            xs: 0.5,
            sm: 1,
          }}
        >

          {/* <NotificationsPopover /> */}
          <AccountPopover />
        </Stack>
      </StyledToolbar>
    </StyledRoot >
  );
}
