import { apiGet, apiPost, apiDelete, apiPut } from '../../utils';

export function getAllProductsAPI(data) {
    const { limit = 10, skip = 0, searchTerm = '', sortBy = 'sale', categoryId = '', productTags = '', brandId = '', active = true } = data || {}
    const newData = { limit, skip, searchTerm, sortBy, categoryId, productTags, brandId, active }
    return apiGet('api/v1/admin/products', newData);
}

export function getSingleProductDetailsAPI(data) {
    return apiGet(`api/v1/admin/products/${data}`);
}

export function getInfoAndEffectAPI(data) {
    return apiGet(`api/v1/admin/products/infoEffects`);
}

export function updateSingleProductAPI(id, data) {
    return apiPost(`api/v1/admin/products/edit/${id}`, data);
}

export function addCategoryAPI(data, id) {
    if (id) {
        return apiPut(`api/v1/admin/category/${id}`, data);
    }
    return apiPost(`api/v1/admin/category`, data);
}


export function getAllCategoryAPI(data) {
    return apiGet(`api/v1/admin/category`, data);
}


export function updateCategoriesAPI(data) {
    return apiPost(`api/v1/admin/category/multi`, data);
}

export function deleteCategoryAPI(id) {
    return apiDelete(`api/v1/admin/category/${id}`);
}


export function getAllBlazeCategoryAPI(data) {
    return apiGet(`api/v1/admin/category/blaze`, data);
}

export function getAllCategoryImagesAPI(data) {
    return apiGet(`api/v1/admin/category/images`, data);
}

export function getAllBrandsByProductAPI() {
    return apiGet(`api/v1/admin/brand/blaze `);
}

export function getAllBmwBrandsAPI(data) {
    return apiGet(`api/v1/admin/brand`,data);
}

export function addBrandToBmwAPI(data) {
    return apiPost(`api/v1/admin/brand`, data);
}

export function syncProductAdminAPI(data) {
    return apiGet(`api/v1/admin/products/syncAllproducts`,data);
}

export function updateBrandFromBmwAPI(data) {
    return apiPut(`api/v1/admin/brand`, data);
}

export function deleteBrandFromBmwAPI(id) {
    return apiDelete(`api/v1/admin/brand/delete/${id}`);
}

export function getThresholdAPI(data){
    return apiGet(`api/v1/admin/products/threshold`,data);
}

export function updateThresholdAPI(data){
    return apiPut(`api/v1/admin/products/threshold`,data);
}

// Upload Image
export function uploadImageAPI(data) {
    return apiPost(`api/v1/admin/category/banner/image`, data)
}

export function deleteBannerImageAPI(data) {
    return apiPost(`api/v1/admin/category/banner/image/delete`,data);
}