import React from 'react'
import RemoveRedEyeIconComp from '@mui/icons-material/RemoveRedEye';

export default function ViewIcon({onClick}) {
    return (
        <div className=" cur-pointer  eye-icon">
            <RemoveRedEyeIconComp onClick={onClick} />
        </div>
    )
}
