import {
    GET_DASHBOARD_DATA_REQUEST,GET_DASHBOARD_DATA_SUCCESS,GET_DASHBOARD_DATA_FAILURE
} from '../actions/dashboard'

const initialState = {
    isLoading: false,
    dashboardData:{}
}

export default function(state= {...initialState},action={}) {
    switch(action.type) {
        case GET_DASHBOARD_DATA_REQUEST:{
            return {...state,isLoading:true}
        }
        case GET_DASHBOARD_DATA_SUCCESS:{
            return {...state,isLoading:false,dashboardData:action.payload}
        }
        case GET_DASHBOARD_DATA_FAILURE:{
            return {...state,isLoading:false,dashboardData:action.payload}
        }
        default :
        return state;

        }

    }

