// component
import { useLocation } from 'react-router-dom';
import AdUnitsIcon from '@mui/icons-material/AdUnits';
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel';
import SvgColor from '../../../components/svg-color';
// ----------------------------------------------------------------------
import { getData } from '../../../utils';

const userData = JSON.parse(getData('ankar-admin'));
// const location = useLocation();
  // const selectedPath = location.pathname;

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

export const ankarConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: icon('ic_dashboard'),
  },
  {
    title: 'products',
    path: '/dashboard/Manage_products',
    icon: icon('ic_products'),
  },
  {
    title: 'categories',
    path: '/dashboard/manage_categories',
    icon: icon('ic_categories'),
  },
  {
    title: 'brands',
    path: '/dashboard/brands',
    icon: icon('ic_brands'),
  },
  // {
  //   title: 'Create Project',
  //   path: '/dashboard/create_projects',
  //   icon: icon('ic_user'),
  // },
  // {
  //   title: 'Create Admin',
  //   path: '/dashboard/create_admin',
  //   icon: icon('ic_user'),
  // },
  {
    title: 'notification',
    path: '/dashboard/notifications',
    icon: icon('ic_notification'),
  },
  // {
  //   title: 'Users',
  //   path: '/dashboard/user',
  //   icon: icon('ic_user'),
  // },
  // {
  //   title: 'Users',
  //   path: '/dashboard/user',
  //   icon: icon('ic_user'),
  // },

  {
    title: 'settings',
    path: '/settings',
    icon: icon('ic_settings'),
  },
  {
    title: 'settingsnew',
    path: '/settingsnew',
    icon: icon('ic_settings'),
  },
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: icon('ic_lock'),
  // },
  {
    title: 'promotions',
    path: '/dashboard/promotion',
    icon: icon('ic_promotions'),
  },

]


const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: icon('ic_dashboard'),
    name: 'dashboard',
  },
  {
    title: 'products',
    path : '/dashboard/Manage_products',
    icon: icon('ic_products'),
    name: 'products',
  },
  {
    title: 'categories',
    path: '/dashboard/manage_categories',
    icon: icon('ic_categories'),
    name: 'categries',
  },
  {
    title: 'promotions',
    path: '/dashboard/promotion',
    icon: icon('ic_promotions'),
    name: 'promotions',
  },

  {
    title: 'orders',
    path: '/dashboard/order',
    icon: icon('ic_orders'),
    name: 'orders',
  },
  {
    title: 'brands',
    path: '/dashboard/brands',
    icon: icon('ic_brands'),
    name: 'brand',
  },
  {
    title: 'delivery Hours',
    path: '/dashboard/manage_delivery',
    icon: icon('ic_delivery_hours'),
    name: 'deliveryHours',
  },
  {
    title: 'notification',
    path: '/dashboard/notifications',
    icon: icon('ic_notification'),
    name: 'notifications',
  },
  {
    title: 'Customers',
    path: '/dashboard/customers',
    icon: icon('ic_customer'),
    name: 'customers',
  },
  {
    title: 'Shop Banners',
    path: '/dashboard/banners',
    icon : <ViewCarouselIcon/>,
    name: 'banners',
  },

  {
    title: 'Integrations',
    path: '/dashboard/settings',
    icon: icon('ic_settings'),
    name: 'integrations',
  },
  {
    title: 'Settings',
    path: '/dashboard/shopsettings',
    icon : icon('ic_settings'),
    name: 'shopSettings',
  },
];

export default navConfig;