import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import { Card, FormControl, Button, FormControlLabel, Grid, MenuItem, Radio, RadioGroup, Select, TextField, FormGroup, InputLabel, Input, Checkbox } from '@mui/material';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { useLocation, useNavigate } from 'react-router-dom';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import CustomInput from '../components/common/CustomInput';
import * as notificationsActions from '../redux/actions/notifications';
import * as commonActions from '../redux/actions/common';
import { validateNotificationData } from '../utilities/validations/notification';

const initialState = {
  name: '',
  // description: '',
  platforms: '',
  title: '',
  body: '',
  isSchedule: false,
  scheduleTime: new Date().toISOString(),
  image:"",
  rules: [
    {
      entity_type: '',
      criteria: "",
      key: '',
      value: '',
      value_data_type: ""
    },
  ],
};

export default function AddNotificationPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [notificationData, setNotificationData] = useState(initialState);
  const [criterialData, setCriteriaData] = useState({})
  const [errors,setErrors] = useState({...notificationData});
  const [imageFile, setImageFile] = useState(null);
  const [imageUrl, setImageUrl] = useState('');
  const [imagePreview, setImagePreview] = useState('');
  const [selectedPlatforms, setSelectedPlatforms] = useState([]);

  const fileInputRef = useRef(null);

    const onImageChangeHandler = (e) => {
    const file = e.target.files[0];
    setImageFile(file);
    const url = URL.createObjectURL(file);
    setImageUrl(url);
    setImagePreview(url);
  };

  const onButtonClick = () => {
    fileInputRef.current.click();
  };


  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    let updatedData = {
      ...notificationData,
      [name]: value,
    };

    if (name === 'isSchedule') {
      updatedData.isSchedule = value === 'later';

      if (value === 'now') {
        updatedData = {
          ...updatedData,
          scheduleTime: new Date().toISOString(),
        };
      }
    }

    if (name === "platforms") {
      const platformsArray = value.split(",");
      updatedData = {
        ...updatedData,
        platforms: platformsArray,
      };
    }

    setNotificationData(updatedData);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
  };

  const onDateChangeHandler = (e) => {
    const { name, value } = e.target;
    setNotificationData({ ...notificationData, [name]: value });
  };

  // const onPlatformClickHandler = (value) => {
  //   if (notificationData?.platforms === value) {
  //     setNotificationData({ ...notificationData, platforms: null });
  //   } else {
  //     setNotificationData({ ...notificationData, platforms: value });
  //   }
  // };

  const onPlatformClickHandler = (value) => {
    if (selectedPlatforms.includes(value)) {
      setSelectedPlatforms((prevSelectedPlatforms) =>
        prevSelectedPlatforms.filter((platform) => platform !== value)
      );
    } else {
      setSelectedPlatforms((prevSelectedPlatforms) => [...prevSelectedPlatforms, value]);
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      platforms: '',
    }));
  };


  const onNotificationSubmitHandler = () => {
    const { isValid, errors } = validateNotificationData(notificationData);

    if (isValid) {
      let submitData = { ...notificationData };

      if (selectedPlatforms.length === 0) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          platforms: "Please select at least one platform.",
        }));
        return;
      }
      const platformsString = selectedPlatforms.join(",");

      if (notificationData.isSchedule === 'now') {

        submitData = {
          ...submitData,
          scheduleTime: new Date().toISOString(),
        };
      }

      submitData = {
        ...submitData,
        platforms: platformsString,
        image: imageUrl,
      };

      dispatch(notificationsActions.createNotifications(submitData))
        .then((res) => {
          setNotificationData(initialState);
          setImageFile(null);
          dispatch(commonActions.closeCustomModalDialog());
          dispatch(notificationsActions.getAllNotifications());
           setTimeout(()=>{
            navigate("/dashboard/notifications");
          },[3000])
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setErrors({...errors});
      console.log("errors",errors);

    }
  };

  const getNotificationCriteria = async () => {
    const { data, status } = await dispatch(notificationsActions.getAllNotificationsCriteria())
    if (status) {
      setCriteriaData(data)

    }
  }

  const onRuleChangeHandler = (e, notificationIdx, key) => {
    const notificationDataArr = [...notificationData.rules]
    let selectedRule = notificationDataArr?.find(((rule, idx) => idx === notificationIdx))
    if (!isEmpty(selectedRule)) {
      const selectedRuleIdx = notificationDataArr.indexOf(selectedRule)
      selectedRule = {
        ...selectedRule,
        [key]: e.target.value
      }
      if (key === 'key') {
        const keyDataType = criterialData[selectedRule.entity_type][e.target.value]
        selectedRule = {
          ...selectedRule,
          value_data_type: keyDataType
        }
      }

      notificationDataArr[selectedRuleIdx] = selectedRule
      setNotificationData({ ...notificationData, rules: notificationDataArr })

      setErrors((prevErrors) => ({
        ...prevErrors,
        rules: prevErrors.rules.map((rule, idx) => {
          if (idx === notificationIdx) {
            return {
              ...rule,
              [key]: '',
            };
          }
          return rule;
        }),
      }));

    }
  }

  const addRuleHandler = () => {
    const newRules = [...notificationData.rules]
    newRules.push({
      entity_type: '',
      criteria: "",
      key: '',
      value: '',
      value_data_type: ""
    })
    setNotificationData({ ...notificationData, rules: newRules })
  }

  const removeRuleHanlder = (e, notificationIdx) => {
    const rules = [...notificationData.rules]
    const newRules = rules.filter((item, _idx) => notificationIdx !== _idx);
    setNotificationData({ ...notificationData, rules: newRules })
  }


  useEffect(() => {
    getNotificationCriteria()
  }, [])



  return (
    <>
       <Grid item xs={12}  className="d-flex">
       <div onClick={() => {
            navigate({
                pathname: "/dashboard/notifications",
                search: location.search
            })
        }} className="cur-pointer back-btn d-flex align-center mb-1">
            <KeyboardBackspaceIcon />
            <h2 className='m-0'> Back to Notification</h2>
        </div>
        <Grid item xs={12} sm={6} md={4} xl={4} className="page-heading">
            <h2 className='m-0'>Notification Details</h2>
        </Grid>
      </Grid>

    <Card className='p-3'>
      <Grid container spacing={2}>
        {/* <Grid item xs={12} lg={12} md={12}>
                <input type="file"  className="w-100" height={40}  onChange={onFileChangeHandler} />
                    </Grid> */}
        <Grid item xs={12} lg={6} md={6} className="add-category-input">
          <CustomInput
            name="name"
            value={notificationData?.name}
            onChange={onChangeHandler}
            label="Name"
            className="w-100"

          />
           {errors?.name && <p className="error">{errors?.name}</p>}
        </Grid>
        <Grid item xs={12} lg={6} md={6} className=" add-category-input">
          <CustomInput
            name="title"
            value={notificationData?.title}
            onChange={onChangeHandler}
            label="Title"
            className="w-100"
          />
           {errors?.title && <p className="error">{errors?.title}</p>}
        </Grid>
        <Grid item xs={12} className="pt-2 add-category-input">
          <TextField
            className="blaze-zipcode-textarea"
            name="body"
            value={notificationData?.body}
            label="Notification Description"
            placeholder="Notification Description"
            multiline
            fullWidth
            variant="outlined"
            onChange={onChangeHandler}
          />
          {errors?.body && <p className="error">{errors?.body}</p>}
        </Grid>


        <Grid item xs={12} className="pt-2">
          <h3 className='m-0'>Schedule </h3>
          <FormGroup row className="d-block theme-color">
            <FormControl>
                <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label"  className='themecolor'  name="isSchedule" value={notificationData.isSchedule}  onChange={onChangeHandler}>
                  <FormControlLabel value="now" control={<Radio  checked={!notificationData?.isSchedule}  className='themecolor' />} label="Now" />
                  <FormControlLabel value="later" control={<Radio  checked={notificationData?.isSchedule}  className='themecolor' />} label="Later" />
                </RadioGroup>
                  {errors?.isSchedule && <p className="error">{errors?.isSchedule}</p>}
            </FormControl>
          </FormGroup>
       </Grid>
        {notificationData.isSchedule && (
          <Grid item xs={12} className="pt-2">
            <h3 className='m-0'>Scheduled Date and Time </h3>
            <CustomInput
              type="datetime-local"
              name="scheduleTime"
              value={notificationData?.scheduleTime}
              onChange={onDateChangeHandler}
              inputProps={{ min: new Date().toISOString().split('.')[0] }}
            />
          </Grid>
        )}
        <Grid item xs={12} className="pt-2">
          <h3 className='m-0'>Platforms </h3>
          <FormGroup row className=" theme-color d-flex theme-list">
              <FormControl>
              <FormControlLabel
            control={
              <Checkbox
                checked={selectedPlatforms.includes("web")}
                onChange={() => onPlatformClickHandler("web")}
                className="themecolor"
              />
            }
            label="Web"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={selectedPlatforms.includes("android")}
                onChange={() => onPlatformClickHandler("android")}
                className="themecolor"
              />
            }
            label="Android"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={selectedPlatforms.includes("iOS")}
                onChange={() => onPlatformClickHandler("iOS")}
                className="themecolor"
              />
            }
            label="iOS"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={selectedPlatforms.includes("all")}
                onChange={() => onPlatformClickHandler("all")}
                className="themecolor"
              />
            }
            label="All"
          />
              </FormControl>
                {errors.platforms && <p className="error">{errors.platforms}</p>}
          </FormGroup>
        </Grid>

      </Grid>

      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12}>
          <h3 className='m-0'>Rule </h3>
        </Grid>
        {
          !isEmpty(criterialData) && notificationData?.rules?.map(((notification, notificationIdx) => {
            console.log('yes:', criterialData,  notificationData?.rules)

            return <>
              <Grid item md={12} className='d-flex'>
                <Grid container spacing={2}>
                  <Grid item xs={3} className="pt-2">
                    <FormControl fullWidth>
                    <InputLabel id="entity-label">Entity Type</InputLabel>
                    <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={notification?.entity_type}
                  fullWidth
                  onChange={(e) => onRuleChangeHandler(e, notificationIdx, 'entity_type')}
                  name="entity"
                  variant="outlined"
                  displayEmpty
                  label="Entity Type"
                  // renderValue={(value) => {
                  //   if (!value) {
                  //     return <InputLabel id="entity-label" shrink>Entity Type</InputLabel>;
                  //   }
                  //   return value;
                  // }}
                   >
                   {criterialData?.entity_type?.length > 0 &&
                      criterialData?.entity_type?.map((entity) => {
                        return (
                          <MenuItem
                            key={entity}
                            value={entity}
                            sx={{ textTransform: "capitalize" }}
                          >
                            {entity}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    </FormControl>
                    {errors?.rules?.[0]?.entity_type && (
                      <p className="error">{errors?.rules?.[0]?.entity_type}</p>
                    )}

                </Grid>

                  <Grid item md={3} className="pt-2">
                  <FormControl fullWidth>
                    <InputLabel id="entity-label">Key</InputLabel>
                  <Select
                   labelId="demo-simple-select-helper-label"
                   id="demo-simple-select-helper"
                  disabled={!!!notification?.entity_type}
                  value={notification?.key} fullWidth
                  onChange={(e) => onRuleChangeHandler(e, notificationIdx, 'key')}
                  name="Key"
                  displayEmpty
                  variant="outlined"
                  label="Key"
                  // renderValue={(value) => {
                  //   if (!value) {
                  //     return <InputLabel id="entity-label" shrink>Key</InputLabel>;
                  //   }
                  //   return value;
                  // }}
                   >
                      {
                        criterialData[notification?.entity_type] && Object.keys(criterialData[notification?.entity_type]).length > 0 && Object.keys(criterialData[notification?.entity_type])?.map((key => {
                          return <MenuItem value={key} sx={{ textTransform: 'capitalize' }}>{key}</MenuItem>
                        }))
                      }
                    </Select>
                    </FormControl>
                    {errors?.rules?.[0]?.key && <p className="error">{errors?.rules?.[0]?.key}</p>}
                  </Grid>
                  <Grid item md={3} className="pt-2">
                  <FormControl fullWidth>
                    <InputLabel id="entity-label">Criteria</InputLabel>
                    <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    disabled={!!!notification?.key}
                    value={notification.criteria}
                    fullWidth
                    onChange={(e) => onRuleChangeHandler(e, notificationIdx, 'criteria')}
                    name="Criteria"
                  displayEmpty
                  variant="outlined"
                  label="Criteria"
                  >
                      {
                        criterialData[notification?.entity_type] && criterialData[notification?.entity_type][notification.key] && criterialData?.criteria?.[criterialData[notification?.entity_type][notification.key]]?.map((criteria => {
                          const [key, value] = Object.entries(criteria)[0]
                          return <MenuItem value={key} sx={{ textTransform: 'capitalize' }}>{value}</MenuItem>
                        }))
                      }
                    </Select>
                    </FormControl>
                    {errors?.rules?.[0]?.criteria && <p className="error">{errors?.rules?.[0]?.criteria}</p>}
                  </Grid>
                  <Grid item md={3} className="pt-2 disable-input" >
                    <TextField
                    placeholder='Value'
                     InputLabelProps={{
                      style: { fontSize: 2 }
                    }}
                    disabled={!!!notification?.criteria} fullWidth type={criterialData?.[notification?.entity_type]?.[notification.key] === 'date' ? 'date' : 'text'} value={notification.value} onChange={(e) => onRuleChangeHandler(e, notificationIdx, 'value')}
                    />
          {/* {
            notificationIdx === (notificationData?.rules?.length - 1) &&
            <div className="add-more-fields" onClick={addRuleHandler}>Add new rule</div>
          } */}
          {notificationIdx !== 0 &&
            <div className="remove-icon color-red cur-pointer">
              <RemoveCircleIcon onClick={(e) => removeRuleHanlder(e, notificationIdx)} />
            </div>
          }
                    {errors?.rules?.[0]?.value && <p className="error">{errors?.rules?.[0]?.value}</p>}
                  </Grid>

                </Grid>

              </Grid>

            </>
          }))
        }
      </Grid>
      <Grid item xs={3} className="pt-2">
      <Grid item xs={2} className="d-flex jc-left add-button">
          <Button variant="contained" color="primary" onClick={addRuleHandler}>
            Add new rule
          </Button>
        </Grid>
        </Grid>
      <Grid item xs={3} className="pt-2">
        <Grid item xs={2} className="d-flex jc-left add-button">
          <Button variant="contained" color="primary" onClick={onButtonClick}>
            Upload Image
          </Button>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={onImageChangeHandler}
          />
        </Grid>
          {imagePreview && (
          <Grid item xs={2} className="d-flex jc-left pt-2">
            <img src={imagePreview} alt="Preview" style={{ maxWidth: '50%', maxHeight: '165px' }} />
          </Grid>
        )}
      </Grid>

      <Grid container spacing={4} sx={{ mt: 3 }}>
        <Grid item xs={12} className="d-flex jc-ctr add-button">
          <Button onClick={onNotificationSubmitHandler} variant="contained" color="primary" >Submit</Button>
        </Grid>
      </Grid>
    </Card>
    </>
  );
}