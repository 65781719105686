import React, { useEffect, useState } from 'react';
import { Box, Typography, Card, CardContent, CardHeader, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { Button } from '@mui/material';
import * as orderActions from '../redux/actions/orders';
import TableLoader from '../components/common/TableLoader';

export default function OrderDetails(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { id = "" } = useParams();

  const { isLoading, singleOrder } = useSelector((state) => state.orders);


  console.log("paymentoption", singleOrder)
  console.log("object", singleOrder?.values?.cartData)
  console.log("object1", singleOrder?.values)
  console.log("user", singleOrder?.values?.cartData?.deliveryAddress)

const timestamp = singleOrder?.values?.cartData?.orderPlacedTime;
const date = new Date(timestamp);

const options = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
  timeZoneName: 'short',
};
const formattedDateTime = date.toLocaleString('en-US', options)

  useEffect(() => {
    dispatch(orderActions.getOrders(id))
  }, [id])

  if (!singleOrder || singleOrder.length === 0) {
    return <TableLoader />;
  }

  const handleGoBack = () => {
    window.history.back();
};


  return (
    <div>
        <Grid container spacing={3}>
      <Grid item xs={12} className="d-flex">
        <div 
        onClick={handleGoBack}
        // onClick={() => {
        //   navigate({
        //     pathname: "/dashboard/order",
        //     search: location.search
        //   })
        // }} 
        className="cur-pointer d-flex align-center mb-1 back-btn">
          <KeyboardBackspaceIcon />
          <h2 className='m-0'>Back to Orders</h2>
        </div>
        <Grid item xs={12} sm={6} md={4} xl={4} className="page-heading">
        <h2 className='m-0'>Order Details</h2>
      </Grid>
      </Grid>
      </Grid>
      <Grid container spacing={6} className="order-details-page-ui">
      <Grid item md={4}>
      <Card className='p-2'>
      <Typography variant="h6"><strong> USER DETAILS</strong></Typography>
      <Typography variant="body1"><strong>Name:</strong> {singleOrder?.values?.userData?.firstName} {singleOrder?.values?.userData?.lastName}</Typography>
      <Typography variant="body1"><strong>Email:</strong> {singleOrder?.values?.userData?.email}</Typography>
      <Typography variant="body1"><strong>Phone:</strong> {singleOrder?.values?.userData?.Phone}</Typography>
      <Typography variant="body1"><strong>User Id:</strong><a href= {`/dashboard/customers/viewCustomer/${singleOrder?.values?.cuid}`}>{singleOrder?.values?.cuid}</a></Typography>
      <Typography variant="body1"><strong>Address:</strong> {singleOrder?.values?.cartData?.deliveryAddress?.address}</Typography>
      <Typography variant="body1"><strong>Consumer Type:</strong> {singleOrder?.values?.cartData?.cart?.consumerType}</Typography>
      </Card>
      </Grid>
      <Grid item md={4}>
        <Card className='p-2'>
        <Typography variant="h6"> <strong>ORDER DETAILS</strong></Typography>
      <Typography variant="body1"><strong>Order ID:</strong> {singleOrder?.values?.orderId} </Typography>
      <Typography variant="body1"><strong>Mode:</strong> {singleOrder?.values?.cartData?.pickupType}</Typography>
      <Typography variant="body1"><strong>Order placed on:</strong>{formattedDateTime}</Typography>
      <Typography variant="body1"><strong>Sub total:</strong> ${singleOrder?.values?.cartData?.cart.subTotal}</Typography>
      <Typography variant="body1"><strong>Discount:</strong> ${singleOrder?.values?.cartData?.cart?.discount}</Typography>
      <Typography variant="body1"><strong>Sub total after discount:</strong> ${singleOrder?.values?.cartData?.cart?.subTotalDiscount}</Typography>
      <Typography variant="body1"> <u><strong>Taxes</strong></u></Typography>
      <Typography variant="body1"><strong>City tax:</strong> ${singleOrder?.values?.cartData?.cart?.taxResult?.totalCityTax}</Typography>
      <Typography variant="body1"><strong>Excise tax:</strong> ${singleOrder?.values?.cartData?.cart?.taxResult?.totalExciseTax}</Typography>
      <Typography variant="body1"><strong>State tax:</strong> ${singleOrder?.values?.cartData?.cart?.taxResult?.totalStateTax}</Typography>
      <Typography variant="body1"><strong>County tax:</strong> ${singleOrder?.values?.cartData?.cart?.taxResult?.totalCountyTax}</Typography>
      <Typography variant="body1"><strong>Total calculated tax:</strong> ${singleOrder?.values?.cartData?.cart?.totalCalcTax}</Typography>
      <Typography variant="body1"><strong>TOTAL:</strong> ${singleOrder?.values?.cartData?.cart?.total}</Typography>
      </Card>
      </Grid>
      
      
      <Grid item md={4}>
      <Card className='p-2'>
      <Typography variant="h6"><strong> REWARDS USED</strong></Typography>
      {singleOrder?.values?.promotions?.map((item, index) => (
        <Typography>
          <li key={index}>
          {item.promotionName}
          </li>
        </Typography>
      ))}
      <br/>
            <Typography variant="h6"><strong> PAYMENT</strong></Typography>
      <Typography variant="body1"><strong>Payment Option:</strong> {singleOrder?.values?.cartData?.cart?.paymentOption}</Typography>
      <br/>
      <Typography variant="body1"><u><strong>SOURCE:</strong></u>{singleOrder?.values?.cartData?.source}</Typography>
      </Card>
      </Grid>
      
      </Grid>


<div className='cus-table mt-3'>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
            <TableCell>S.No</TableCell>
              <TableCell>Product ID</TableCell>
              <TableCell>Product Name</TableCell>
              <TableCell>SKU</TableCell>
              <TableCell>Unit Price</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Total Price</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {singleOrder?.values?.cartData?.cart?.items?.map((order,index) => (
              <TableRow key={order?.id}>
                <TableCell>{index + 1}</TableCell>
                <TableCell><a href={`/dashboard/manage_products/productDetails/${order?.productId}`}>{order?.productId}</a></TableCell>
                <TableCell>{order?.productName}</TableCell>
                <TableCell>{order?.productSku}</TableCell>
                <TableCell>${order.cost}</TableCell>
                <TableCell>{order?.quantity}</TableCell>
                <TableCell>${order?.finalPrice}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      </div>

    </div>
  );
}
