import { apiDelete, apiGet, apiPost, apiPut } from "../../utils";

export function getAllDeliveryHoursAPI(data) {
    const { limit = 10, skip = 0, searchTerm = '', sortBy = 'sale', categoryId = '', productTags = '', brandId = '', active = true ,cartType="" } = data || {}
    const newData = { limit, skip, searchTerm, sortBy, categoryId, productTags, brandId, active,cartType }
    return apiGet(`api/v1/admin/deliveryHours`,newData);
}


export function createNewDeliveryHourAPI(data) {
    return apiPost('api/v1/admin/deliveryHours', data);
}

export function updateDeliverHoursAPI(id, data) {
    return apiPut(`api/v1/admin/deliveryHours/${id}`, data);
}

export function deleteDeliverHoursAPI(id) {
    return apiDelete(`api/v1/admin/deliveryHours/${id}`)
}