import React from 'react'
import EditIconComp from '@mui/icons-material/Edit';

export default function EditIcon({ onClick }) {
    return (
        <div className="cur-pointer edit-icon">
            <EditIconComp onClick={onClick} />
        </div>
    )
}
