import { AccessTokenId, AccessTokenName, APP_NAME, isSuperAdmin } from '../../constants';
import {
    GET_LAST_SYNC_DATE,
    GET_LAST_SYNC_DATE_FAILED,
    GET_LAST_SYNC_DATE_SUCCESSFULL,
    LOGIN_FAILED,
    LOGIN_SUCCESSFULL,
    LOGOUT_USER,
    REQUEST_LOGIN,
    SET_USER,
} from '../actions/auth';
import { defaultColor, getUserData, isLoggedIn, removeData, setData } from '../../utils';

const initialState = {
    user: getUserData() || {},
    isLoading: false,
    isLoggedIn: !isLoggedIn(),
    userPhone: "",
    lastSyncDate: ""
}

export default function auth(state = { ...initialState }, action = {}) {
    switch (action.type) {

        case SET_USER:
            return { ...state, user: action.user, isLoggedIn: true }


        case REQUEST_LOGIN:
            return { ...state, isLoading: true }

        case LOGIN_SUCCESSFULL: {
            const  { projectData: { app, themeColors , image  } } = action?.user?.data;
            let logoImage = image;
            let appColors = themeColors;
            if (!appColors) {
                appColors = defaultColor
            } 
            if(!image){
                logoImage ='../assets/images/ankar.svg' 
            }

            setData('themeColors', appColors)
            setData('logoImage',logoImage)
            const superAdmin = action?.user?.isSuperAdmin || false
            setData(AccessTokenName, action?.user?.data)
            setData(APP_NAME, app)
            setData(AccessTokenId, action?.user?.access_token)
            setData(isSuperAdmin, superAdmin)
            return { ...state, user: action.user, isLoggedIn: true, isLoading: false }
        }


        case LOGIN_FAILED:
            return { ...state, isLoading: false, isLoggedIn: false }

            case GET_LAST_SYNC_DATE:
            return { ...state }

        case GET_LAST_SYNC_DATE_SUCCESSFULL:
            return { ...state, lastSyncDate: (action?.data && action?.data?.lastSync) || "" }

        case GET_LAST_SYNC_DATE_FAILED:
            return { ...state }




        case LOGOUT_USER:
            removeData(AccessTokenId)
            removeData(AccessTokenName)
            removeData(isSuperAdmin)
            removeData("adminLastSync")
            removeData('accessToken')
            return { ...state, isLoggedIn: false }

        default:
            return state
    }
}