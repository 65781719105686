import { GET_ORDERS_FAILED,GET_ORDERS_REQUEST,GET_ORDERS_SUCCESS,GET_ORDERS_DETAILS_FAILED,GET_ORDERS_DETAILS_SUCCESS,GET_ORDERS_DETAILS_REQUEST } from "../actions/orders"


const initialState = {
    orders: {},
    isLoading: false,
    singleOrder:{}
}

export default function (state = { ...initialState }, action = {}) {
    switch (action.type) {
        case GET_ORDERS_REQUEST: {
            return { ...state, isLoading: true }
        }

        case GET_ORDERS_SUCCESS: {
            return { ...state, isLoading: false, orders: action.payload }
        }

        case GET_ORDERS_FAILED: {
            return { ...state, isLoading: false, orders: action.payload }
        }

        case GET_ORDERS_DETAILS_REQUEST: {
            return { ...state, isLoading: true }
        }

        case GET_ORDERS_DETAILS_SUCCESS: {
            return { ...state, isLoading: false, singleOrder: action.payload }
        }

        case GET_ORDERS_DETAILS_FAILED: {
            return { ...state, isLoading: false, singleOrder: action.payload }
        }

        default:
            return state
    }
}