import React, { useEffect, useMemo, useState } from 'react'
import ReactApexChart from 'react-apexcharts';
import { Card, CardHeader } from '@mui/material';
import { useChart } from '../../../components/chart';

export default function AppCustomerOnBoard({ dashboardStore }) {
 
 
  const data = useMemo(() => {
    if (dashboardStore?.dashboardData?.onBoardedDetails?.monthsBreakup) {
      return dashboardStore.dashboardData.onBoardedDetails.monthsBreakup;
    }
    return [];
  }, [dashboardStore?.dashboardData?.onBoardedDetails?.monthsBreakup])
  
  const [fields, setFields] = useState({

    series: [{
      name: 'Customer',
      data: Object.values(data),
    }],
    
    options: {
      colors:"var(--graph-secondary)",
      chart: {
        height: 364,
        type: 'bar',
        toolbar: {
          show: false
        },
      },
      plotOptions: {
        bar: {
          
          borderRadius: 5,
          dataLabels: {
            position: 'top',
          },
        }
      },
      dataLabels: {
        enabled: true,  
        formatter: (val) => {
          return `${val}` ; 
        },
        offsetY: -20,
        style: {
          fontSize: '15px',
          colors: ["#304758"]
        }
      },
      xaxis: {
        categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun" , "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        position: 'buttom',
        axisBorder: {
          show: true
        },
        axisTicks: {
          show: true
        },  
        crosshairs: {
          fill: {
            type: 'gradient',
            gradient: {
              colorFrom: '#D8E3F0',
              colorTo: '#BED1E6',
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            }
          }
        },
        
      },
      yaxis: {
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: true,
        },
        labels: {
          show: true,
          formatter: (val) => {
            return `${val}` ;
          }
        }
      },
      // title: {
      //   text:'Customer Onboarded',
      //   align: 'top',
      //   offsetY: 0,
      //   style: {
      //     color: '#444'
      //   }
      // },
     
    },
  });

  useEffect(() => {
    setFields({...fields, series: [{
      name: 'Customers',
      data: Object.values(data),
    }]})
  }, [data])

  const dataSeries = { name: 'customers', data: fields.series[0]?.data };
 

  return (
    <Card>
      <div id="chart">
      <h3 className='pl-2'>Customer Onboarded</h3>
        <ReactApexChart options={fields.options} series={[dataSeries]} type="bar" height={414} />
      </div>
    </Card>
  );
}
