import { CLOSE_MODAL_DIALOG, OPEN_MODAL_DIALOG,
    //  SET_SHOP_NAME
     } from "../actions/common"


const initialState = {
    modalData: {},
    isLoading: false,
    modal: "",
    // shopNameOptionList: [{
    //     id: "031e99f07f2446a580b994d42a3710f9",
    //     name: "Calgary",
    // }, {
    //     id: "b7d10fc48a3e4fb8a15d1f5d3d984db9",
    //     name: "Vancouver",
    // }],
    // shopNameOptionId:{
    //     id: "031e99f07f2446a580b994d42a3710f9",
    //     name: "Calgary",
    // }

}


export default function (state = { ...initialState }, action = {}) {
    switch (action.type) {
        case OPEN_MODAL_DIALOG: {
            return { ...state, modal: action.modal, modalData: action.data }
        }

        case CLOSE_MODAL_DIALOG: {
            return { ...state, modal: "", modalData: action.data }
        }
        // case SET_SHOP_NAME: {
        //     const data=state.shopNameOptionList.find((item)=>item.id === action.data)
        //     return { ...state, shopNameOptionId:data }
        // }

        default:
            return state
    }
}
