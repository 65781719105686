import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Grid, TextField } from "@material-ui/core";
import ContentLoader from "react-content-loader";
import { toast } from 'react-toastify';
import * as commonActions from "../../redux/actions/common";
import * as integrationActions from "../../redux/actions/integrations";
import CustomInput from "../common/CustomInput";

export default function AddIntegrationModal() {
  const dispatch = useDispatch();
  const [fields, setFields] = useState([]);
  const [metadata, setMetadata] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { modalData: { integration: integrationObj } } = useSelector(state => state.common);
  const { metadata: initialMetadata } = integrationObj || {};

  console.log("modaldata", metadata);
 
  useEffect(() => {
    
    setMetadata(
      initialMetadata?.reduce((result, metadataObj) => {
        const keys = Object.keys(metadataObj);
       if (keys.length > 0) {
  for (let i = 0; i < keys.length; i+=1) {
    const key = keys[i];
    result[key] = metadataObj[key];
  }
}

        return result;
      }, {}) || {}
    );
  }, [dispatch, initialMetadata]);

  const onFieldChange = (key, value) => {
    setMetadata((prevMetadata) => ({
      ...prevMetadata,
      [key]: value,
    }));
  };
  const onSubmitHandler = (event) => {
    event.preventDefault();
    const updatedInitialMetadata = initialMetadata.map((metadataObj) => {
      const updatedMetadataObj = {};
      Object.keys(metadataObj).forEach((key) => {
        updatedMetadataObj[key] = metadata[key];
      });
      return updatedMetadataObj;
    });
    const updatedIntegration = {
      ...integrationObj,
      metadata: updatedInitialMetadata,
    };
    dispatch(integrationActions.updateIntegration(updatedIntegration))
      .then(() => {
        dispatch(commonActions.closeCustomModalDialog({}, "closeAddIntegrationModal"));
        toast.success('Integration updated');
        setTimeout(() => {
          window.location.reload();
      }, 1000);
  }).catch(() => { })
      .catch(error => {
        console.log('error', error);
      });
  };


 
  return (
    <Grid container component="main" className="add-integration-modal-container mt-60">
      <Grid item xs={12} className="header-modal">
  {integrationObj && integrationObj.name && <h3>Edit Integration - {integrationObj.name.charAt(0).toUpperCase() + integrationObj.name.slice(1)}</h3>} 
</Grid>



      <Grid item xs={12} className="">
        {isLoading ? (
          <ContentLoader />
        ) : (
          <>
   {initialMetadata && initialMetadata.length > 0 ? (
              initialMetadata?.map((metadataObj, index) => {
                const keys = Object.keys(metadataObj);
                return (
                  <div key={index}>
                    {keys.map((key, innerIndex) => (
                      <React.Fragment key={innerIndex}>
                        {/* {key && <h5 className="mb-0">{key}</h5>} */}
                        <CustomInput
                          label={key}
                          className="w-100"
                          margin="normal"
                          value={metadata[key] || ""} 
                          name={key}
                          onChange={(event) => onFieldChange(key, event.target.value)}
                        />

                      </React.Fragment>
                    ))}
                  </div>
                );
              })
            ) : (
              <p className="d-flex jc-ctr add-button">No keys available.</p>
            )}
          </>
        )}

      </Grid>
      {initialMetadata && initialMetadata.length > 0 && (
        <Grid item xs={12} className="d-flex jc-ctr add-button">
          <form onSubmit={onSubmitHandler}>
            <Button type="submit" variant="contained" color="primary">
              Submit
            </Button>
          </form>
        </Grid>
      )}
    </Grid>
  );
}
