import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import CropOriginalIcon from '@material-ui/icons/CropOriginal';
import { Box } from "@material-ui/core";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import MenuIcon from '@material-ui/icons/Menu';
import EditIcon from '../../../components/common/Icons/EditIcon'
import DeleteIcon from '../../../components/common/Icons/DeleteIcon'
import { APP_NAME, BASE_URL } from "../../../constants";
import CustomInput from '../../../components/common/CustomInput';
import CustomMultiSelect from '../../../components/common/CustomMultiSelect';
import { getData } from "../../../utils";


const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({

    background: isDragging ? "" : "",
    border: isDragging ? "none" : "",


    ...draggableStyle
});


export default function DraggableList(props) {

    const {
        category = [],
        handleChangeSubCategory,
        handleDeleteCategory,
        values,
        onUpdateCategoryOrder,
        handleEditCategory
    } = props;

    const onDragEnd = (result) => {

        if (!result.destination) {
            return;
        }

        const items = reorder(
            props.category,
            result.source.index,
            result.destination.index
        );
        onUpdateCategoryOrder(items)

    }

    const appName = JSON.parse(getData(APP_NAME))

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                    <ul
                        className="category-ul-list"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                    >
                        {category && category.map((item, index) => {
                            const imgUrl = (item && item.image) || ''
                            return <Draggable key={item._id} draggableId={item._id} index={index}>
                                {(provided, snapshot) => (

                                    <li className="custon-list"
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style
                                        )}
                                    >
                                        <MenuIcon className="mr-2" />
                                        <div className="category-img-sec">
                                            {
                                                imgUrl ?
                                                    (
                                                        imgUrl.startsWith("http") ?
                                                            <img crossOrigin="anonymous" src={imgUrl} className="category-img" alt="" /> :
                                                            <img crossOrigin="anonymous" src={`${BASE_URL}${imgUrl}`} className="category-img" alt="" />
                                                    ) :
                                                    <CropOriginalIcon />
                                            }
                                        </div>

                                        <div className="left-section">
                                            <CustomInput
                                                name="categoryName"
                                                fullWidth
                                                value={item?.categoryName || ""}

                                                onChange={() => { }}
                                                label="Category Name"
                                            />
                                        </div>
                                        {
                                            appName === 'cannamobile' || appName === "multishop" ?
                                                <CustomInput
                                                    name="subCategoryName"
                                                    id={item._id}
                                                    fullWidth
                                                    value={item?.blazeCategories?.length ? item?.blazeCategories.map((blaze => blaze?.name))?.join(',') : ''}
                                                    onChange={(e) => handleChangeSubCategory(e, null, index)}
                                                    label="Sub Category"
                                                    className="w-50"
                                                />
                                                
                                                :
                                                <CustomMultiSelect
                                                    label="Sub Category"
                                                    name={"name"}
                                                    fullWidth
                                                    options={values || []}
                                                    handleChange={(e, val) => { handleChangeSubCategory(e, val, index) }}
                                                    value={item?.blazeCategories || []}
                                                    className={"multiwidth"}
                                                />

                                        }
                                        <Box ml={2} display='flex' alignItems={'center'}>
                                            <EditIcon onClick={(e) => handleEditCategory(item)} />
                                            <DeleteIcon onClick={() => { handleDeleteCategory(item._id) }} />
                                        </Box>

                                    </li>
                                )}
                            </Draggable>
                        })}
                        {provided.placeholder}
                    </ul>
                )}
            </Droppable>
        </DragDropContext>
    );
}
