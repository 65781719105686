import { apiGet, apiPost, apiDelete, apiPut } from '../../utils';

export function addBannerAPI(data) {
    // if (id) {
    //     return apiPost(`api/v1/admin/banner/add/${id}`, data);
    // }
    return apiPost(`api/v1/admin/banner/add`, data);
}

export function getAllBannerAPI(data) {
    return apiGet(`api/v1/admin/banner`, data);
}
// Upload Image
export function uploadImageAPI(data) {
    return apiPost(`api/v1/admin/category/banner/image`, data)
}

export function deleteBannerImageAPI(data) {
    return apiPost(`api/v1/admin/category/banner/image/delete`,data);
}


export function getFreeGiftApi(data){
    return apiGet(`api/v1/admin/miscellaneous/gift`, data)
}

export function updateFreeGiftApi(data){
    return apiPut(`api/v1/admin/miscellaneous/gift`, data)
}