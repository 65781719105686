import { toast } from 'react-toastify';
import { getDashBoardDataAPI } from '../api/dashboard';

export const GET_DASHBOARD_DATA_REQUEST='GET_DASHBOARD_DATA_REQUEST';
export const GET_DASHBOARD_DATA_SUCCESS='GET_DASHBOARD_DATA_SUCCESS';
export const GET_DASHBOARD_DATA_FAILURE='GET_DASHBOARD_DATA_FAILURE';

export  function getDashBoardData(data,year) {
    return dispatch => {
        dispatch({
            type: 'GET_DASHBOARD_DATA',
        })
        return new Promise((resolve, reject) => {
            getDashBoardDataAPI(data,year)
            .then((res)=>{
                dispatch({
                    type: 'GET_DASHBOARD_DATA_SUCCESS',
                    payload:res?.data
                })
                return resolve(res)
            })
            .catch(err=>{
                dispatch({
                    type: 'GET_DASHBOARD_DATA_FAILURE',
                })
                toast.error(err)
                return reject(err)
            })
        })

    }
}