import React, { useEffect, useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Switch, TextField } from '@material-ui/core';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import * as cannasettingsActions from '../redux/actions/cannasettings';
import { SelectedShop } from '../constants';



export default function CannaSettings(){


    function createData(name, action, status) {
        return {name, action, status}
    }
    
    const rows = [
        createData('Free Gift','g','dd'),
        createData('Payment Option','g','dd'),
    ]

    const dispatch = useDispatch();
    const [isToggleOn, setIsToggleOn] = useState(false)
    const [isToggleNewOn, setIsToggleNewOn] = useState(false)
    const [isContentToggleOn, setIsContentToggleOn] = useState(false)
    const [isContentLessToggleOn, setIsContentLessToggleOn] = useState(false)
    const [contentCash, setContentCash] = useState("");
    const [contentCashless, setContentCashless] = useState("");

    useEffect(() => {
        const fetchData = async () => {
            try {
                const getFreeGiftResponse = await dispatch(cannasettingsActions.getFreeGift('freeGift'))
                const isActive = getFreeGiftResponse?.data?.data?.active
                const isStatus200 = getFreeGiftResponse?.status === 200;
                setIsToggleOn(isActive && isStatus200)

                const getFreeGiftNewResponse = await dispatch(cannasettingsActions.getFreeGiftNew('freeGiftNewMember'))
                const isActiveNew = getFreeGiftNewResponse?.data?.data?.active
                const isStatus200New = getFreeGiftResponse?.status === 200;
                setIsToggleNewOn(isActiveNew && isStatus200New)

                const getContentCashResponse = await dispatch(cannasettingsActions.getContentCash('contentCash'))
                const isContentCashActive = getContentCashResponse?.data?.data?.active
                const content = getContentCashResponse?.data?.data?.content || [];
                setContentCash(content)
                setIsContentToggleOn(isContentCashActive)

                const getContentCashlessResponse = await dispatch(cannasettingsActions.getContentCashless('contentCashless'))
                const isContentCashlessActive = getContentCashlessResponse?.data?.data?.active
                const contentless = getContentCashlessResponse?.data?.data?.content || [];
                setContentCashless(contentless)
                setIsContentLessToggleOn(isContentCashlessActive)

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    },[])

    const handleToggleChange = async () => {
        try {
            const isActive = !isToggleOn;
            setIsToggleOn(isActive);

            const dataToUpdate = {
                uniqueId: 'freeGift',
                data: {
                active: isActive,
                }
            }
            const updateResponse = await dispatch(cannasettingsActions.updateFreeGift(dataToUpdate));
            toast.success('Update Successful')
        }   catch(error) {
        console.error('Update failed', error);
        }
    }

    const handleToggleNewChange = async () => {
        try {
            const isActiveNew = !isToggleNewOn;
            setIsToggleNewOn(isActiveNew);

            const dataToUpdate = {
                uniqueId: 'freeGiftNewMember',
                data: {
                active: isActiveNew,
                }
            }
            const updateResponse = await dispatch(cannasettingsActions.updateFreeGiftNew(dataToUpdate));
            toast.success('Update Successful')
        }   catch(error) {
        console.error('Update failed', error);
        }
    }


    const handleContentToggleChange = () => {
        setIsContentToggleOn(!isContentToggleOn);
    }


    const handleContentCashUpdate = async () => {
        try {
            // if (!isContentToggleOn){
            //     return;
            // }
            const dataToUpdate = {
                uniqueId: 'contentCash',
                data: {
                    content: contentCash,
                    active: isContentToggleOn,}
            };
            console.log(dataToUpdate, dataToUpdate.data)
            const updateResponse = await dispatch(cannasettingsActions.updateContentCash(dataToUpdate))
            toast.success('Content Update Successful')
        }catch(error){
            console.error('Content Update Failed', error)
        }
    }

    const handleContentLessToggleChange = () => {
        setIsContentLessToggleOn(!isContentLessToggleOn);
    }

    const handleContentLessUpdate = async () => {
        try {
            // if (!isContentLessToggleOn){
            //     return;
            // }
            const dataToUpdate = {
                uniqueId: 'contentCashless',
                data: {
                    content: contentCashless,
                    active: isContentLessToggleOn ,}
                }
            const updateResponse = await dispatch(cannasettingsActions.updateContentCashless(dataToUpdate))
            toast.success('Content Update Successful')
            } catch(error) {
                console.error('Content update failed', error)
            }
        }
    

    return (<>
        <h2>Settings</h2>

    <TableContainer component={Paper}>
        <Table sx={{ minWidth: 450 }} aria-label="simple table">
        <TableHead>
            <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Action</TableCell>
                <TableCell>Status</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            <TableRow>
                <TableCell>Free Gift</TableCell>
                <TableCell>
                    <Switch
                    checked={isToggleOn}
                    onChange={handleToggleChange}/>
                    </TableCell>
                <TableCell>{isToggleOn ? 'Active': 'Inactive'}</TableCell>
            </TableRow>

            <TableRow>
                <TableCell>Free Gift (New User)</TableCell>
                <TableCell>
                    <Switch
                    checked={isToggleNewOn}
                    onChange={handleToggleNewChange}/>
                    </TableCell>
                <TableCell>{isToggleNewOn ? 'Active': 'Inactive'}</TableCell>
            </TableRow>       
             </TableBody>
        </Table>
    </TableContainer>

    <br/>
    <br/>
    <br/>

    <TableContainer component={Paper}>
        <Table sx={{ minWidth: 450 }} aria-label="simple table">
        <TableHead>
            <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Text</TableCell>
                <TableCell>Action</TableCell>
                <TableCell>Update content</TableCell>
                <TableCell>Status</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>

            <TableRow>
                <TableCell>Cash Payment Text</TableCell>
                <TableCell>
                <CKEditor
                    editor={ ClassicEditor }
                    data={contentCash}
                    onChange={ ( event,editor ) => {
                        const data = editor.getData();
                        setContentCash(data)
                        console.log( data);
                    } }
                />



                </TableCell>
                <TableCell><Switch
                checked={isContentToggleOn}
                onChange={handleContentToggleChange}/>
                </TableCell>
                <TableCell><button 
                    onClick={handleContentCashUpdate}
                    style={{
                    padding: '8px 12px',
                    fontSize: '14px',
                    backgroundColor: '#80C904',
                    color: 'white',
                    border: 'none',
                    borderRadius: '4px',
                    cursor: 'pointer',
                    }}>Update</button></TableCell>
                <TableCell>{isContentToggleOn ? 'Active' :'Inactive'}</TableCell>

            </TableRow>
            <TableRow>
                <TableCell>Interac E-Transfer Text</TableCell>
                <TableCell>
                <CKEditor
                    editor={ ClassicEditor }
                    data={contentCashless}
                    onChange={ ( event,editor ) => {
                        const data = editor.getData();
                        setContentCashless(data)
                        console.log( data);
                    } }
                />
                </TableCell>
                <TableCell><Switch
                checked={isContentLessToggleOn}
                onChange={handleContentLessToggleChange}
                /></TableCell>
                <TableCell><button
                    onClick={handleContentLessUpdate}
                    style={{
                    padding: '8px 12px',
                    fontSize: '14px',
                    backgroundColor: '#80C904',
                    color: 'white',
                    border: 'none',
                    borderRadius: '4px',
                    cursor: 'pointer',
                    }}>Update</button></TableCell>
                <TableCell>{isContentLessToggleOn ? 'Active' :'Inactive'}</TableCell>

            </TableRow>
        </TableBody>
        </Table>
    </TableContainer>
    </>)

}

