import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'

import auth from './auth';

import products from './products';
import common from './common';
import promotions from './promotions'
import notifications from './notification';
import dashboard from './dashboard';
import orders from './orders'
import deliveryHours from './deliveryHours'
import users from "./user"
import customers from "./customer"
import forgotPassword from './forgotPassword';
import integrations from './integrations';

const rootReducer = (history) => combineReducers({
    router: connectRouter(history),
    auth,
    products,
    common,
    promotions,
    orders,
    customers,
    deliveryHours,
    users,
    dashboard,
    notifications,
    forgotPassword,
    integrations,
   
});

export default rootReducer;