import { toast } from 'react-toastify';
import { getAllCustomersAPI,getCustomerOrdersAPI } from '../api/customer';

export const GET_CUSTOMER_DATA = 'GET_CUSTOMER_DATA';
export const GET_CUSTOMER_DATA_SUCCESS = 'GET_CUSTOMER_DATA_SUCCESS';
export const GET_CUSTOMER_DATA_FAILURE = 'GET_CUSTOMER_DATA_FAILURE';

export function getAllCustomers(data){
    return dispatch =>{
        dispatch({
            type: GET_CUSTOMER_DATA,
        })

        return new Promise((resolve, reject) =>{
            getAllCustomersAPI(data)
            .then(res =>{
                dispatch({
                    type: GET_CUSTOMER_DATA_SUCCESS,
                    payload: res?.data
                })
                return resolve(res)
            })
            .catch(err =>{
                dispatch({
                    type: GET_CUSTOMER_DATA_FAILURE,
                })
                toast.error(err)
                return reject(err)
            })

        })
    }
}

export const GET_CUSTOMER_ORDERS = 'GET_CUSTOMER_ORDERS'
export const GET_CUSTOMER_ORDERS_SUCCESS = 'GET_CUSTOMER_ORDERS_SUCCESS'
export const GET_CUSTOMER_ORDERS_FAILURE = 'GET_CUSTOMER_ORDERS_FAILURE'

export function getCustomersOrders(id, data) {
    return dispatch => {
        dispatch({
            type: GET_CUSTOMER_ORDERS,
        });

        return new Promise((resolve, reject) => {
            getCustomerOrdersAPI(id, data)
                .then(res => {
                    dispatch({
                        type: GET_CUSTOMER_ORDERS_SUCCESS,
                        payload: res?.data,
                    });
                    return resolve(res);
                })
                .catch(err => {
                    dispatch({
                        type: GET_CUSTOMER_ORDERS_FAILURE,
                    });
                    toast.error(err);
                    return reject(err);
                });
        });
    };
}
