import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux';
import ReactApexChart from 'react-apexcharts';
import { Card,CardContent } from '@mui/material';
import * as dashboardActions from '../../../redux/actions/dashboard';


const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export default function AppRevenue({dashboardStore, yearParam}) {
  const dispatch = useDispatch();
  const [month, setMonth] = useState('all');
  const [selectedYear, setSelectedYear] = useState(yearParam || '2022');

  const data = useMemo(()=>{
    if(dashboardStore?.dashboardData?.revenueData){
      return Object.values(dashboardStore?.dashboardData?.revenueData);
    }
    return []
  },[dashboardStore?.dashboardData?.revenueData])

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
    dispatch(dashboardActions.getDashBoardData());
  };

  const [fields, setFields] = useState({

    series: [{
      name: 'Amount',
      data: []
    }],

    options: {
      colors:"var(--graph-secondary)",
      chart: {
        height: 400,
        type: 'bar',
        toolbar: {
          show: false,
          tools:{
            download: false,
          }
        }

      },
      plotOptions: {
        bar: {
          
          borderRadius: 5,
          dataLabels: {
            position: 'top',
          },
        },

    
      },
      dataLabels: {
        enabled: true,  
            formatter: (val) => {
          return `$${val}` ;
        },
        offsetY: -20,
        style: {
          fontSize: '15px',
          colors: ["#212B36"]
        }
      },
      xaxis: {
        
        position: 'buttom',
        axisBorder: {
          show: true
        },
        axisTicks: {
          show: true
        },
        crosshairs: {
          fill: {
            type: 'gradient',
            gradient: {
              colorFrom: '#80C627',
              colorTo: '#80C627',
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            }
          }
        },
        toolbar: {
          show: false,

        }
      },
      yaxis: {
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: true,
        },
        labels: {
          show: true,
          formatter: (val) => {
            return `$${val}` ;
          }      
        }
      },
      // title: {
      //   text:'Revenue',
      //   align: 'left',
      //   margin: 10,
      //   offsetX: 0,
      //   offsetY: 0,
      //   floating: false,
      //   style: {
      //     color: '#000',
      //     fontWeight:  'bold',
      //   }
      // },
    },
  });

  useEffect(()=>{
    setFields({...fields,series: [{
      name: 'Amount',
      data
    }]})
  },[data])

  const filteredData = (month === 'all') ? data : [data[Number(month)]];
  const filteredCategories = (month === 'all') ? monthNames : [monthNames[Number(month)]];
  const filteredSeries = [{ name: 'Amount', data: filteredData }];

  useEffect(() => {
    setFields({
      ...fields,
      options: {
        ...fields.options,
        xaxis: {
          ...fields.options.xaxis,
          categories: filteredCategories
        }
      }
    })
  },[month])
  
  
  return (
    <>
     <Card className='p-3 pt-0'>
 
    <div className='d-flex jc-content-b algn-itm-ctr'>
      <h3 className=''>Revenue</h3>
      <div className='select-style'>
        <select value={month} onChange={(e) => setMonth(e.target.value)}>
          <option value="all">All Months</option>
          <option value="0">Jan</option>
          <option value="1">Feb</option>
          <option value="2">Mar</option>
          <option value="3">Apr</option>
          <option value="4">May</option>
          <option value="5">Jun</option>
          <option value="6">Jul</option>
          <option value="7">Aug</option>
          <option value="8">Sep</option>
          <option value="9">Oct</option>
          <option value="10">Nov</option>
          <option value="11">Dec</option>
        </select>
        {/* <select className='ml-1' value={selectedYear} onChange={handleYearChange}>
          <option value="2021">2021</option>
          <option value="2022">2022</option>
          <option value="2023">2023</option>
        </select> */}
      </div>
    </div>
    <div id="chart">
    
      <ReactApexChart options={fields.options} series={filteredSeries} type="bar" height={402} />
    </div>

</Card>

    
      </>
  )
}