import React, { useCallback, useEffect, useState, useRef } from 'react';
import queryString from 'query-string'
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet-async';
import {
  InputAdornment,
  Tooltip,
  Zoom,
  CircularProgress,
  Grid,
  Button,}
  from "@material-ui/core";

import { useDispatch,useSelector } from 'react-redux';
import Select from 'react-select';

import _ from 'lodash';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import { useLocation, useNavigate } from "react-router-dom"
import { setData,getData, colorStyles, filterOptions,isSuperAdminAccess } from '../../../utils';
import * as productsActions from "../../../redux/actions/products";
import * as authActions from "../../../redux/actions/auth";
import * as commonActions from "../../../redux/actions/common";
import CustomInput from '../../../components/common/CustomInput';
import AllProducts from './AllProducts';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
}));

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

export default function ManageProducts(props) {

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(0);

  const [search, setSearch] = useState("")
  const [filters, setFilters] = useState({
    limit: 10,
    skip: 0,
    page: 0,
  })
  const [isActiveTab, setIsActiveTab] = useState(true)
  const { lastSyncDate } = useSelector(state => state.auth)
  const [waitingTime, setWaitingTime] = useState("")
  const {productThreshold,syncProductLoader = false } = useSelector(state => state.products);
  const thresholdValue = productThreshold?.threshold;
  const waitingTimeInterval = useRef(null);

  console.log("thresholdValue",thresholdValue)


  useEffect(() => {
    let newFilters = {}
    let searchT = ''
    let newActive = true
    if (location.search) {
      const { limit = 10, skip = 0, page = 0, searchTerm = '', active = true } = queryString.parse(location.search);
      searchT = searchTerm
      newActive = active === "true"
      newFilters = {
        limit: Number(limit),
        skip: Number(skip),
        page: Number(page)
      }
      setIsActiveTab(newActive)
      setFilters(newFilters)
      setSearch(searchT)
      setCurrentPage(skip / limit)
    }
    hitRequest({ ...newFilters, searchTerm: searchT, active: newActive })
    runWaitingTimeInterval()
        return () => {
            clearInterval(waitingTimeInterval.current)
        }

  }, [])

  const runWaitingTimeInterval = () => {
    if (getData("adminLastSync")) {
        const value = JSON.parse(getData("adminLastSync"))
        waitingTimeInterval.current = setInterval(() => {
           const diffInMin = Math.floor((Date.now() - value) / 1000 / 60);

            if (diffInMin > 2) {
                setWaitingTime("")
                clearInterval(waitingTimeInterval.current)
            }
            let waitingMsg = ""
            if (diffInMin === 0 || diffInMin === 1) waitingMsg = "Wait for 2 min"
            if (diffInMin === 2) waitingMsg = "Wait for 1 min"
            setWaitingTime(waitingMsg)
        }, 2000);
    }
}

  useEffect(() => {
    location.search = queryString.stringify({ ...filters, searchTerm: search, active: isActiveTab });
    navigate({
      pathname: '/dashboard/manage_products',
      search: location.search
    })
  }, [filters, search, isActiveTab])


  const hitRequest = (filters = {}) => {
    dispatch(authActions.getLastSyncDate())
    dispatch(productsActions.getAllProducts({ ...filters, sortBy: 'active' }))

  }

  const handleChange = (e) => {
    const { value } = e.target
    if (value.length === 1 && value[0] === ' ') {
      setSearch('');
      return;
    }
    const newFilters = { limit: 10, skip: 0, page: 0, searchTerm: value, active: isActiveTab }
    setSearch(value);
    setFilters({ limit: 10, skip: 0, page: 0 })
    debouncedSave(newFilters);
  }

  const debouncedSave = useCallback(_.debounce(filters => hitRequest(filters), 1000), []);

  const onChangePagination = (e, page, activeStatus) => {
    setCurrentPage(page);
    const skip = page * filters.limit
    setFilters({ ...filters, skip, page })
    hitRequest({ ...filters, skip, page, searchTerm: search, active: activeStatus })
};


  const onChangeRowsPerPage = (e) => {
    const newFilters = { limit: parseInt(e, 10), page: 0, skip: 0, active: isActiveTab }
    setFilters(newFilters)
    hitRequest({ ...newFilters, searchTerm: search })
  }


  const onClickClearInputField = () => {
    if (!search) return
    const newFilters = { limit: 10, skip: 0, page: 0, }
    setFilters(newFilters)
    hitRequest({ ...newFilters, active: isActiveTab })
    setSearch('')
  }

  useEffect(() => {
    dispatch(productsActions.getThreshold())
}, []);

  const onChangeSelectedTab = (e) => {
    const status = e?.value;
    const newFilters = { limit: 10, skip: 0, page: 0, }
    setIsActiveTab(status)
    setFilters(newFilters)
    setCurrentPage(0)
    hitRequest({ ...newFilters, searchTerm: search, active: status, page: 0 })
  }

  const handlePrevPage = (prevPage, activeStatus) => {
    const skip = prevPage * filters.limit
    const newFilters = { ...filters, skip, page: prevPage, active: activeStatus }
    setFilters(newFilters)
    setCurrentPage(prevPage)
    hitRequest(newFilters)
};

const handleNextPage = (nextPage,activeStatus) => {
  const skip = nextPage * filters.limit
  const newFilters = { ...filters, skip, page: nextPage, active: activeStatus }
  setFilters(newFilters)
  setCurrentPage(nextPage)
  hitRequest(newFilters)
};

const onSyncBlazeProducts = () => {
  if (!syncProductLoader && !waitingTime) {
      dispatch(productsActions.SyncProduct()).then(res => {
          setData("adminLastSync", Date.now())
          setWaitingTime("Wait for 2 min")
          runWaitingTimeInterval()
          dispatch(authActions.getLastSyncDate())
      })
  }
}

const dt = (lastSyncDate && new Date(lastSyncDate)) || '';
const la = dt.toLocaleString('en-US', { timeZone: 'America/Los_Angeles' });



return (
  <>
  <Helmet>
  <title>Products | Ankar Admin </title>
</Helmet>
  <Grid item xs={12} className="manage-product-section">
    <Grid container className='pb-3'>
      <Grid item xs={12} sm={4} md={4} xl={4} className="page-heading">
        <h2 className='m-0'>Products</h2>
      </Grid>

      <div className="last-sync">
        <div>
          Last Sync : {la || "Syncing Now"}
        </div>
        { <LightTooltip TransitionComponent={Zoom} title={`${syncProductLoader ? "Syncing is in Progress" : waitingTime ? `${waitingTime} for next sync"` : "Sync products from blaze"}`}>
          <div className={`product-sync-div ${waitingTime ? "disabled-text" : ""}`} onClick={onSyncBlazeProducts}>
            {syncProductLoader ? <CircularProgress size={18} /> : waitingTime || "Sync Products"}
          </div>
        </LightTooltip> }
      </div>

        <Grid item xs={12} sm={12} md={12} xl={12} className="d-flex p-0 search-section jc-content-end">
          <div className="search-input">
            <CustomInput
              label="Search"
              name="search"
              placeholder="Search"
              onChange={handleChange}
              value={search}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: search ? (
                  <InputAdornment position="end" className="cur-pointer">
                    <ClearIcon onClick={onClickClearInputField} />
                  </InputAdornment>
                ) : null,
              }}
            />
          </div>

          <div className='search-btn-drop-down d-flex'>
            <div className="manage-top-tabs ml-3">
            <Select
              isSearchable={false}
              options={filterOptions}
              value={filterOptions?.find((option => option.value === isActiveTab?.toString()))}
              onChange={onChangeSelectedTab}
              styles={colorStyles}
            />
          </div>
          <div className='add-category-button ml-3'>
              <Button
                  variant="contained"
                  color="primary"
                  onClick={() => { dispatch(commonActions.openCustomModalDialog({ thresholdValue }, "ThresholdModal")) }}
              >
                  {false ? <CircularProgress size={20} style={{ color: "white" }} /> : 'Threshold'}
              </Button>
                      </div>
                      </div>
        </Grid>
    </Grid>
  <AllProducts
    onChangePagination={(event, page) => {
      const activeStatus = isActiveTab;
      setCurrentPage(page);
      onChangePagination(event, page, activeStatus);
    }}
    onChangeRowsPerPage={onChangeRowsPerPage}
    filters={filters}
    searchTerm={search}
    isActiveTab={isActiveTab}
    currentPage={currentPage}
    handleNextPage={(nextPage) => {
        const activeStatus = isActiveTab;
        handleNextPage(nextPage, activeStatus);
    }}
    handlePrevPage={(prevPage) => {
        const activeStatus = isActiveTab;
        handlePrevPage(prevPage, activeStatus);
    }}
  />
</Grid>
</>
  );
}