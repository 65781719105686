import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { useDispatch ,useSelector } from 'react-redux';
import {  useNavigate } from 'react-router-dom';
import _, { filter } from 'lodash';
import { Grid, TableCell,Box,Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// import queryString from 'query-string'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import * as commonActions from '../../../redux/actions/common'
import * as promotionsActions from "../../../redux/actions/promotions"
import * as promotionsAPI from "../../../redux/api/promotions"
import CustomTable from "../../../components/common/CustomTable"
import TableLoader from '../../../components/common/TableLoader';
import EditPromotions from './EditPromotions';
import AddPromotionModal from './AddPromotionModal'
import EditIcon from '../../../components/common/Icons/EditIcon';
import DeleteIcon from '../../../components/common/Icons/DeleteIcon';




function AllPromotions(props) {
    const { onChangePagination = () => { }, filters = {}, onChangeRowsPerPage = () => { }, searchTerm = '', isActiveTab = true, currentPage, handleNextPage, handlePrevPage, } = props
    const { allPromotions, isLoading } = useSelector(state => state.promotions)
    const { values, total = 0 } = allPromotions
    const dispatch  = useDispatch()
  
    const totalPages = useMemo(() => {
      return Math.ceil(total / filters.limit) > 1  ? Math.ceil(total / filters.limit): 1
  
    }, [total, filters.limit]);
  
  
    const columns = [
      {
        name: 'S.no',
        key: 's_no',
      },
      {
        name: 'Title',
        key: 'name'
      },
      {
        name: 'Description',
        key: 'promoDesc',
      },
      {
        name: 'Status',
        key: 'active',
      },
      {
        name: 'Promocode',
        key: 'promoCodes',
      },
      {
        name: 'Actions',
        key: 'actions'
      },
  
    ]
    
    const handleConfirmation = (id) => {
      dispatch(commonActions.openCustomModalDialog({
          title: "Are you sure you want to delete?",
          confirm: () => { handleDeletePromotion(id) },
          cancel: () => { }
      }, "ConfirmationModal"))
  }
  
const handleDeletePromotion = (id) => {
  dispatch(promotionsActions.deletePromotion({ promotionId: id }))
    .then(() => {
      setTimeout(() => {
        window.location.reload();
    }, 1000);
}).catch(() => { })
};


  const handleEditPromotion = (item) => {
      dispatch(commonActions.openCustomModalDialog({ data: item }, "EditPromotion"))
  }
 const parseRowData = useMemo(() => {
  return values?.map(((item, idx) => {
    return {
      ...item,
      s_no: filters.skip + (idx+1),
      active: item?.active ? (
        <span className="badge-status complete-badge">Active</span>
      ) : (
        <span className="badge-status decline-badge">In-Active</span>
      ),
      actions: <Box>
        <EditIcon onClick={() => handleEditPromotion(item)} />
        <DeleteIcon onClick={() => handleConfirmation(item.id)}  />
      </Box>
    }
  })) || []
}, [values])

      return (
        <>
          {   
            isLoading ? <TableLoader  /> : <CustomTable
              tableHeading={columns}
              rowsData={parseRowData}
              currentPage={currentPage}
              totalPages={totalPages}
              skip={filters.skip}
              handlePrevPage={handlePrevPage}
              handleNextPage={handleNextPage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              limit={filters.limit}
              onChangePagination={onChangePagination}
            />
          }
          
        </>
      );
    }



export default AllPromotions