import {
    GET_CUSTOMER_DATA,
    GET_CUSTOMER_DATA_SUCCESS,
    GET_CUSTOMER_DATA_FAILURE,
    GET_CUSTOMER_ORDERS,
    GET_CUSTOMER_ORDERS_SUCCESS,
    GET_CUSTOMER_ORDERS_FAILURE
} from '../actions/customer'

const initialState={
    isLoading: false,
    allCustomers: {},
    allOrders:{},
}

export default function (state = { ...initialState }, action = {}) {
    switch (action.type) {
        case GET_CUSTOMER_DATA: {
            return { ...state, isLoading: true }
        }

        case GET_CUSTOMER_DATA_SUCCESS: {
            return { ...state, isLoading: false, allCustomers: action.payload }
        }

        case GET_CUSTOMER_DATA_FAILURE: {
            return { ...state, isLoading: false, allCustomers: action.payload }
        }

        case GET_CUSTOMER_ORDERS:{
            return{ ...state, isLoading:true}
        }

        case GET_CUSTOMER_ORDERS_SUCCESS: {
            return { ...state, isLoading:false,allOrders: action.payload }
        }

        case GET_CUSTOMER_ORDERS_FAILURE: {
            return { ...state, isLoading:false,allOrders: action.payload}
        
        }       

        default:
            return state
    }
}