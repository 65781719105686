import { toast } from 'react-toastify';

import { getAllAdminAPI, getAllAdminDataAPI, addUserAPI, deleteUserAPI } from "../api/user";

export const GET_ALL_ADMIN = 'GET_ALL_ADMIN';
export const GET_ALL_ADMIN_SUCCESS = 'GET_ALL_ADMIN_SUCCESS'
export const GET_ALL_ADMIN_FAILED = 'GET_ALL_ADMIN_FAILED'

export function getAllAdmin() {
    return dispatch => {
        dispatch({ type: GET_ALL_ADMIN })
        return new Promise((resolve, reject) => {
            getAllAdminAPI().then(res => {
                dispatch({ type: GET_ALL_ADMIN_SUCCESS, 
                    payload: res?.data })
                resolve(res)
            }).catch((err) => {
                dispatch({ type: GET_ALL_ADMIN_FAILED })
                reject(err)
            })
        })
    }
}
export const GET_ALL_ADMIN_DATA = 'GET_ALL_ADMIN_DATA';
export const GET_ALL_ADMIN_DATA_SUCCESS = 'GET_ALL_ADMIN_DATA_SUCCESS'
export const GET_ALL_ADMIN_DATA_FAILED = 'GET_ALL_ADMIN_DATA_FAILED'

export function getAllAdminData(data) {
    return dispatch => {
        dispatch({ type: GET_ALL_ADMIN_DATA })
        return new Promise((resolve, reject) => {
            getAllAdminDataAPI(data).then(res => {
                dispatch({ type: GET_ALL_ADMIN_DATA_SUCCESS, 
                    payload: res?.data})
                resolve(res)
            }).catch((err) => {
                dispatch({ type: GET_ALL_ADMIN_DATA_FAILED })
                reject(err)
            })
        })
    }
}

export const ADD_ADMIN = 'ADD_ADMIN';
export const ADD_ADMIN_SUCCESS = 'ADD_ADMIN_SUCCESS'
export const ADD_ADMIN_FAILED = 'ADD_ADMIN_FAILED'

export function addUser(data, id) {
    return dispatch => {
        dispatch({ type: ADD_ADMIN })
        return new Promise((resolve, reject) => {
            addUserAPI(data, id).then(res => {
                dispatch({ type: ADD_ADMIN_SUCCESS, payload: res })
                toast.success(res.message)
                resolve(res)
            }).catch((err) => {
                dispatch({ type: ADD_ADMIN_FAILED })
                reject(err)
            })
        })
    }
}

export const DELETE_ADMIN = 'DELETE_ADMIN';
export const DELETE_ADMIN_SUCCESS = 'DELETE_ADMIN_SUCCESS'
export const DELETE_ADMIN_FAILED = 'DELETE_ADMIN_FAILED'

export function deleteUser(id) {
    return dispatch => {
        dispatch({ type: DELETE_ADMIN })
        return new Promise((resolve, reject) => {
            deleteUserAPI(id).then(res => {
                dispatch({ type: DELETE_ADMIN_SUCCESS, payload: res })
                toast.success(res.message)
                resolve(res)
            }).catch((err) => {
                dispatch({ type: DELETE_ADMIN_FAILED })
                reject(err)
            })
        })
    }
}