import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as commonActions from "../../../redux/actions/common"

import Dialog from './dialog';
import AddCategory from '../../../sections/@dashboard/categories/AddCategories';
import OrderViewModal from './modal/OrderViewModal';
import AddDeliveryHoursModal from '../../addDeliveryHours';
import AddPromotions from '../../../sections/@dashboard/promotions/AddPromotionModal';
import EditPromotions from '../../../sections/@dashboard/promotions/EditPromotions';
import UserModal from '../../addUser';
import AddBrandModal from '../../addBrand';
import AddIntegrationModal from '../../addIntegration'
import ThresholdModal from '../../../sections/@dashboard/products/ThresholdModal';
// import CustomConfirmationModal from '../CustomConfirmationModal';
import CustomConfirmationModal from '../CustomConfirmationModal';
import CustomerViewModal from './modal/CustomerViewModal';
// import ViewItems from '../../../containers/Orders/ViewItems'



export default function CustomModal() {
    const dispatch = useDispatch();
    const { modal,modalData } = useSelector(state => state.common);
    console.log("modal ==<", modal)
    const closeModal = () => {
        dispatch(commonActions.closeCustomModalDialog({}))
    }
    switch (modal) {
        case "AddCategory":
            return <RenderModalWithComponent maxWidth='md' component={AddCategory} customFunction={closeModal} />
        
        case 'OrderView':
            return <RenderModalWithComponent maxWidth='md' component={OrderViewModal} customFunction={closeModal} />
        
            case 'CustomerView':
            return <RenderModalWithComponent maxWidth='md' component={CustomerViewModal} customFunction={closeModal} />
        
        case "AddUserModal":
            return <RenderModalWithComponent maxWidth='md' component={UserModal} customFunction={closeModal} />
            
            // case "AddNotificationModal":
            //     return <RenderModalWithComponent maxWidth='md' component={AddNotificationModal} customFunction={closeModal} />

            case "AddBrandModal":
            return <RenderModalWithComponent  maxWidth='lg' component={AddBrandModal} customFunction={closeModal} />
        // case "AddBrandModal":
        //     return <RenderModalWithComponent component={AddBrandModal} customFunction={closeModal} />
      
        case "ConfirmationModal":
            return <RenderModalWithComponent maxWidth='sm' component={CustomConfirmationModal} customFunction={closeModal}  />
        // case "AddSpecialProduct":
        //     return <RenderModalWithComponent maxWidth='md' component={AddSpecialProduct} customFunction={closeModal} />
      
        case "AddPromotions":
            return <RenderModalWithComponent maxWidth='md' component={AddPromotions} customFunction={closeModal}  />

        case "AddIntegrationModal":
            return <RenderModalWithComponent maxWidth='md' component={AddIntegrationModal} customFunction={closeModal} />
      
        case "EditPromotion":
            return <RenderModalWithComponent maxWidth='md' component={EditPromotions} customFunction={closeModal} />
        // case "AddUserModal":
        //     return <RenderModalWithComponent component={AddUserModal} customFunction={closeModal} />
        // case "ConfirmationModal":
        //     return <RenderModalWithComponent component={CustomConfirmationModal} customFunction={closeModal} maxWidth="xs" />
        // case "AddSpecialProduct":
        //     return <RenderModalWithComponent component={AddSpecialProduct} customFunction={closeModal} />
      

        // case "AddTruckZipcode":
        //     return <RenderModalWithComponent component={AddTruckZipcode} customFunction={closeModal} />
        // case "ViewItems":
        //     return <RenderModalWithComponent component={ViewItems} customFunction={closeModal} maxWidth="md" />
        case "ThresholdModal":
            return <RenderModalWithComponent component={ThresholdModal} modalData={modalData} customFunction={closeModal} maxWidth='md' />
        case "AddDeliveryHoursModal":
            return <RenderModalWithComponent component={AddDeliveryHoursModal} customFunction={closeModal} maxWidth='md' />
        default:
            return null
    }
}

const RenderModalWithComponent = ({ component: Component, maxWidth = "md", isCloseIcon, customFunction, ...rest }) => {
    return (
        <>
            <Dialog isCloseIcon={isCloseIcon} fullWidth maxWidth={maxWidth} customFunction={customFunction}>
                <Component {...rest} closeModal={customFunction} />
            </Dialog>
        </>
    )
}

