import { toast } from "react-toastify"
import { forgotPasswordAPI,resetPasswwordAPI } from "../api/forgotPassword"

export const FORGOT_PASSWORD = 'FORGOT_PASSWORD'
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS'
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE'

export function forgotPassword(data) {
    return dispatch =>{
        dispatch({
            type: FORGOT_PASSWORD,
        })
        return new Promise((resolve, reject) =>{
            forgotPasswordAPI(data)
            .then((res) =>{
                dispatch({
                    type: FORGOT_PASSWORD_SUCCESS,
                    payload:res?.data
                })
                return resolve(data)

            })
            .catch(err => {
                dispatch({
                    type: FORGOT_PASSWORD_FAILURE
                })
                toast.error(err)
                return reject(err)
            })
        })
    }
}

export const RESET_PASSWORD='RESET PASSWORD'
export const RESET_PASSWORD_SUCCESS='RESET PASSWORD_SUCCESS'
export const RESET_PASSWORD_FAILURE='RESET PASSWORD FAILURE'

export function resetPassword(token){
    return dispatch =>{
        dispatch({
            type:RESET_PASSWORD
        })
        return new Promise((resolve, reject) =>{
            resetPasswwordAPI(token)
            .then((res)=>{
                dispatch({
                    type:RESET_PASSWORD_SUCCESS,
                    payload:res?.data
                })
                return resolve(token)
            })
            .catch((err)=>{
                dispatch({
                    type:RESET_PASSWORD_FAILURE
                })
                toast.error(err)
                return reject(err)
            });
        })
    }
}

