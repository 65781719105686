import { apiDelete, apiGet, apiPost } from "../../utils";

export function getStaticIntegrationsAPI(data){
    return apiGet(`api/v1/admin/integrations/all/`,data)
}

export function getIntegrationsAPI(data){
    return apiGet(`api/v1/admin/integrations/`,data)
}

export function addIntegrationsAPI(data){
    return apiPost(`api/v1/admin/integrations/`,data)
}

export function updateIntegrationsAPI(data){
    return apiPost(`api/v1/admin/integrations/`,data)
}

export function deleteIntegrationsAPI(id){
    return apiDelete(`api/v1/admin/integrations/${id}`,)
}

// export function getDetailsIntegrationsAPI(integrationId,data){
//     return apiGet(`api/v1/admin/integrations/${integrationId}`,data)
// }