import { useLocation, useNavigate, useParams } from "react-router-dom"; 
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, FormControl, Input, Paper, TextField } from "@material-ui/core";
import { toast } from "react-toastify";
import { CircularProgress, Grid, Hidden } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { Label } from "@material-ui/icons";
import * as forgotPasswordActions from "../../../redux/actions/forgotPassword";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    height: "100%",
    width: "100%",
  },
  innerImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    objectPosition: "70%",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function ResetPassword({ setPage }) {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const [imageLoader, setImageLoader] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const { isLoading } = useSelector((state) => state.auth);
  const isSubmitDisabled = !password || !confirmPassword;

  const { search } = useLocation(); 
 
  const token = search.split("=")[1];

async function changePassword(event) {
  event.preventDefault();
  if(!token) return;
  if (password === confirmPassword) {
    if (password.length < 6) {
      toast.error("Password should be at least 6 characters long.");
      return;
    }
    try {
      const userData = {
        password,
        confirmPassword,
        token 
      };

     
  dispatch(forgotPasswordActions.resetPassword(userData)).then(
    toast.success("Password changed successfully, please login."),
    setTimeout(()=>{
      navigate("/login")
    },[4000])
  )
  

    } catch (error) {
      console.log(error);
    }
  } else {  
    toast.error("Passwords do not match, please try again!");
  }
}



  return (
    <div className="login-background-contianer">
      <Grid
        container
        component="main"
        className={`${classes.root} login-container`}
      >
        <Grid item xs={12} sm={12} md={112} lg={12} className="login-container-item">
          <Paper style={{ height: "100vh" }}>
            <Grid container className="" style={{ height: "100%" }}>
             
              <Grid item xs={12} sm={12} md={6} style={{ display: "flex" }}>
                <Grid container>
                  <Grid item xs={12} sm={12} md={12} className="form-container-item">
                    <div className={classes.paper}>
                      <div className="mb-2">
                        <h1>Reset Password</h1>
                      </div>
                      <form className={`${classes.form} login-form reset-password`}>
                        <TextField
                           type={showPassword ? 'text' : 'password'}
                          placeholder="Enter your new password"
                          variant="outlined"
                          fullWidth
                          required=""
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end" className="cur-pointer smaller-adornment" variant='outlined'>
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() => setShowPassword(!showPassword)}
                                  onMouseDown={(e) => e.preventDefault()}
                                  size="small"
                                >
                                  {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                        {/* <p style={{ marginBottom: "10px" }} /> */}
                        <TextField
                          type="password"
                          placeholder="Confirm your new password"
                          variant="outlined"
                          fullWidth
                          required=""
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          
                        />
                        {/* <p style={{ marginBottom: "10px" }} /> */}
                        <Button
                          type="Submit"
                          fullWidth
                          variant="contained"
                          color="primary"
                          className={classes.submit}
                          onClick={changePassword}
                          disabled={isSubmitDisabled}
                        >
                          {isLoading ? (
                            <CircularProgress
                              size={25}
                              style={{ color: "white" }}
                            />
                          ) : (
                            "Submit"
                          )}
                        </Button>
                      </form>
                      </div>
                  </Grid>
                </Grid>
              </Grid>
              <Hidden only={["xs", "sm"]}>
                <Grid item md={6} className={classes.image}>
                  <img
                    src="../assets/images/first-page.jpg"
                    alt=""
                    onLoad={() => setImageLoader(false)}
                    className={classes.innerImage}
                  />
                </Grid>
              </Hidden>
            </Grid>
            </Paper>
        </Grid>
      </Grid>
    </div>
  );

}

