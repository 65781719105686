import { useMemo, useState, useCallback, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string'
import { Box } from '@material-ui/core';
import { Helmet } from 'react-helmet-async';
import SearchIcon from '@material-ui/icons/Search';
import { Container, Stack, Typography, MenuItem, Button, TextField, Grid, InputAdornment, } from '@mui/material';
import _, { filter } from 'lodash';
import Select from 'react-select';
import * as orderActions from "../redux/actions/orders";
import * as commonActions from '../redux/actions/common';
import CommonLoading from "../components/common/CommonLoading";
import { filterOptions } from '../utilities/helper';
import { colorStyles } from '../utils';
import CustomInput from '../components/common/CustomInput';
import CustomTable from '../components/common/CustomTable';
import ViewIcon from '../components/common/Icons/ViewIcon'
import TableLoader from '../components/common/TableLoader';



const columns = [
  {
    name: 'S.no',
    key: 's_no',
  },
  {
    name: 'Customer Email',
    key: 'email',
  },
  {
    name: 'Total Items',
    key: 'totalItems',
  },
  {
    name: 'Sub Total',
    key: 'subTotal',
  },
  {
    name: 'Discount',
    key: 'totalDiscount',
  },
  {
    name: 'Total Tax',
    key: 'totalCalcTax',
  },
  {
    name: 'Delivery Fee',
    key: 'deliveryFee',
  },
  {
    name: 'Order Total',
    key: 'total',
  },
  {
    name: "Order Status",
    key: 'cartStatus'
  },
  {
    name: 'Actions',
    key: 'actions'
  },

]

export default function OrderPage() {
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const { orders: { values = [], total = 0 }, isLoading } = useSelector(state => state.orders, shallowEqual)
  const [isActiveTab, setIsActiveTab] = useState('All')

  const debouncedSave = useCallback(_.debounce(filters => hitRequest(filters), 1000), []);

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [search, setSearch] = useState("")
  const [filters, setFilters] = useState({
    limit: 10,
    skip: 0,
    page: 0,
  })

  const onChangePagination = (e, page) => {
  const skip = page * filters.limit
setFilters({ ...filters, skip, page, cartStatus: isActiveTab })
hitRequest({ ...filters, skip, page, searchTerm: search, cartStatus: isActiveTab })

  }

  const onChangeRowsPerPage = (e) => {
    const newFilters = { limit: parseInt(e, 10), page: 0, skip: 0 }
    setFilters(newFilters)
    setCurrentPage(0)
    hitRequest({ ...newFilters, searchTerm: search,cartStatus: isActiveTab })
  }

  const customImagePath = '/';

  const totalPages = useMemo(() => {
    return Math.ceil(total / filters.limit) > 1 ? Math.ceil(total / filters.limit) : 1

  }, [total, filters.limit]);

  const handlePrevPage = (prevPage) => {
    setCurrentPage(prevPage)
    const skip = prevPage * filters.limit
    setFilters({ ...filters, skip, page: prevPage })
    hitRequest({ ...filters, skip, page: prevPage, searchTerm: search,cartStatus: isActiveTab })
  };
  
  const handleNextPage = (nextPage) => {
    setCurrentPage(nextPage)
    const skip = nextPage * filters.limit
    setFilters({ ...filters, skip, page: nextPage })
    hitRequest({ ...filters, skip, page: nextPage, searchTerm: search,cartStatus: isActiveTab })
  };


  const handleChange = (e) => {
    const { value } = e.target
    if (value.length === 1 && value[0] === ' ') {

      setSearch('');
      return;
    }
    const newFilters = { limit: 10, skip: 0, page: 0, searchTerm: value,cartStatus: isActiveTab }
    setSearch(value);
    setFilters({ limit: 10, skip: 0, page: 0 })
    setCurrentPage(0)
    debouncedSave(newFilters);
  }


  const onChangeSelectedTab = (e) => {
    const status = e?.value;

    const newFilters = { limit: 10, skip: 0, page: 0, }
    setIsActiveTab(status)
    setFilters(newFilters)
    setCurrentPage(0)
    hitRequest({ ...newFilters, searchTerm: search, cartStatus: status })
  }


  const hitRequest = (filters = {}) => {

    const cartStatus = filters?.cartStatus === 'Active' ? 'Placed' :
                       filters?.cartStatus === 'Declined' ? 'Declined' :
                       filters?.cartStatus === 'InProgress' ? 'InProgress' :
                       filters?.cartStatus === 'All' ? 'all' : 
                       '';
    dispatch(orderActions.getAllOrders({
      ...filters,
      cartStatus, 
      sortBy: 'all' 
    }));
  };
  
  const parseRowData = useMemo(() => {
    return values?.map((brand, idx) => {
      const cartStatusClass =
        brand.cartStatus === 'Placed'
          ? 'complete-badge'
          : brand.cartStatus === 'InProgress'
          ? 'inprogress-badge'
          : 'decline-badge';
  
      return {
        ...brand,
        s_no: filters.skip + idx + 1,
        totalItems: brand?.items?.length || 0,
        totalDiscount: `$${brand?.totalDiscount}`,
        cartStatus: (
          <span className={`badge-status ${cartStatusClass}`}>{brand.cartStatus}</span>
        ),
        subTotal: `$${brand?.subTotal}`,
        totalCalcTax: `$${brand?.totalCalcTax}`,
        deliveryFee: `$${brand?.deliveryFee}`,
        total: `$${brand?.total}`,
        actions: (
          <Box>
            <ViewIcon
              onClick={() => navigate(`/dashboard/order/viewOrder/${brand?.orderId}`)}
            />
            {/* <ViewIcon onClick={() => dispatch(commonActions.openCustomModalDialog(brand, 'OrderView'))} /> */}
          </Box>
        ),
      };
    }) || [];
  }, [values]);

  useEffect(() => {
    let newFilters = {}
    let searchT = ''
    let newActive = true
    if (location.search) {
      const { limit = 10, skip = 0, page = 0, searchTerm = '', active = true, cartStatus = "" } = queryString.parse(location.search);

      searchT = searchTerm
      newActive = active === "true"
      newFilters = {
        limit: Number(limit),
        skip: Number(skip),
        page: Number(page),
      }
      setIsActiveTab(cartStatus)
      setFilters(newFilters)
      setSearch(searchT)
      setCurrentPage(skip / limit)
      hitRequest({ ...newFilters, searchTerm: searchT, cartStatus, sortBy: 'active' })
      return;
    }
    hitRequest({ ...newFilters, searchTerm: searchT, cartStatus: "All" })

  }, [])

  useEffect(() => {
    location.search = queryString.stringify({ ...filters, searchTerm: search, cartStatus: isActiveTab });
    navigate({
      pathname: '/dashboard/order',
      search: location.search
    })
    //   window.onpageshow = function(event) {
  //     if (event.persisted) {
  //         window.location.reload();
  //     }
  // };
  }, [filters, search, isActiveTab])


  return (
    <>
      <Helmet>
        <title>Orders | Ankar Admin </title>
      </Helmet>
      <Grid item xs={12} className="manage-product-section">
        <Grid container className='pb-3'>
          <Grid item xs={12} sm={6} md={4} xl={4} className="page-heading">
            <h2 className='m-0'>Orders</h2>
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={12} className="d-flex p-0 search-section jc-content-end">
            <div className="search-input">
              <CustomInput
                label="Search"
                name="search"
                placeholder="Search"
                onChange={handleChange}
                value={search}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  // endAdornment: search ? (
                  //   <InputAdornment position="end" className="cur-pointer">
                  //     <ClearIcon onClick={onClickClearInputField} />
                  //   </InputAdornment>
                  // ) : null,
                }}
              />
            </div>
            <div className="manage-top-tabs ml-3">
              <Select
                isSearchable={false}
                options={filterOptions}
                onChange={onChangeSelectedTab}
                styles={colorStyles}
                value={filterOptions?.find((option => option.value === isActiveTab?.toString()))}
              />
            </div>
          </Grid>

        </Grid>
        {
          isLoading ? <TableLoader /> : <CustomTable
            tableHeading={columns}
            rowsData={parseRowData}
            currentPage={currentPage}
            totalPages={totalPages}
            skip={filters.skip}
            handlePrevPage={handlePrevPage}
            handleNextPage={handleNextPage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            limit={filters.limit}
            onChangePagination={onChangePagination}
          />
        }
      </Grid>

    </>
  );
}