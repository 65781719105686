import React, { useState, useEffect } from 'react'
import { Box, Card, Checkbox, Chip, Divider, Grid, Switch, TextField, Typography } from '@material-ui/core'
// import { ArrowBack } from '@material-ui/icons'
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import EditProductDetails from '../components/editProductDetails';
import * as productActions from '../redux/actions/products'


const intialState = {
    strainType: "",
    infoEffects: [],
    brandDescription: "",
    websiteProductName: "",
    disclaimerText: "",
    metaTitle: "",
    metaDescription: "",
    focusKeyword: [],
    seoContent: "",
    productDescription: "",
    isBestSelling : false,
    isCopyText: false,
};



export default function ProductDetails(props) {

    const dispatch = useDispatch();
    const { id = "" } = useParams()

    const productId = id;

    const { isLoading, infoAndEffectsData, singleProductDetailData } = useSelector((state) => state.products)

    const [data, setData] = useState(intialState);


    const [productStatus, setProductStatus] = useState(false)

    useEffect(() => {
        dispatch(productActions.getInfoAndEffect())
        dispatch(productActions.getSingleProductDetails(productId))
    }, [])

    useEffect(() => {
        onRefreshProductDetails()
    }, [singleProductDetailData])

    const onRefreshProductDetails = () => {
        const updatedEffects = [...(singleProductDetailData?.productEffects || [])]?.map(v => v.title)
        setData({
            ...data,
            infoEffects: updatedEffects || [],
            strainType: (singleProductDetailData?.flowerType?.toLowerCase()) || "",
            brandDescription: (singleProductDetailData?.brandDescription) || "",
            websiteProductName: (singleProductDetailData?.websiteProductName) || "",
            metaTitle: singleProductDetailData?.metaTitle || "",
            metaDescription: singleProductDetailData?.metaDescription || "",
            focusKeyword: singleProductDetailData?.focusKeyword,
            seoContent: singleProductDetailData?.seoContent || "",
            productDescription: singleProductDetailData?.productDescription || "",
            disclaimerText: singleProductDetailData?.disclaimerText || "",
            isCopyText: singleProductDetailData?.isCopyText || false,
            isBestSelling : singleProductDetailData?.isBestSelling || false
        })
        setProductStatus((singleProductDetailData?.showInWidget) || false)
    }

    const onChangeCheckbox = (e, name) => {
        e.preventDefault();

        const updatedEffects = [...(data?.infoEffects || [])]
        const alreadyExist = updatedEffects.findIndex(v => v === name)
        if (alreadyExist === -1) {
            updatedEffects.push(name)
        } else {
            updatedEffects.splice(alreadyExist, 1)
        }
        setData({ ...data, infoEffects: updatedEffects })
    }


    const onChangeBrandDescription = (e) => {
        const { name, value, checked } = e.target;


        if (name === "focusKeyword") {
            const strToArr = value.split(',')
            setData({
                ...data,
                [name]: strToArr
            })
        }
        if (name === "isCopyText" || name === 'isBestSelling') {
            setData({
                ...data,
                [name]: checked
            })
        }
        else {

            setData({
                ...data,
                [name]: value
            })
        }
    };



    const handleSubmit = (e) => {
        e.stopPropagation();
        dispatch(productActions.updateSingleProduct(productId, data)).then(() => {
            toast.success('Product updated successfully')
        }).catch(() => { onRefreshProductDetails() })
    }

    const onChangeProductStatus = () => {
        setProductStatus(!productStatus)
        dispatch(productActions.updateSingleProduct(productId, { active: !productStatus })).then(() => {
            toast.success('Product status updated successfully')
        }).catch(() => { onRefreshProductDetails() })
    }
    
    return (

        <div className='edit-productdetails'>
            <EditProductDetails
                infoAndEffectsData={infoAndEffectsData}
                singleProductDetailData={singleProductDetailData}
                data={data}
                productStatus={productStatus}
                onChangeProductStatus={onChangeProductStatus}
                onChangeCheckbox={onChangeCheckbox}
                handleSubmit={handleSubmit}
                onChangeBrandDescription={onChangeBrandDescription}
            />

        </div>


    )
}
