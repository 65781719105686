import { GET_DELIVERY_HOURS_FAILED,GET_DELIVERY_HOURS_REQUEST,GET_DELIVERY_HOURS_SUCCESS } from "../actions/deliveryHours"


const initialState = {
    deliveryHours: {},
    isLoading: false,
}
  

export default function (state = { ...initialState }, action = {}) {
    switch (action.type) {
        case GET_DELIVERY_HOURS_REQUEST: {
            return { ...state, isLoading: true }
        }

        case GET_DELIVERY_HOURS_SUCCESS: {
            return { ...state, isLoading: false, deliveryHours: action.payload }
        }

        case GET_DELIVERY_HOURS_FAILED: {
            return { ...state, isLoading: false, deliveryHours: action.payload }
        }

        default:
            return state
    }
}