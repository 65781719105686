import { toast } from 'react-toastify';
// import { resolve } from 'path';
import { getFreeGiftApi, updateFreeGiftApi, getContentCashApi, updateContentCashApi, getContentCashlessApi, updateContentCashlessApi, getFreeGiftNewApi, updateFreeGiftNewApi } from '../api/cannasettings';

export const GET_FREE_GIFT = 'GET_FREE_GIFT';
export const GET_FREE_GIFT_SUCCESSFUL = 'GET_FREE_GIFT_SUCCESSFUL';
export const GET_FREE_GIFT_FAILED = 'GET_FREE_GIFT_FAILED';

export function getFreeGift(uniqueId){
    return dispatch => {
        dispatch({
            type:GET_FREE_GIFT
        })
        return new Promise((resolve, reject) => {
            getFreeGiftApi(uniqueId)
            .then((res) => {
                dispatch({
                    type: GET_FREE_GIFT_SUCCESSFUL,
                    data: res?.data
                })
                return resolve(res)
            })
            .catch(err => {
                dispatch({
                    type: GET_FREE_GIFT_FAILED
                })
                return reject(err)
            })
        })
    }
}

export const UPDATE_FREE_GIFT = 'UPDATE_FREE_GIFT';
export const UPDATE_FREE_GIFT_SUCCESSFUL = 'UPDATE_FREE_GIFT_SUCCESSFUL';
export const UPDATE_FREE_GIFT_FAILED = 'UPDATE_FREE_GIFT_FAILED';

export function updateFreeGift(data){
    return dispatch => {
        dispatch({
            type:UPDATE_FREE_GIFT
        })
        return new Promise((resolve, reject) => {
            const {uniqueId, dataToUpdate} = data
            updateFreeGiftApi(data)
            .then((res) => {
                dispatch({
                    type: UPDATE_FREE_GIFT_SUCCESSFUL,
                    data: res?.data
                })
                return resolve(res)
            })
            .catch(err => {
                dispatch({
                    type: UPDATE_FREE_GIFT_FAILED
                })
                return reject(err)
            })
        })
    }
}

export const GET_FREE_GIFT_NEW = 'GET_FREE_GIFT_NEW';
export const GET_FREE_GIFT_NEW_SUCCESSFUL = 'GET_FREE_GIFT_NEW_SUCCESSFUL';
export const GET_FREE_GIFT_NEW_FAILED = 'GET_FREE_GIFT_NEW_FAILED';

export function getFreeGiftNew(uniqueId){
    return dispatch => {
        dispatch({
            type:GET_FREE_GIFT_NEW
        })
        return new Promise((resolve, reject) => {
            getFreeGiftNewApi(uniqueId)
            .then((res) => {
                dispatch({
                    type: GET_FREE_GIFT_NEW_SUCCESSFUL,
                    data: res?.data
                })
                return resolve(res)
            })
            .catch(err => {
                dispatch({
                    type: GET_FREE_GIFT_NEW_FAILED
                })
                return reject(err)
            })
        })
    }
}

export const UPDATE_FREE_GIFT_NEW = 'UPDATE_FREE_GIFT_NEW';
export const UPDATE_FREE_GIFT_NEW_SUCCESSFUL = 'UPDATE_FREE_GIFT_NEW_SUCCESSFUL';
export const UPDATE_FREE_GIFT_NEW_FAILED = 'UPDATE_FREE_GIFT_NEW_FAILED';

export function updateFreeGiftNew(data){
    return dispatch => {
        dispatch({
            type:UPDATE_FREE_GIFT_NEW
        })
        return new Promise((resolve, reject) => {
            const {uniqueId, dataToUpdate} = data
            updateFreeGiftNewApi(data)
            .then((res) => {
                dispatch({
                    type: UPDATE_FREE_GIFT_NEW_SUCCESSFUL,
                    data: res?.data
                })
                return resolve(res)
            })
            .catch(err => {
                dispatch({
                    type: UPDATE_FREE_GIFT_NEW_FAILED
                })
                return reject(err)
            })
        })
    }
}

export const GET_CONTENTCASH = 'GET_CONTENTCASH';
export const GET_CONTENTCASH_SUCCESSFUL = 'GET_CONTENTCASH_SUCCESSFUL ';
export const GET_CONTENTCASH_FAILED = 'GET_CONTENTCASH_FAILED';

export function getContentCash(uniqueId){
    return dispatch => {
        dispatch({
            type:GET_CONTENTCASH
        })
        return new Promise((resolve, reject) => {
            getContentCashApi(uniqueId)
            .then((res) => {
                dispatch({
                    type: GET_CONTENTCASH_SUCCESSFUL,
                    data: res?.data
                })
                return resolve(res)
            })
            .catch(err => {
                dispatch({
                    type: GET_CONTENTCASH_FAILED
                })
                return reject(err)
            })
        })
    }
}

export const UPDATE_CONTENTCASH = 'UPDATE_CONTENTCASH';
export const UPDATE_CONTENTCASH_SUCCESSFUL = 'UPDATE_CONTENTCASH_SUCCESSFUL ';
export const UPDATE_CONTENTCASH_FAILED = 'UPDATE_CONTENTCASH_FAILED';

export function updateContentCash(data){
    return dispatch => {
        dispatch({
            type:UPDATE_CONTENTCASH
        })
        return new Promise((resolve, reject) => {
            updateContentCashApi(data)
            .then((res) => {
                dispatch({
                    type: UPDATE_CONTENTCASH_SUCCESSFUL,
                    data: res?.data
                })
                return resolve(res)
            })
            .catch(err => {
                dispatch({
                    type: UPDATE_CONTENTCASH_FAILED
                })
                return reject(err)
            })
        })
    }
}


export const GET_CONTENTCASHLESS = 'GET_CONTENTCASHLESS';
export const GET_CONTENTCASHLESS_SUCCESSFUL = 'GET_CONTENTCASHLESS_SUCCESSFUL ';
export const GET_CONTENTCASHLESS_FAILED = 'GET_CONTENTCASHLESS_FAILED';

export function getContentCashless(uniqueId){
    return dispatch => {
        dispatch({
            type:GET_CONTENTCASHLESS
        })
        return new Promise((resolve, reject) => {
            getContentCashlessApi(uniqueId)
            .then((res) => {
                dispatch({
                    type: GET_CONTENTCASHLESS_SUCCESSFUL,
                    data: res?.data
                })
                return resolve(res)
            })
            .catch(err => {
                dispatch({
                    type: GET_CONTENTCASHLESS_FAILED
                })
                return reject(err)
            })
        })
    }
}

export const UPDATE_CONTENTCASHLESS = 'UPDATE_CONTENTCASHLESS';
export const UPDATE_CONTENTCASHLESS_SUCCESSFUL = 'UPDATE_CONTENTCASHLESS_SUCCESSFUL ';
export const UPDATE_CONTENTCASHLESS_FAILED = 'UPDATE_CONTENTCASHLESS_FAILED';

export function updateContentCashless(data){
    return dispatch => {
        dispatch({
            type:UPDATE_CONTENTCASHLESS
        })
        return new Promise((resolve, reject) => {
            updateContentCashlessApi(data)
            .then((res) => {
                dispatch({
                    type: UPDATE_CONTENTCASHLESS_SUCCESSFUL,
                    data: res?.data
                })
                return resolve(res)
            })
            .catch(err => {
                dispatch({
                    type: UPDATE_CONTENTCASHLESS_FAILED
                })
                return reject(err)
            })
        })
    }
}

