import React from "react";
import { Button, Container, Grid, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";


export default function CustomConfirmationModal(props) {
    const { closeModal } = props;

    const { modalData } = useSelector(state => state.common)

    const { confirm = () => { }, cancel = () => { }, title = "Are you sure you want to Logout?", leftBtnTitle = "No", rightBtnTitle = "Yes" } = modalData

    return (
        <div className="confirmation-modal p-3">
            <Container>
                <Grid item xs={12}>
                    <Typography variant="h5" className="txt-center">{title}</Typography>
                    <div className="d-flex jc-space-even pt-1">
                        <Button onClick={() => { cancel(); closeModal(); }}
                            variant="contained" className="secondary-btn btn" color="primary"
                        >
                            {leftBtnTitle}
                        </Button>
                        <Button onClick={() => { confirm(); closeModal(); }}
                            variant="contained"
                            color="primary"
                        >
                            {rightBtnTitle}
                        </Button>
                    </div>
                </Grid>
            </Container>
        </div>
    )
}