import { apiGet, apiPut } from '../../utils';

export function getFreeGiftApi(uniqueId){
    return apiGet(`api/v1/admin/miscellaneous/${uniqueId}`)
}

export function updateFreeGiftApi(data){

    return apiPut(`api/v1/admin/miscellaneous/`, data)
}

export function getFreeGiftNewApi(uniqueId){
    return apiGet(`api/v1/admin/miscellaneous/${uniqueId}`)
}

export function updateFreeGiftNewApi(data){

    return apiPut(`api/v1/admin/miscellaneous/`, data)
}

export function getContentCashApi(uniqueId) {
    return apiGet(`api/v1/admin/miscellaneous/${uniqueId}`)
}

export function updateContentCashApi(data){
    return apiPut(`api/v1/admin/miscellaneous/`, data)
}

export function getContentCashlessApi(uniqueId) {
    return apiGet(`api/v1/admin/miscellaneous/${uniqueId}`)
}

export function updateContentCashlessApi(data) {
    return apiPut(`api/v1/admin/miscellaneous/`, data)

}