import React, { useCallback, useEffect, useMemo, useState } from 'react'
import _ from 'lodash';
import { Box, Typography, Card, CardContent, CardHeader,Grid } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { Button } from '@mui/material';
import * as customerActions from '../redux/actions/customer'
import TableLoader from '../components/common/TableLoader';
import CustomTable from '../components/common/CustomTable';


export default function CustomerDetails(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { id = "" } = useParams();
  const { isLoading } = useSelector((state) => state.customers);
  const items = useSelector((state) => state.customers.allOrders);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(9);

  const debouncedSave = useCallback(_.debounce(filters => hitRequest(filters), 1000), []);


  const hitRequest = (id, filters = {}) => {
    const { start, ...restFilters } = filters;
    dispatch(customerActions.getCustomersOrders(id, { ...restFilters, start }))

  }


  const [filters, setFilters] = useState({
    limit: 9,
    skip: 0,
    start:0
    // page:1,
  })

  console.log("Filters:", filters);


  // const onChangePagination = (e, page) => {
  //   const skip = page * filters.limit
  //   setFilters({ ...filters, skip, page })
  //   hitRequest({ ...filters, skip, page, })
  // }

  // const onChangePagination = (e) => {
  //   const newSkip = filters.skip + filters.limit
  //   const newPage = Math.floor(newSkip / itemsPerPage)
  //   console.log("New Skip:", newSkip);
  //   setFilters({ ...filters, skip:newSkip })
  //   setCurrentPage(newPage);
  //   hitRequest(id, { limit:filters.limit, skip:newSkip })
  // }



  const start = currentPage * itemsPerPage;
  console.log(start, 'startstart')
  // const skip = 0;
  const skip = Math.min(start + itemsPerPage, items?.values?.length);
  const totalPages = Math.ceil(items?.total/itemsPerPage)
  const displayedOrders = items?.values
  console.log(start, skip, ' start skip')
  console.log(displayedOrders, 'displayedOrders')
  console.log(totalPages, 'totalPage')
  console.log(items?.values?.length)

  useEffect(() => {
    dispatch(customerActions.getCustomersOrders(id))
  }, [id])



  const handleGoBack = () => {
    window.history.back();
};


const handleNextPage = () => {
  const nextPage = currentPage + 1;
  const newSkip = nextPage * itemsPerPage;
  setFilters({ ...filters, skip: newSkip });
  setCurrentPage(nextPage);
  const newStart  = nextPage * itemsPerPage
  console.log(newStart, 'tocheckifnewStartispassed')
  hitRequest(id, { limit: itemsPerPage, skip: newSkip, start: newStart });
};

const handlePrevPage = () => {
  const prevPage = currentPage - 1;
  setCurrentPage(prevPage);
  const newSkip = prevPage * itemsPerPage;
  setFilters({ ...filters, skip: newSkip });
  hitRequest(id, { limit: itemsPerPage, skip: newSkip });
};



  return (<>

    <Box className='px-3'>
      {isLoading ? (
        <TableLoader />
      ) : (
        <Box>


                <Grid item xs={12} className="d-flex">
                <div 
                onClick={handleGoBack}
                className="cur-pointer d-flex align-center mb-1 back-btn">
                    <KeyboardBackspaceIcon />
                    <h2 className='m-0'>Back to Customer</h2>
                </div>
            </Grid>


                <Grid item xs={12} sm={6} md={4} xl={4} className="page-heading">
        <h2 className='m-0'>Customer Order History</h2>
      </Grid>
          {items?.values?.length > 0 ? (
         <>

              <Card className='list-points mb-2'>
              <Typography>
             <strong>Name:</strong> {`${(items && items?.values?.[0]?.userData && items?.values?.[0]?.userData?.firstName) || '-'} ${(items && items?.values?.[0]?.userData && items?.values?.[0]?.userData?.lastName) || '-'}`}

              </Typography>
              <Typography>
              <strong> Email:</strong> {items?.values[0 ].email || '-'}
              </Typography>

              <Typography>
              <strong>  Phone:</strong> {items?.values[0].userData?.Phone || '-'}</Typography>
              <Typography>
              <strong> Total orders:</strong> {items?.total || 0} </Typography>
              </Card>
              <div className='customer-order-history'>
              {displayedOrders?.map((item, index) => (
              <Card key={item?.orderId} className='mb-2'>
                    <CardHeader className='card-header' title={`Order ${start + index + 1}`}  />
                    <CardContent className='list-grid'>
                    <Typography>
                      <strong>  Order Id:</strong> {item?.orderId || '-'}
                    </Typography>
                    <Typography>
                    <strong>Products:</strong>
                    <ul>
                      {item?.cartData?.cart?.items.map((item, index) => (
                        <li key={index}>
                       {item?.product.name || '-'}
                      </li>
                       ))
                      }
                      </ul>
                    </Typography>
                    <Typography>
                      <strong> Payment Option:</strong> {item?.cartData?.cart?.paymentOption || '-'}
                    </Typography>
                    <Typography>
                      <strong> Total Amount:</strong> ${(item?.cartData?.cart?.total || '-')}
                    </Typography>
                    <Typography>
                      <strong> Promotions used:</strong> 
                      {item?.promotions ? ( 
                        <>
                      {item?.promotions?.map((promoItem, index) => (
                        <li key={index}>
                          {promoItem.promotionName}
                          <div>
                          <u>Promotion Discount</u>: $ {promoItem.promotionDiscount}
                          </div>
                        </li>)
                      )}
                      </>
                        ):(
                        'None'
                        )}
                    </Typography>
                    <Typography>
                      <strong> Order through:</strong>
                      {item?.isApp ? 'app' : 'Website'}
                    </Typography>
                    <Typography>
                      <strong> Order Date:</strong> {item?.updatedDate ? new Date(item?.updatedDate).toLocaleString() : '-'}
                    </Typography>
                    <Typography style={{color:item?.status==="pending" ? '#F49025' : item?.status==="canceledbydispensary"  ? "#F49025": "red"}}>
                      <strong style={{color:'#555'}}> Status: </strong>
                      {item?.status === "canceledbydispensary" ? "Canceled by dispensary" : (item?.status?.charAt(0).toUpperCase() + item?.status?.slice(1) || '-')}
                    </Typography>
                  

                    </CardContent>
                    
              </Card>
              ))}

            </div>

            <div className="pagination-controls">
                <Button
                disabled={currentPage === 0}
                onClick={handlePrevPage}>
                  Previous
                </Button>
                <Typography variant="body1">Page {currentPage + 1} of {totalPages}</Typography>
  <Button
    disabled={currentPage === totalPages - 1}
    onClick={handleNextPage}
  >
    Next
  </Button>
              </div>
          </>

          ) : (
            <div className="no-data txt-center">
            <img src="/assets/NoOrdersFound.png" alt="" />
            <Typography variant="h5">No orders found</Typography>
        </div>

          )}

        </Box>
      )}
    </Box>

  </>);
}