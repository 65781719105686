import { toast } from "react-toastify"
import { getIntegrationsAPI,getStaticIntegrationsAPI,addIntegrationsAPI,updateIntegrationsAPI, deleteIntegrationsAPI } from "../api/integrations"



export const GET_ALL_STATIC_INTEGRATIONS = 'GET_ALL_STATIC_INTEGRATIONS'
export const GET_ALL_STATIC_INTEGRATIONS_SUCCESS = 'GET_ALL_STATIC_INTEGRATIONS_SUCCESS'
export const GET_ALL_STATIC_INTEGRATIONS_FAILURE = 'GET_ALL_STATIC_INTEGRATIONS_FAILURE'

export function getStaticIntegrations(data){
    return dispatch =>{
        dispatch({
            type: GET_ALL_STATIC_INTEGRATIONS
        })
        return new Promise((resolve, reject) =>{
            getStaticIntegrationsAPI(data)
            .then(res =>{
                dispatch({
                    type: GET_ALL_STATIC_INTEGRATIONS_SUCCESS,
                    payload: res?.data
                })
                return resolve(res)
            })
            .catch((err=>{
                dispatch({
                    type: GET_ALL_STATIC_INTEGRATIONS_FAILURE,  
                })
                toast.error(err)
                return reject(err)
            }))
            
        })
     
}
}

export const GET_ALL_INTEGRATIONS = 'GET_ALL_INTEGRATIONS'
export const GET_ALL_INTEGRATIONS_SUCCESS = 'GET_ALL_INTEGRATIONS_SUCCESS'
export const GET_ALL_INTEGRATIONS_FAILURE ='GET_ALL_INTEGRATIONS_FAILURE'

export function getAllIntegrations(data){
    return dispatch =>{
        dispatch({
            type: GET_ALL_INTEGRATIONS
        })
        return new Promise((resolve,reject) =>{
            getIntegrationsAPI(data)
            .then(res =>{
                dispatch({
                    type: GET_ALL_INTEGRATIONS_SUCCESS,
                    payload:res?.data
                });
                return resolve(res);
            })
            .catch(err =>{
                dispatch({
                    type: GET_ALL_INTEGRATIONS_FAILURE
                })
                toast.error(err)
                return reject(err)
            })
        })
    }
}

export const ADD_INTEGRATION = 'ADD_INTEGRATION'
export const ADD_INTEGRATION_SUCCESS = 'ADD_INTEGRATION_SUCCESS'
export const ADD_INTEGRATION_FAILURE = 'ADD_INTEGRATION_FAILURE'


export function addIntegration(data,id){
    return dispatch =>{
        dispatch({
            type: ADD_INTEGRATION
        })
        return new Promise((resolve, reject) =>{
            addIntegrationsAPI(data,id)
            .then(res =>{
                dispatch({
                    type: ADD_INTEGRATION_SUCCESS,
                    payload:res
                })
                let txt = "Added"
                if (id) {
                    txt = "Updated"
                }
                toast.success(`Integraion ${txt} successfully.`)
                return resolve(res)
                
            })
            .catch((err) => {
                dispatch({
                    type:ADD_INTEGRATION_FAILURE
                })
                toast.error(err)
                return reject(err)
            })
        })
    }
}


export const UPDATE_INTEGRATION = 'UPDATE_INTEGRATION'
export const UPDATE_INTEGRATION_SUCCESS = 'UPDATE_INTEGRATION_SUCCESS'
export const UPDATE_INTEGRATION_FAILURE = 'UPDATE_INTEGRATION_FAILURE'


export function updateIntegration(data){
    return dispatch =>{
        dispatch({
            type: UPDATE_INTEGRATION
        })
        return new Promise((resolve, reject) =>{
            updateIntegrationsAPI(data)
            .then(res =>{
                dispatch({
                    type: UPDATE_INTEGRATION_SUCCESS,
                    payload:{res}
                })
            return resolve(res)
                
            })
            .catch((err) => {
                dispatch({
                    type:UPDATE_INTEGRATION_FAILURE
                })
                toast.error(err)
                return reject(err)
            })
        })
    }
}

export const DELETE_INTEGRATION = 'DELETE_INTEGRATION';
export const DELETE_INTEGRATION_SUCCESSFULL = 'DELETE_INTEGRATION_SUCCESSFULL';
export const DELETE_INTEGRATION_FAILED = 'DELETE_INTEGRATION_FAILED';

export function deleteIntegration(data) {
    return dispatch => {
        dispatch({
            type: DELETE_INTEGRATION
        })
        return new Promise((resolve, reject) => {
            deleteIntegrationsAPI(data)
                .then((res) => {
                    dispatch({
                        type: DELETE_INTEGRATION_SUCCESSFULL,
                        data: res?.data
                    })
                    toast.success("Integration Deleted successfully.")
                    return resolve(res)
                })
                .catch(err => {
                    dispatch({
                        type: DELETE_INTEGRATION_FAILED
                    })
                    toast.error(err)
                    return reject(err)
                })
        })
    }
}

// export const GET_DETAILS_INTEGRATION = 'GET_DEATAILS_INTEGRATION'
// export const  GET_DETAILS_INTEGRATION_SUCCESS = ' GET_DETAILS_INTEGRATION_SUCCESS'
// export const  GET_DETAILS_INTEGRATION_FAILURE = ' GET_DETAILS_INTEGRATION_FAILURE'


// export function getDetailsIntegration(integrationId,data){
//     return dispatch =>{
//         dispatch({
//             type: GET_DETAILS_INTEGRATION
//         })
//         return new Promise((resolve, reject) =>{
//             getDetailsIntegrationsAPI(integrationId,data)
//             .then(res =>{
//                 dispatch({
//                     type: GET_DETAILS_INTEGRATION_SUCCESS,
//                     payload:res?.data
//                 })
//             return resolve(res)
                
//             })
//             .catch((err) => {
//                 dispatch({
//                     type:GET_DETAILS_INTEGRATION_FAILURE
//                 })
//                 toast.error(err)
//                 return reject(err)
//             })
//         })
//     }
// }
