import React, { useMemo } from 'react';

import {  Box } from '@material-ui/core';
import {  useNavigate } from 'react-router-dom';
import { useDispatch ,useSelector } from 'react-redux';
import { GREENSTONE_BASE_URL,WEST_COAST_BASE_URL,ROYAL_BASE_URL,HYPERWOLF_BASE_URL, AccessTokenName, SelectedShop } from '../../../constants';
import CustomTable from '../../../components/common/CustomTable';
import EditIcon from '../../../components/common/Icons/EditIcon';
import ViewIcon from '../../../components/common/Icons/ViewIcon';
import TableLoader from '../../../components/common/TableLoader';
import { flowerTypeImg } from '../../../utils';



function AllProducts(props) {
  const { onChangePagination = () => { }, filters = {}, onChangeRowsPerPage = () => { }, searchTerm = '', isActiveTab = true, currentPage, handleNextPage,productSlug, handlePrevPage, } = props
  const navigate = useNavigate()
  const { allProducts={}, isLoading } = useSelector(state => state.products)
  const { values = [], total = 0 } = allProducts || {}

  const totalPages = useMemo(() => {
    return Math.ceil(total / filters.limit) > 1  ? Math.ceil(total / filters.limit) : 1

  }, [total, filters.limit]);

  const getBaseUrl = () => {
    const userData = JSON.parse(localStorage.getItem(AccessTokenName));
    const selectedShop = JSON.parse(localStorage.getItem(SelectedShop));
    const appname = JSON.parse(localStorage.getItem('app'));
    const baseUrl = userData?.projectData?.webUrl
    const getShopId = userData?.shops.find(a => ( a.shopId === selectedShop ) )
    const slugName = getShopId?.slug
    const slash = '/'
    const webURL = baseUrl + slash
    const canaURL = baseUrl + slash + slugName + slash

    if (appname === 'cannamobile' || appname === "multishop") {
      return canaURL;
    }
    return webURL;
  };

  

  const columns = [
    {
      name: 'S.no',
      key: 's_no',
    },
    {
      name: 'Product Name',
      key: 'adminProductName'
    },
    {
      name: 'Website Product Name',
      key: 'websiteProductName',
    },
    
    {
      name: 'Brands',
      key: 'brand',
    },

    {
      name: 'Category',
      key: 'category',
    },
    {
      name: 'Sub Category',
      key: 'category',
    },
    {
      name: 'Type ',
      key: 'flowerType',
    },
    {
      name: 'Price ',
      key: 'unitPrice',
    },
    {
      name: 'Status',
      key: 'active',
    },
    {
      name: 'Actions',
      key: 'actions'
    },

  ]

  const parseRowData = useMemo(() => {
    return (
      values?.map((item, idx) => {
        const status = item?.showInWidget ? 'Active' : 'In-Active';
        const statusClassName = item?.showInWidget ? 'complete-badge' : 'decline-badge';
        return {
          ...item,
          s_no: filters.skip + idx + 1,
          brand: item?.brand?.name || '',
          category: item?.category?.name || '',
          unitPrice: `$${item?.unitPrice || 0}`,
          active: <span className={`badge-status ${statusClassName}`}>{status}</span>,
          flowerType: (
            <>
              <span style={{ display: 'inline-block' }}>{item?.flowerType}</span>
              <span className="flowertype-list" style={{ display: 'inline-block' }}>
                {flowerTypeImg(item?.flowerType)}
              </span>
            </>
          ),
          actions: (
            <Box>
              <div className="d-flex">
              <ViewIcon onClick={() => { window.open(`${getBaseUrl()}shop/product/${item?.productSlug}`, '_blank') }} />
                <EditIcon onClick={() => navigate(`/dashboard/Manage_products/productDetails/${item.id}`)} />
              </div>
            </Box>
          ),
        };
      }) || []
    );
  }, [values]);
  
  return (
    <>
      {
        isLoading ? <TableLoader  /> : <CustomTable
          tableHeading={columns}
          rowsData={parseRowData} 
          currentPage={currentPage}
          totalPages={totalPages}
          skip={filters.skip}
          handlePrevPage={handlePrevPage}
          handleNextPage={handleNextPage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          limit={filters.limit}
          onChangePagination={onChangePagination}
        />
      }

    </>
  );
}


export default AllProducts

