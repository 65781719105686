import { apiGet } from "../../utils";

export function getAllOrdersAPI(data) {
    const { limit = 10, skip = 0, searchTerm = '', sortBy = 'sale', categoryId = '', productTags = '', brandId = '',cartStatus="" } = data || {}
    const newData = { limit, skip, searchTerm, sortBy, categoryId, productTags, brandId,cartStatus }
    return apiGet(`api/v1/admin/cart/getorders`,newData);
}

export function getOrderAPI(id,data){
    const { limit=10, skip=0 } = data || {}
    const newData = { limit, skip }
    
    return apiGet(`api/v1/admin/cart/order/${id}`, newData);
}