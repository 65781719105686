import { useState, useEffect } from 'react';
import ContentLoader from 'react-content-loader';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography,Select,MenuItem,FormControl,InputLabel } from '@mui/material';
import moment from 'moment';
import TableLoader from '../components/common/TableLoader';
import * as dashBoardActions from '../redux/actions/dashboard';
import SvgColor from '../components/svg-color';

import {
  AppCurrentVisits,
  AppRevenue,
  AppWidgetSummary,
  AppCustomerOnBoard,
  AppProductSold,
} from '../sections/@dashboard/app';
import dashboard from '../redux/reducers/dashboard';
import { getData } from '../utils';

export default function DashboardAppPage() {

  const theme = useTheme();
  const [dashboardData, setDashboardData] = useState({});
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const dashboardStore = useSelector((state) => state.dashboard);
  const [selectedYear, setSelectedYear] = useState('2023');
  const userData = JSON.parse(getData('ankar-admin'));


  useEffect(() => {
    dispatch(dashBoardActions.getDashBoardData()).then(() => setIsLoading(false));
  }, [dashboardData]);

  useEffect(() => {
    if (dashboardStore && dashboardStore.data) {
      setDashboardData(dashboardStore.data);
      setIsLoading(false);
    }
  }, [dashboardStore]);

  const handleYearChange = (event) => {
    const selectedYearValue = event.target.value;
    setSelectedYear(selectedYearValue);
    dispatch(dashBoardActions.getDashBoardData(null, selectedYearValue));
  };
  const {
    totalRevenue = 0,
    totalProductSold = 0,
    totalOrder = 0,
    onBoardedDetails = {},
  } = dashboardStore.dashboardData || {};

  if(userData.permissions?.dashboard === true){
    return (
      <div className={`dashboard-page ${isLoading ? 'loading' : ''}`}>
        <Helmet>
          <title>  Ankar Admin </title>
        </Helmet>
          <Grid container className=''>
            <Grid item xs={12} sm={12} md={12} xl={12} className="page-heading">
              <h2 className='m-0'>Dashboard</h2>
            </Grid>
          </Grid>

          <div  className="d-flex jc-content-end mb-2 algn-itm-ctr revenue-year-box">
          <InputLabel id="year-label" className='revenue-year'>  Year by Filter: </InputLabel>
              <FormControl className='drop-down-filter' variant="standard">
                  <Select labelId="year-label" id="year-select" className='search-btn-drop-down-filter' value={selectedYear} onChange={handleYearChange}>
                    <MenuItem value={moment().subtract(2,'years').year()}>{moment().subtract(2,'years').year()}</MenuItem>
                    <MenuItem value={moment().subtract(1,'years').year()}>{moment().subtract(1,'years').year()}</MenuItem>
                    <MenuItem value={moment().year()}>{moment().year()}</MenuItem>
                  </Select>
              </FormControl>

          </div>
          <Grid container spacing={3}>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Total Revenue Generated"
              dashboardStore= {`$${totalRevenue}`}
              icon={<svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5.91384 24.4216C6.93751 25.3373 8.09838 26.0634 9.34251 26.6092L15.8853 22.5006C17.2389 21.6466 19.0519 21.8485 20.185 22.9878L22.385 25.1878C25.6826 22.7566 27.75 18.8669 27.75 14.6459C27.75 7.42006 21.8716 1.54169 14.6458 1.54169C7.42005 1.54169 1.54167 7.42006 1.54167 14.6459C1.54167 18.3751 3.13421 21.9379 5.91384 24.4216ZM15.2317 15.8021H14.06C12.2578 15.8021 10.7917 14.336 10.7917 12.5338C10.7917 10.9135 11.9603 9.57994 13.4896 9.3086V8.09377C13.4896 7.45552 14.0076 6.93752 14.6458 6.93752C15.2841 6.93752 15.8021 7.45552 15.8021 8.09377V9.25002H17.3438C17.982 9.25002 18.5 9.76802 18.5 10.4063C18.5 11.0445 17.982 11.5625 17.3438 11.5625H14.06C13.5328 11.5625 13.1042 11.9911 13.1042 12.5184C13.1042 13.061 13.5328 13.4896 14.06 13.4896H15.2317C17.0339 13.4896 18.5 14.9557 18.5 16.7579C18.5 18.3782 17.3314 19.7118 15.8021 19.9831V21.1964C15.8021 21.8346 15.2841 22.3526 14.6458 22.3526C14.0076 22.3526 13.4896 21.8346 13.4896 21.1964V20.0417H11.9479C11.3097 20.0417 10.7917 19.5237 10.7917 18.8854C10.7917 18.2472 11.3097 17.7292 11.9479 17.7292H15.2317C15.7589 17.7292 16.1875 17.3006 16.1875 16.7734C16.1875 16.2307 15.7589 15.8021 15.2317 15.8021Z" fill="black"/>
              <path d="M35.4583 18.5H29.2917C28.6688 18.5 28.1046 18.8762 27.8672 19.4512C27.6282 20.0278 27.7608 20.6907 28.2017 21.1316L30.1951 23.125L23.8958 29.4243L18.8191 24.3475C18.315 23.8403 17.5226 23.7525 16.9182 24.1271L0.730746 34.1479C0.00616263 34.5965 -0.215837 35.5462 0.231246 36.2692C0.522621 36.741 1.02675 37 1.5432 37C1.8207 37 2.09975 36.926 2.35258 36.7688L17.501 27.3908L22.8043 32.6941C23.4071 33.2969 24.3815 33.2969 24.9842 32.6941L32.375 25.3049L34.3684 27.2983C34.6628 27.5928 35.0575 27.75 35.4583 27.75C35.6572 27.75 35.8576 27.7115 36.0488 27.6328C36.6254 27.3939 37 26.8312 37 26.2083V20.0417C37 19.1907 36.3109 18.5 35.4583 18.5Z" fill="black"/>
              </svg>
              }
              className="total-revenue-box-bg"
              />
          </Grid>


          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Customer Onboarded"
              dashboardStore={onBoardedDetails?.totalOnboarded}
              color="info"
              icon={<svg width="41" height="36" viewBox="0 0 41 36" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M20.5 13.9336C24.3477 13.9336 27.4668 10.8145 27.4668 6.9668C27.4668 3.11914 24.3477 0 20.5 0C16.6523 0 13.5332 3.11914 13.5332 6.9668C13.5332 10.8145 16.6523 13.9336 20.5 13.9336Z" fill="black"/>
              <path d="M34.5938 13.9335C37.0262 13.9335 38.998 11.9617 38.998 9.52924C38.998 7.09681 37.0262 5.12494 34.5938 5.12494C32.1613 5.12494 30.1895 7.09681 30.1895 9.52924C30.1895 11.9617 32.1613 13.9335 34.5938 13.9335Z" fill="black"/>
              <path d="M6.40625 13.9335C8.83868 13.9335 10.8105 11.9617 10.8105 9.52924C10.8105 7.09681 8.83868 5.12494 6.40625 5.12494C3.97382 5.12494 2.00195 7.09681 2.00195 9.52924C2.00195 11.9617 3.97382 13.9335 6.40625 13.9335Z" fill="black"/>
              <path d="M10.7457 17.8887C9.01199 16.4682 7.4419 16.6562 5.43731 16.6562C2.43918 16.6562 0 19.081 0 22.0607V30.8061C0 32.1001 1.05623 33.1523 2.3551 33.1523C7.96265 33.1523 7.28711 33.2538 7.28711 32.9105C7.28711 26.7136 6.55311 22.1691 10.7457 17.8887Z" fill="black"/>
              <path d="M22.4067 16.6882C18.9053 16.3961 15.862 16.6915 13.2369 18.8583C8.84407 22.3769 9.68946 27.1146 9.68946 32.9104C9.68946 34.4438 10.9371 35.7147 12.4938 35.7147C29.3968 35.7147 30.0696 36.26 31.0719 34.0403C31.4006 33.2897 31.3106 33.5282 31.3106 26.3472C31.3106 20.6436 26.3719 16.6882 22.4067 16.6882Z" fill="black"/>
              <path d="M35.5627 16.6562C33.5471 16.6562 31.9857 16.4701 30.2543 17.8886C34.4156 22.1372 33.7129 26.3716 33.7129 32.9104C33.7129 33.2559 33.1521 33.1523 38.5608 33.1523C39.9061 33.1523 41 32.0624 41 30.7227V22.0606C41 19.0809 38.5608 16.6562 35.5627 16.6562Z" fill="black"/>
              </svg>
              }
              className="totalOnboarded-box-bg"

            />

          </Grid>



          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Total Orders"
              dashboardStore={totalOrder}
              color="warning"
              icon={<svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M27.5834 0.98335C27.2325 0.376797 26.5794 0 25.8792 0H18.5607V8.65683H32.0215L27.5834 0.98335Z" fill="black"/>
              <path d="M16.5092 0H9.12156C8.41992 0 7.76688 0.37748 7.41606 0.9854L2.99423 8.65683H16.5091V0H16.5092Z" fill="black"/>
              <path d="M2.25977 10.7084V32.5881C2.25977 33.9182 3.3423 35 4.67237 35H30.3276C31.6577 35 32.7403 33.9182 32.7403 32.5881V10.7084H2.25977ZM22.1563 19.9095L16.851 25.2148C16.6506 25.4152 16.388 25.5158 16.1254 25.5158C15.8629 25.5158 15.6002 25.4152 15.4006 25.2148L12.9134 22.7283C12.5134 22.3283 12.5134 21.6786 12.9134 21.2779C13.3142 20.8772 13.9638 20.8772 14.3646 21.2779L16.1255 23.0395L20.7059 18.4591C21.1067 18.0584 21.7563 18.0584 22.1564 18.4591C22.5571 18.8598 22.5571 19.5088 22.1563 19.9095Z" fill="black"/>
              </svg>
              }
              className="totalOrder-box-bg"
            />
          </Grid>


          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Product Sold"
              dashboardStore={totalProductSold}
              color="error"
              icon={<svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path   d="M5.29387 25.4746L11.249 35.997C11.537 36.5059 11.3563 37.1577 10.8475 37.4457L8.82187 38.5921C8.31306 38.88 7.66122 38.6994 7.37326 38.1905L1.41817 27.668C1.13029 27.1592 1.31095 26.5074 1.81968 26.2194L3.84526 25.073C4.35408 24.7851 5.00591 24.9658 5.29387 25.4746ZM26.657 2.38113L29.57 4.06293C26.4588 5.85917 23.3476 7.6554 20.2365 9.45163V13.4644C20.2365 13.6114 20.3094 13.7377 20.4367 13.8112L22.3427 14.9116C22.6104 15.0661 22.9433 14.874 22.9433 14.5649V11.0144L32.2768 5.62574L35.1898 7.30754C35.4444 7.45456 35.5901 7.70697 35.5901 8.00101V17.8537C35.5901 18.1478 35.4444 18.4002 35.1898 18.5472L26.657 23.4735C26.4024 23.6206 26.1109 23.6206 25.8563 23.4735L17.3236 18.5472C17.069 18.4002 16.9232 18.1478 16.9232 17.8537V8.00101C16.9232 7.70697 17.069 7.45456 17.3236 7.30754L25.8563 2.38121C26.1109 2.23419 26.4023 2.23419 26.657 2.38113ZM7.35444 26.5142L11.0872 33.1097L11.6238 32.7999C11.776 32.7125 11.9491 32.6948 12.1063 32.7367L21.9654 35.3764C23.4228 35.7671 24.2577 35.5114 25.5461 34.8546L39.0929 26.5131C39.7935 26.0819 39.8859 24.9081 39.4707 24.2392C38.5606 22.7729 36.0575 23.98 35.0072 24.5917L27.5175 28.954C25.851 29.9245 24.6306 29.7554 22.8261 29.462L17.8086 28.1366C17.4673 28.0454 17.2645 27.6947 17.3557 27.3533C17.4469 27.012 17.7976 26.8092 18.139 26.9004C19.7598 27.3347 21.3661 27.8566 23.0094 28.1962C23.8556 28.2999 24.5549 28.0293 24.7895 27.1536C25.0029 26.3569 24.501 25.6054 23.748 25.3453C21.5002 24.9341 19.3172 24.1449 17.601 23.5244C15.1061 22.6223 14.4342 22.4153 11.975 23.839L7.35444 26.5142ZM3.92838 27.0518C3.5332 27.1097 3.25965 27.4769 3.31738 27.8721C3.37512 28.2673 3.74236 28.5409 4.13762 28.4831C4.53281 28.4253 4.80636 28.0581 4.74862 27.6628C4.6908 27.2676 4.32357 26.9941 3.92838 27.0518Z" fill="black"/>
              </svg>}
              className="totalProductSold-box-bg"
            />
          </Grid>

          </Grid>

          {isLoading && <TableLoader rowCount={4} />}

          <Grid container spacing={3} className="dash-grid-sec">
          {!isLoading && (
            <Grid item xs={12} md={6} lg={8} className='mt-2'>
              <AppRevenue
                dashboardStore={dashboardStore}
              />
            </Grid>

                  )}

          {!isLoading && (
            <Grid item xs={12} md={6} lg={4} className='mt-2'>
              <AppCurrentVisits
                title="Current Visits"
                chartData={[
                  { label: 'InActive Customers', value: dashboardStore?.dashboardData?.inactiveMembersDetails?.inactiveCustomers },
                  { label: 'Active Customers', value: dashboardStore?.dashboardData?.inactiveMembersDetails?.totalactiveCustomers },
                  // { label: 'InActive Customers', value: 100 },
                  // { label: 'Active Customers', value: 25 },
                  ]}
                Colors={[
                  "var(--graph-secondary)",
                  " var(--graph-primary)",
                ]}
              />
            </Grid>
            )}

              {!isLoading && (
            <Grid item xs={12} md={6} lg={8} className='mt-2'>
              <AppProductSold
                title="Product Sold"
                dashboardStore={dashboardStore}
                />
            </Grid>
                )}
            {!isLoading && (
            <Grid item xs={12} md={6} lg={4} className='mt-2'>
              <AppCustomerOnBoard
                dashboardStore={dashboardStore}
              />
            </Grid>
            )}
          </Grid>

      </div>
    );
  }
  return(
      <>
      <div className={`dashboard-page ${isLoading ? 'loading' : ''}`}>
      <Helmet>
        <title>  Ankar Admin </title>
      </Helmet>
        <Grid container className=''>
          <Grid item xs={12} sm={12} md={12} xl={12} className="page-heading">
            <h2 className='m-0'>Dashboard</h2>
          </Grid>
        </Grid>
        <p>You do not have permission to view Dashboard</p>
      </div>
      </>
    )
  
}