import { useMemo, useState, useCallback, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { Helmet } from 'react-helmet-async';
import SearchIcon from '@material-ui/icons/Search';
import queryString from 'query-string';

import { Button, Grid, InputAdornment, } from '@mui/material';
import _, { filter } from 'lodash';
import * as productsActions from "../redux/actions/products";
import * as commonActions from '../redux/actions/common'
import TableLoader from '../components/common/TableLoader';
import CustomInput from '../components/common/CustomInput';
import CustomTable from '../components/common/CustomTable';
import EditIcon from '../components/common/Icons/EditIcon';
import DeleteIcon from '../components/common/Icons/DeleteIcon';



const columns = [
  {
    name: 'S.no',
    key: 's_no',
  },
  {
    name: 'Brand Name',
    key: 'brandName',
  },
  {
    name: 'Description',
    key: 'description',
  },
  {
    name: 'Actions',
    key: 'actions'
  },

]

export default function BrandPage() {
  const [currentPage, setCurrentPage] = useState(0);
  const { allBmwBrands: { values = [], total = 0 }, isLoading } = useSelector(state => state.products, shallowEqual)
  const [isActiveTab, setIsActiveTab] = useState(true)

  const debouncedSave = useCallback(_.debounce(filters => hitRequest(filters), 1000), []);

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [search, setSearch] = useState("")
  const [filters, setFilters] = useState({
    limit: 10,
    skip: 0,
    page: 0,
  })

  const onChangePagination = (e, page) => {
    const skip = page * filters.limit
 setFilters({ ...filters, skip, page,  })
 hitRequest({ ...filters, skip, page, searchTerm: search })
 
   }
  const onChangeRowsPerPage = (e) => {
    const newFilters = { limit: parseInt(e, 10), page: 0, skip: 0 }
    setFilters(newFilters)
    setCurrentPage(0)
    hitRequest({ ...newFilters, searchTerm: search, })
  }

  const totalPages = useMemo(() => {
    return Math.ceil(total / filters.limit) > 1 ? Math.ceil(total / filters.limit) : 1

  }, [total, filters.limit]);


  const handlePrevPage = (prevPage) => {
    setCurrentPage(prevPage)
    const skip = prevPage * filters.limit
    setFilters({ ...filters, skip, page: prevPage })
    hitRequest({ ...filters, skip, page: prevPage, searchTerm: search, })
  };
  const handleNextPage = (nextPage) => {
    setCurrentPage(nextPage)
    const skip = nextPage * filters.limit
    setFilters({ ...filters, skip, page: nextPage })
    hitRequest({ ...filters, skip, page: nextPage, searchTerm: search, })
  };


  const hitRequest = (filters = {}) => {
    dispatch(productsActions.getAllBmwBrands({ ...filters, sortBy: 'active' }))
  }

  const handleConfirmation = (id) => {
    dispatch(commonActions.openCustomModalDialog({
      title: "Are you sure you want to delete?",
      confirm: () => { handleDelete(id) },
      cancel: () => { }
    }, "ConfirmationModal"))

  }

  const handleEdit = (item) => {
    dispatch(commonActions.openCustomModalDialog({
      title: "Edit",
      data: item
    }, "AddBrandModal"))
  }

  const handleDelete = (item) => {
    dispatch(productsActions.deleteBrandFromBmw(item.brandId)).then(() => {
      dispatch(productsActions.getAllBmwBrands())
    }).catch(() => { })
  }

  const handleChange = (e) => {
    const { value } = e.target
    if (value.length === 1 && value[0] === ' ') {
      setSearch('');
      return;
    }
    const newFilters = { limit: 10, skip: 0, page: 0, searchTerm: value }
    setSearch(value);
    setFilters({ limit: 10, skip: 0, page: 0 })
    setCurrentPage(0)
    debouncedSave(newFilters);
  }

  const parseRowData = useMemo(() => {
    return values?.map(((brand, idx) => {
      return {
        ...brand,
        s_no: filters.skip + (idx + 1),
        actions: <Box>
          <EditIcon onClick={() => handleEdit(brand)} />
          <DeleteIcon onClick={() => handleConfirmation(brand)} />
        </Box>
      }
    }))
  }, [values])

  useEffect(() => {
    let newFilters = {}
    let searchT = ''
    let newActive = true
    if (location.search) {
      const { limit = 10, skip = 0, page = 0, searchTerm = '', active = true } = queryString.parse(location.search);
      searchT = searchTerm
      newActive = active === "true"
      newFilters = {
        limit: Number(limit),
        skip: Number(skip),
        page: Number(page)
      }
      setIsActiveTab(newActive)
      setFilters(newFilters)
      setSearch(searchT)
    }
    hitRequest(filters)
    dispatch(productsActions.getAllBrandsByProducts())

  }, [])

  useEffect(() => {
    
    location.search = queryString.stringify({ ...filters, searchTerm: search, cartType: isActiveTab });
    window.onpageshow = function(event) {
      if (event.persisted) {
          window.location.reload();
      }
  };

  }, [filters, search, isActiveTab])

  

  return (
    <>
      <Helmet>
        <title>Brands | Ankar Admin </title>
      </Helmet>
      <Grid item xs={12} className="manage-product-section">
        <Grid container className='pb-3'>
          <Grid item xs={12} sm={6} md={4} xl={4} className="page-heading">
            <h2 className='m-0'>Brand</h2>
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={12} className="d-flex p-0 search-section jc-content-end">
            <div className='search-input'>
              <CustomInput
                label="Search"
                name="search"
                placeholder="Search"
                onChange={handleChange}
                value={search}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  // endAdornment: search ? (
                  //   <InputAdornment position="end" className="cur-pointer">
                  //     <ClearIcon onClick={onClickClearInputField} />
                  //   </InputAdornment>
                  // ) : null,
                }}
              />
            </div>
            <div className="manage-top-tabs ml-3">
              <Button variant="contained" onClick={() => dispatch(commonActions.openCustomModalDialog({}, 'AddBrandModal'))}>
                Add Brand
              </Button>
            </div>
          </Grid>

        </Grid>
        {
          isLoading ?  <TableLoader /> : <CustomTable
            tableHeading={columns}
            rowsData={parseRowData}
            currentPage={currentPage}
            totalPages={totalPages}
            skip={filters.skip}
            handlePrevPage={handlePrevPage}
            handleNextPage={handleNextPage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            limit={filters.limit}
            onChangePagination={onChangePagination}
          />
        }


      </Grid>
    </>
  );
}