import React from 'react';
import { useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import ManagePromotions from './ManagePromotions';

function PromotionsPage() {
    const classes = useStyles();


    return (
        <Grid container component="main" className={`${classes.root} manage-product-section`}>
            <ManagePromotions />
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },

}));

export default PromotionsPage