import { toast } from 'react-toastify';
import { getAllOrdersAPI,getOrderAPI } from '../api/orders';


export const GET_ORDERS_REQUEST = 'GET_ORDERS_REQUEST';
export const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS';
export const GET_ORDERS_FAILED = 'GET_ORDERS_FAILED';

export function getAllOrders(data) {
    return dispatch => {
        dispatch({
            type: GET_ORDERS_REQUEST
        })
        return new Promise((resolve, reject) => {
            getAllOrdersAPI(data)
                .then((res) => {
                    dispatch({
                        type: GET_ORDERS_SUCCESS,
                        payload: res?.data
                    })
                    return resolve(res)
                })
                .catch(err => {
                    dispatch({
                        type: GET_ORDERS_FAILED
                    })
                    toast.error(err)
                    return reject(err)
                })
        })
    }
}

export const GET_ORDERS_DETAILS_REQUEST = 'GET_ORDERS_DETAILS_REQUEST';
export const GET_ORDERS_DETAILS_SUCCESS = 'GET_ORDERS_DETAILS_SUCCESS';
export const GET_ORDERS_DETAILS_FAILED = 'GET_ORDERS_DETAILS_FAILED';


export function getOrders(data) {
    return dispatch =>{
        dispatch({
            type: GET_ORDERS_DETAILS_REQUEST,
        })

        return new Promise((resolve, reject) =>{
            getOrderAPI(data)
            .then(res =>{
                dispatch({
                    type: GET_ORDERS_DETAILS_SUCCESS,
                    payload: res?.data
                })
                return resolve(res)
            })
            .catch(err =>{
                dispatch({
                    type: GET_ORDERS_DETAILS_FAILED,
                })
                toast.error(err)
                return reject(err)
            })

        })
    }
}