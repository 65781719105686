import { useMemo, useState, useCallback, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import SearchIcon from '@material-ui/icons/Search';
import queryString from 'query-string';
import { Button, Grid, InputAdornment, } from '@mui/material';
import { Box } from '@material-ui/core';
import _ from 'lodash';
import * as commonActions from '../redux/actions/common'
import * as notificationActions from '../redux/actions/notifications';
import EditIcon from '../components/common/Icons/EditIcon';
import CustomInput from '../components/common/CustomInput';
import CustomTable from '../components/common/CustomTable';
import TableLoader from '../components/common/TableLoader';
import DeleteIcon from '../components/common/Icons/DeleteIcon';




const columns = [
  {
    name: 'S.no',
    key: 's_no',
  },
  // {
  //   name: 'Name',
  //   key: 'name',
  // },
  {
    name: 'Title',
    key: 'title',
  },
  {
    name: 'Schedule Time',
    key: 'scheduleTime',  
  },
  {
    name: 'Description',
    key: 'body'
  },
  {
    name: 'Status',
    key:'status'
  },
  {
    name: 'Actions',
    key: 'actions'
  },

]
export default function NotificationsPage() {
  const [currentPage, setCurrentPage] = useState(0);
  const { allNotifications: { values = [], total = 0 }, isLoading } = useSelector(state => state.notifications, shallowEqual)
  const debouncedSave = useCallback(_.debounce(filters => hitRequest(filters), 1000), []);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [search, setSearch] = useState("")
  const [filters, setFilters] = useState({
    limit: 10,
    skip: 0,
    page: 0,
  })
  
  const handleConfirmation = (id) => {
    console.log(id, 'confirmdelete')
    dispatch(commonActions.openCustomModalDialog({
      title: "Are you sure you want to delete?",
      confirm: () => { handleDelete(id) },
      cancel: () => { }
    }, "ConfirmationModal"))
  }

  const handleDelete = (id) => {
    console.log(id, 'ishiodsh')
    dispatch(notificationActions.deleteNotification(id))
  }


  const onChangePagination = (e, page) => {
    const skip = page * filters.limit
 setFilters({ ...filters, skip, page,  })
 hitRequest({ ...filters, skip, page, searchTerm: search })
 
   }
  const onChangeRowsPerPage = (e) => {
    const newFilters = { limit: parseInt(e, 10), page: 0, skip: 0 }
    setFilters(newFilters)
    setCurrentPage(0)
    hitRequest({ ...newFilters, searchTerm: search, })
  }

  const totalPages = useMemo(() => {
    return Math.ceil(total / filters.limit) > 1 ? Math.ceil(total / filters.limit) : 1

  }, [total, filters.limit]);

  const handlePrevPage = (prevPage) => {
    setCurrentPage(prevPage)
    const skip = prevPage * filters.limit
    setFilters({ ...filters, skip, page: prevPage })
    hitRequest({ ...filters, skip, page: prevPage, searchTerm: search, })
  };
  const handleNextPage = (nextPage) => {
    setCurrentPage(nextPage)
    const skip = nextPage * filters.limit
    setFilters({ ...filters, skip, page: nextPage })
    hitRequest({ ...filters, skip, page: nextPage, searchTerm: search, })
  };

  const handleChange = (e) => {
    const { value } = e.target;
  
    if (value.length === 1 && value[0] === ' ') {

      setSearch('');
      return;
    }
  
    const newFilters = { limit: 10, skip: 0, page: 0, searchTerm: value };
    setSearch(value);
    setFilters({ limit: 10, skip: 0, page: 0 });
    setCurrentPage(0);
    debouncedSave(newFilters);
  };
  


  const parseRowData = useMemo(() => {
    return values?.map((notifications, idx) => {
      const date = new Date(notifications?.scheduleTime * 1000).toLocaleString();
      console.log(notifications?._id, 'sjkshdk')
      return {
        ...notifications,
        s_no: filters.skip + (idx + 1),
        scheduleTime: date,
        actions: (
          <Box>
            <EditIcon onClick={() => navigate(`/dashboard/notifications/editNotification/${notifications?._id}`)} />
            <DeleteIcon onClick={() => handleConfirmation(notifications?._id)} />
          </Box>
        )
      };
    }) || [];
  }, [values]);
  



  useEffect(() => {
    let newFilters = {}
    let searchT = ''
    // let newActive = true
    if (location.search) {
      const { limit = 10, skip = 0, page = 0, searchTerm = '',  } = queryString.parse(location.search);
      searchT = searchTerm
      // newActive = active === "true"
      newFilters = {
        limit: Number(limit),
        skip: Number(skip),
        page: Number(page)
      }
      //   setIsActiveTab(newActive)
      setFilters(newFilters)
      setSearch(searchT)
      setCurrentPage(skip / limit)
    }
    hitRequest({ ...newFilters, searchTerm: searchT,  })

  }, [])

  useEffect(() => {
    location.search = queryString.stringify({ ...filters, searchTerm: search, });
    navigate({
      pathname: '/dashboard/notifications',
      search: location.search
    })
    //   window.onpageshow = function(event) {
  //     if (event.persisted) {
  //         window.location.reload();
  //     }
  // };
  }, [filters, search,])


  const hitRequest = (filters = {}) => {
    dispatch(notificationActions.getAllNotifications({ ...filters, }))

  }
  return (
    <>
      <Helmet>
        <title>Notification | Ankar Admin </title>
      </Helmet>
      <Grid item xs={12} className="manage-product-section">
        <Grid container className='pb-3'>
          <Grid item xs={12} sm={6} md={4} xl={4} className="page-heading">
            <h2 className='m-0'>Notifications</h2>
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={12} className="d-flex p-0 search-section jc-content-end">
            <div className='search-input'>
              <CustomInput
                label="Search"
                name="search"
                placeholder="Search"
                onChange={handleChange}
                value={search}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                 
                }}
              />
            </div>
            <div className="manage-top-tabs ml-3">
              <Button variant="contained" onClick={() => navigate('/dashboard/notifications/addNotification')}>
                Add Notification
              </Button>
            </div>
          </Grid>

        </Grid>
        {
          isLoading ? <TableLoader /> : <CustomTable
            tableHeading={columns}
            rowsData={parseRowData}
            currentPage={currentPage}
            totalPages={totalPages}
            skip={filters.skip}
            handlePrevPage={handlePrevPage}
            handleNextPage={handleNextPage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            limit={filters.limit}
            onChangePagination={onChangePagination}
          />
        }
      </Grid>
    </>
  );
}

