import { apiPost,apiGet } from '../../utils';

export function loginAPI(data) {
    return apiPost('api/v1/auth/login', data);
}


export function getLastSyncDateAPI(data) {
    return apiGet('api/v1/admin/products/getLastSync',data);
}
