import { useMemo, useState, useCallback, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string'
import { Helmet } from 'react-helmet-async';
import {  Button, TextField, Grid, InputAdornment, } from '@mui/material';
import _ from 'lodash';

import * as deliveryHoursActions from "../redux/actions/deliveryHours";
import * as commonActions from '../redux/actions/common'
import EditIcon from '../components/common/Icons/EditIcon';
import DeleteIcon from '../components/common/Icons/DeleteIcon';
import TableLoader from '../components/common/TableLoader';
import CustomTable from '../components/common/CustomTable';
import { getData } from '../utils';



export default function ManageDeliveryHoursPage() {
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const shopType = JSON.parse(getData('ankar-admin'))?.shops

  const { deliveryHours: { values = [], total = 0 }, isLoading } = useSelector(state => state.deliveryHours, shallowEqual)
  const [isActiveTab, setIsActiveTab] = useState(true)
  const debouncedSave = useCallback(_.debounce(filters => hitRequest(filters), 1000), []);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [search, setSearch] = useState("")
  const [filters, setFilters] = useState({
    limit: 10,
    skip: 0,
    page: 0,
  })

  const columns = [
    {
      name: 'S.no',
      key: 's_no',
    },
    {
      name: 'Location Name',
      key: 'locationName',
    },
    {
      name: "Location City",
      key: 'locationCity'
    },
    {
      name: 'Delivery Days',
      key: 'deliveryDays',
    },
    shopType <= 0 ?
    {
      name: 'Zip Codes',
      key: 'zipcode',
    } : '',
    {
      name: 'Actions',
      key: 'actions'
    },
  ]

  const onChangePagination = (e, page) => {

    const skip = page * filters.limit
    setFilters({ ...filters, skip, page })
    hitRequest({ ...filters, skip, page, searchTerm: search, active: isActiveTab })
  }

  const onChangeRowsPerPage = (e) => {
    const newFilters = { limit: parseInt(e, 10), page: 0, skip: 0 }
    setFilters(newFilters)
    setCurrentPage(0)
    hitRequest({ ...newFilters, searchTerm: search })
  }



  const totalPages = useMemo(() => {
    return Math.ceil(total / filters.limit) > 1 ? Math.ceil(total / filters.limit) : 1
  }, [total, filters.limit]);


  const handlePrevPage = (prevPage) => {

    setCurrentPage(prevPage)
    const skip = prevPage * filters.limit
    setFilters({ ...filters, skip, page: prevPage })
    hitRequest({ ...filters, skip, page: prevPage, searchTerm: search, })
  };
  const handleNextPage = (nextPage) => {

    setCurrentPage(nextPage)
    const skip = nextPage * filters.limit
    setFilters({ ...filters, skip, page: nextPage })
    hitRequest({ ...filters, skip, page: nextPage, searchTerm: search, })
  };


  const handleChange = (e) => {
    const { value } = e.target
    const newFilters = { limit: 10, skip: 0, page: 0, searchTerm: value }
    setSearch(value);
    setFilters({ limit: 10, skip: 0, page: 0 })
    setCurrentPage(0)
    debouncedSave(newFilters);
  }
  const onChangeSelectedTab = (e) => {
    const status = e?.value;
    console.log(status)
    const newFilters = { limit: 10, skip: 0, page: 0, }
    setIsActiveTab(status)
    setFilters(newFilters)
    setCurrentPage(0)
    hitRequest({ ...newFilters, searchTerm: search, cartType: status })
  }

  const onClickClearInputField = () => {
    if (!search) return
    const newFilters = { limit: 10, skip: 0, page: 0, }
    setFilters(newFilters)
    hitRequest({ ...newFilters, active: isActiveTab })
    setSearch('')
  }

  const handleConfirmation = (item) => {
    dispatch(commonActions.openCustomModalDialog({
      title: "Are you sure you want to delete?",
      confirm: () => { handleDelete(item) },
      cancel: () => { }
    }, "ConfirmationModal"))
  }
  const handleEdit = (item) => {
    dispatch(commonActions.openCustomModalDialog({
      title: "Edit",
      data: item
    }, "AddDeliveryHoursModal"))
  }
  const handleDelete = (item) => {
    deliveryHoursActions.deleteDeliverHours(item._id).then(() => {
      dispatch(deliveryHoursActions.getAllDeliveryHours())
    }).catch(() => { })
  }

  const hitRequest = (filters = {}) => {
    dispatch(deliveryHoursActions.getAllDeliveryHours({ ...filters, cartType: (filters?.cartType === 'Active' || filters?.cartType === 'Abandoned') ? true : 'all', sortBy: 'active' }))
  }

  const parseRowData = useMemo(() => {
    return values?.map(((deliveryHour, idx) => {

      const { deliveryDays, locationCity, locationName, zipcode } = deliveryHour
      return {
        s_no: filters.skip + (idx + 1),
        deliveryDays: Object.keys(deliveryDays)?.join(','),
        locationCity,
        locationName,
        zipcode: zipcode?.join(',') || "" ,
        actions: <div className='d-flex'>
          <EditIcon onClick={() => handleEdit(deliveryHour)} />
          <DeleteIcon onClick={() => handleConfirmation(deliveryHour)} />
        </div>,
      }
    })) || []
  }, [values])


  useEffect(() => {
    let newFilters = {}
    let searchT = ''
    // let newActive = true
    if (location.search) {
      const { limit = 10, skip = 0, page = 0, searchTerm = '', active = true, cartType = "" } = queryString.parse(location.search);
      searchT = searchTerm
      // newActive = active === "true"
      newFilters = {
        limit: Number(limit),
        skip: Number(skip),
        page: Number(page),
      }
      setIsActiveTab(cartType)
      setFilters(newFilters)
      setSearch(searchT)
      setCurrentPage(skip / limit)
      hitRequest({ ...newFilters, searchTerm: searchT, cartType })
      return;
    }
    hitRequest({ ...newFilters, searchTerm: searchT, cartType: '' })
  }, [])
  useEffect(() => {
    location.search = queryString.stringify({ ...filters, searchTerm: search, cartType: isActiveTab });
    window.onpageshow = function(event) {
      if (event.persisted) {
          window.location.reload();
      }
  };
  }, [filters, search, isActiveTab])
  return (
    <>
      <Helmet>
      <title>Delivery hours | Ankar Admin </title>
      </Helmet>
      <Grid item xs={12} className="manage-product-section">
        <Grid container className=''>
          <Grid item xs={12} sm={4} md={4} xl={4} className="page-heading">
            <h2 className='m-0'>Delivery Hours</h2>
          </Grid>
          <div className="fixed-top">
            {/* <div className="search-input">
              <CustomInput
                label="Search"
                name="search"
                placeholder="Search"
                onChange={handleChange}
                value={search}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: search ? (
                    <InputAdornment position="end" className="cur-pointer">
                      <ClearIcon onClick={onClickClearInputField} />
                    </InputAdornment>
                  ) : null,
                }}
              />
            </div> */}
            {/* <div className="manage-top-tabs ml-3">
              <Select
                isSearchable={false}
                options={filterOptions}
                onChange={onChangeSelectedTab}
                styles={colorStyles}
                value={filterOptions?.find((option => option.value === isActiveTab?.toString()))}
              />
            </div> */}
            <div className="manage-top-tabs ml-3">
              <Button variant='contained' onClick={() => dispatch(commonActions.openCustomModalDialog({}, 'AddDeliveryHoursModal'))}>Add New Location</Button>
            </div>
          </div>
        </Grid>
        {
          isLoading ? <TableLoader /> : <CustomTable
            tableHeading={columns}
            rowsData={parseRowData}
            currentPage={currentPage}
            totalPages={totalPages}
            skip={filters.skip}
            handlePrevPage={handlePrevPage}
            handleNextPage={handleNextPage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            limit={filters.limit}
            onChangePagination={onChangePagination}
          />
        }
      </Grid>



    </>
  );
}