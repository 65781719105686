import React, { useState } from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';


export default function CustomDialog(props) {

    const { children, customFunction = () => { }, maxWidth='md', className = "cus-modal custom-width",isCloseIcon=true } = props;

    const [open, setModalOpen] = useState(true)

    function handleClose() {
        setModalOpen(false)
        customFunction();
    }


    return (
        <div className="modal-style">
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                maxWidth={maxWidth}
                className={className}
            >
               {
                isCloseIcon &&  <DialogTitle style={{display:'flex' ,justifyContent:'end'}} className="modal-title">
                <IconButton  onClick={handleClose} className="close">
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
               }
                <DialogContent className="dailogBox">
                    {children}
                </DialogContent>
            </Dialog>
        </div>
    );
}
