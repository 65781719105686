import {
    GET_ALL_INTEGRATIONS,
    GET_ALL_INTEGRATIONS_SUCCESS,
    GET_ALL_INTEGRATIONS_FAILURE,
    GET_ALL_STATIC_INTEGRATIONS,
    GET_ALL_STATIC_INTEGRATIONS_SUCCESS,
    GET_ALL_STATIC_INTEGRATIONS_FAILURE,
    ADD_INTEGRATION,
    ADD_INTEGRATION_SUCCESS,
    ADD_INTEGRATION_FAILURE,
    UPDATE_INTEGRATION,
    UPDATE_INTEGRATION_SUCCESS,
    UPDATE_INTEGRATION_FAILURE,
    DELETE_INTEGRATION,
    DELETE_INTEGRATION_SUCCESSFULL,
    DELETE_INTEGRATION_FAILED
    // GET_DETAILS_INTEGRATION,
    // GET_DETAILS_INTEGRATION_SUCCESS,
    // GET_DETAILS_INTEGRATION_FAILURE,
} from '../actions/integrations'

const initialState = {
    isLoading: false,
    allIntegrations:{},
}


export default function (state= {...initialState},actions={}) { 
    switch(actions.type) {
        case GET_ALL_INTEGRATIONS:{
            return {...state, isLoading:true}
        }
        case GET_ALL_INTEGRATIONS_SUCCESS:{
            return {...state, isLoading:false,allIntegrations:actions.payload}
        }
        case GET_ALL_INTEGRATIONS_FAILURE:{
            return {...state, isLoading:false}
        }
        case GET_ALL_STATIC_INTEGRATIONS:{
            return {...state, isLoading:true}
        }
        case GET_ALL_STATIC_INTEGRATIONS_SUCCESS:{
            return {...state, isLoading:false,allIntegrations:actions.payload}
        }
        case GET_ALL_STATIC_INTEGRATIONS_FAILURE:{
            return {...state, isLoading:false}
        }
        case ADD_INTEGRATION:{
            return{...state, isLoading:true}
        }
        case ADD_INTEGRATION_SUCCESS:{
            return{...state, isLoading:false}
        }
        case ADD_INTEGRATION_FAILURE:{
            return {...state, isLoading:false}
        }
        case UPDATE_INTEGRATION:{
            return {...state, isLoading:true}
        }
        case UPDATE_INTEGRATION_SUCCESS:{
            return {...state, isLoading:false}
        }
        case UPDATE_INTEGRATION_FAILURE:{
            return {...state, isLoading:false}
        }

        case DELETE_INTEGRATION:
            return { ...state, isLoading: true }

        case DELETE_INTEGRATION_SUCCESSFULL:
            return { ...state, isLoading: false }

        case DELETE_INTEGRATION_FAILED:
            return { ...state, isLoading: false }
        // case GET_DETAILS_INTEGRATION:{
        //     return {...state, isLoading:true}
        // }
        // case GET_DETAILS_INTEGRATION_SUCCESS:{
        //     return {...state, isLoading:false,allIntegrations:actions.payload}
        // }
        // case GET_DETAILS_INTEGRATION_FAILURE:{
        //     return {...state, isLoading:false}
        // }

        default :
        return state;
    }
}