import React from 'react'
import DeleteIconComp from '@mui/icons-material/Delete';

export default function DeleteIcon({onClick}) {
  return (
    <div className=" cur-pointer  delete-icon">
    <DeleteIconComp onClick={onClick} />
</div>
  )
}
