import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid, TextField, Checkbox, FormGroup, FormControlLabel, FormControl, Box } from '@material-ui/core';
import AddCircleSharpIcon from '@material-ui/icons/AddCircleSharp';
import { cloneDeep } from 'lodash';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { staticTimes, initialDeliveryDays, SelectedShop } from "../../constants"
import * as actions from "../../redux/actions/deliveryHours"
import * as commonActions from "../../redux/actions/common"
import CustomMultiSelect from '../common/CustomMultiSelect';
import { validateDeliveryHoursData } from '../../utilities/validations/DeliveryHours';
import { getData } from '../../utils';


const deliveryTime = { start: "00:00", end: "00:00" }

export default function AddDeliveryHoursModal() {
    const shopType = JSON.parse(getData('ankar-admin'))?.shops
    const dispatch = useDispatch();

    const modalData = useSelector(state => state.common.modalData) || {};

    const isEdit = modalData?.title === "Edit" ? true : false || false;
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});


    const [deliveryHours, setDeliveryHours] = useState({
        locationName: "",
        locationCity: "",
        deliveryDays: {},
        timeInterval: "",
        zipcode: []
    })

    const shopName = shopType.map((item) => item.name )

    const [deliveryDays] = useState([...initialDeliveryDays]);
    useEffect(() => {
        if (modalData?.title === "Edit") {
            setDeliveryHours(modalData.data)

        }
    }, [modalData])

    const onChangeHandler = (e) => {
        const { name = "", value = "" } = e.target;
        const updatedDeliveryHours = {...deliveryHours }

        if (name === "zipcode") 
        {
            updatedDeliveryHours.zipcode = value.split(",")
        }
         else {
            updatedDeliveryHours[name] = value
        }
       
        setDeliveryHours(updatedDeliveryHours);
    }

    const onChangeDeliveryDays = (name, data, index) => {
        if (deliveryHours.deliveryDays.hasOwnProperty(data)) {
            const tempDeliveryHours = deliveryHours
            delete tempDeliveryHours.deliveryDays[data]
            setDeliveryHours({ ...tempDeliveryHours })
        } else {
            let newDeliveryHours = { ...deliveryHours }
            newDeliveryHours = { ...newDeliveryHours, [name]: { ...newDeliveryHours[`${name}`], [data]: [{ ...deliveryTime }] } }

            let newDeliveryDays = Object.entries(newDeliveryHours.deliveryDays)
            newDeliveryDays = newDeliveryDays.sort((first, secound) => {
                const firstDay = deliveryDays.find(item => item.day === first[0])
                const secoundDay = deliveryDays.find(item => item.day === secound[0])
                return firstDay.key - secoundDay.key
            })
            newDeliveryDays = Object.fromEntries(newDeliveryDays)
            setDeliveryHours((prevState) => {
                return { ...prevState, deliveryDays: newDeliveryDays }
            })
        }
    }

    const onDeliveryTimeChangeHandle = (day, key, time, val) => {
        const { deliveryDays = {} } = deliveryHours || {};
        if (!val) return
        deliveryDays[day].forEach((item, index, arr) => {
            if (index === key) {
                arr[index] = { ...item, [time]: val.value }
            }
        })
        setDeliveryHours(prevState => {
            return { ...prevState, deliveryDays: { ...deliveryDays } }
        })
    };

    const addDays = (key) => {
        const delDays = { ...deliveryHours.deliveryDays }
        const time = delDays[key];

        time.push(deliveryTime)

        setDeliveryHours({
            ...deliveryHours,
            deliveryDays: { ...delDays, [key]: time }
        })
    };

    const onDeleteTimings = (dayKey, timeKey) => {
        const delDays = { ...deliveryHours.deliveryDays }
        const day = delDays[dayKey];
        day.splice(timeKey, 1)
        setDeliveryHours({
            ...deliveryHours,
            deliveryDays: { ...delDays, [dayKey]: day }
        })

    };

    const onSubmitHandler = () => {
        const { isValid, errors } = validateDeliveryHoursData(deliveryHours);
        if (!isValid) {
            setValidationErrors(errors);
            return;
        }
        if (isEdit) {
            actions.updateDeliveryHours(deliveryHours._id, deliveryHours).then(() => {
                dispatch(actions.getAllDeliveryHours())
                dispatch(commonActions.closeCustomModalDialog({}, "closeDeliveryHoursModal"))
            }).catch(() => { })
        } else {
            actions.postDeliveryHours(deliveryHours).then(() => {
                dispatch(actions.getAllDeliveryHours())
                dispatch(commonActions.closeCustomModalDialog({}, "closeDeliveryHoursModal"))
            }).catch(() => { })
        }
    };

    return (
        <Grid container component="main" className="add-zipcode-modal-container mt-60">
            <Grid item xs={12} className="header-modal">
                <h3>Delivery Hours</h3>
            </Grid>
            <Grid container spacing={2} >
                <Grid item xs={6}>
                    <div className="rep-modal-field">
                        <h5 className='mb-0'>Location Name</h5>
                        <TextField
                            className="blaze-zipcode-textarea"
                            type="text"
                            value={deliveryHours.locationName}
                            name="locationName"
                            fullWidth
                            variant="outlined"
                            onChange={onChangeHandler}
                        />
                        {validationErrors.locationName && <p className="error">{validationErrors.locationName}</p>}
                        {/* {deliveryHours.locationName === "" && isSubmitted && <p className="error">Location Name is required</p>} */}

                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div className="rep-modal-field">
                        <h5 className='mb-0'>Location City</h5>
                        <TextField
                            className="blaze-zipcode-textarea"
                            type="text"
                            value={deliveryHours.locationCity}
                            name="locationCity"
                            fullWidth
                            variant="outlined"
                            onChange={onChangeHandler}
                        />
                        {validationErrors.locationCity && <p className="error">{validationErrors.locationCity}</p>}
                        {/* {deliveryHours.locationCity === "" && isSubmitted && <p className="error">Location City is required</p>} */}

                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className="rep-modal-field">
                        <FormControl component="fieldset">
                            <FormGroup aria-label="position" row>
                                {deliveryDays.map((data, index) => {
                                    const deliveryDaysKeyMap = Object.keys(deliveryHours.deliveryDays)
                                    const isChecked = deliveryDaysKeyMap.includes(data.day)
                                    return (
                                        <Box className={isChecked ? "weekdAY active" : "weekdAY"} onClick={() => onChangeDeliveryDays("deliveryDays", data.day, index)}>
                                            {data.day.slice(0, 3)}
                                        </Box>
                                    );
                                })}
                            </FormGroup>
                            {/* {errors.deliveryDays && <p className="error">{errors.deliveryDays}</p>} */}
                        </FormControl>
                        {deliveryHours.deliveryDays && Object.keys(deliveryHours.deliveryDays).length > 0 &&
                            <h5 className='mb-0'>Add New Delivery Hours</h5>
                        }
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <div className="rep-modal-field">
                        <Grid container spacing={2} >
                            {
                                deliveryHours && deliveryHours.deliveryDays && Object.entries(deliveryHours.deliveryDays).map((devliveryDayItem, devliveryDayIndex) => {
                                    return <Grid item md={12} className="pt-1" key={devliveryDayIndex}>
                                        <h5 className='mt-0 mb-1'>{devliveryDayItem[0]}</h5>
                                        {
                                            devliveryDayItem && devliveryDayItem[1] && devliveryDayItem[1].length ? devliveryDayItem[1].map((devliveryTimeItem, devliverTimeItem) => {
                                                const selectedStartTime = staticTimes.find(item => item.value === devliveryTimeItem.start)
                                                const selectedEndTime = staticTimes.find(item => item.value === devliveryTimeItem.end)
                                                return (
                                                    <>
                                                        <div className="delivery-hours-timing mb-1" >
                                                            <CustomMultiSelect
                                                                label="Start Time"
                                                                name={"label1"}
                                                                options={staticTimes || []}
                                                                onChange={(e, val) => { onDeliveryTimeChangeHandle(devliveryDayItem[0], devliverTimeItem, "start", val) }}
                                                                value={selectedStartTime || {}}
                                                                multiple={false}
                                                                required
                                                                disableCloseOnSelect={false}
                                                                className="hours-field mr-1"
                                                            />

                                                            <CustomMultiSelect
                                                                label="End Time"
                                                                name={"label1"}
                                                                options={staticTimes || []}
                                                                onChange={(e, val) => { onDeliveryTimeChangeHandle(devliveryDayItem[0], devliverTimeItem, "end", val) }}
                                                                value={selectedEndTime || {}}
                                                                multiple={false}
                                                                required
                                                                disableCloseOnSelect={false}
                                                                className="hours-field"
                                                            />
                                                            {devliverTimeItem !== 0 ? <RemoveCircleIcon className="remove-field cursor-pointer" onClick={() => onDeleteTimings(devliveryDayItem[0], devliverTimeItem)} /> : null}
                                                        </div>


                                                    </>
                                                )
                                            }) : null
                                        }
                                        <div className="add-more-fields" onClick={() => addDays(devliveryDayItem[0])}>Add New Time Slot
                                        </div>

                                    </Grid>
                                })
                            }

                        </Grid>
                    </div>
                </Grid>
                {/* 
                <div className="rep-modal-field">
                    <h3>Maximum Order Limit</h3>
                    <TextField
                        className="blaze-zipcode-textarea"
                        type="number"
                        value={deliveryHours.orderLimit}
                        name="orderLimit"
                        fullWidth
                        variant="outlined"
                        onChange={onChangeHandler}
                    />
                    {errors.description && <p className="error">{errors.description}</p>}
                </div> */}
                <Grid item xs={6}>
                    <div className="rep-modal-field">
                        <h5 className='mb-0'>Time interval in minutes</h5>
                        <TextField
                            className="blaze-zipcode-textarea"
                            type="number"
                            value={deliveryHours.timeInterval}
                            name="timeInterval"
                            fullWidth
                            variant="outlined"
                            onChange={onChangeHandler}
                        />
                        {validationErrors.timeInterval && <p className="error">{validationErrors.timeInterval}</p>}
                        {/* {deliveryHours.timeInterval === "" && isSubmitted && <p className="error">Time Interval is required</p>} */}
                    </div>
                </Grid>
                {
                    // shopType.length <= 0 &&
                    !(shopName.includes("Calgary") || shopName.includes("Vancouver")) &&
                    <Grid item xs={6}>
                        <div className="rep-modal-field">
                            <h5 className='mb-0'>Zip Codes Covered (Comma Separated List)</h5>
                            <TextField
                                className="blaze-zipcode-textarea"
                                name="zipcode"
                                type="text"
                                value={deliveryHours.zipcode}
                                fullWidth
                                variant="outlined"
                                onChange={onChangeHandler}
                            // onKeyPress={onKeyPressHandler}
                            />
                        {validationErrors.zipcode && <p className="error">{validationErrors.zipcode}</p>}
                            {/* {deliveryHours.zipcode === "" && isSubmitted && <p className="error">Zip Code is required</p>} */}
                        </div>
                    </Grid>
                }
                <Grid item xs={12} className="d-flex jc-ctr add-button">
                    <div className="add-button">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={onSubmitHandler}
                        >
                            Submit
                        </Button>
                    </div>
                </Grid>
            </Grid>
        </Grid>
    );
}

